export default [
  {
    title: "File.No",
    field: "fileNo",
    cellStyle: {
      width: 60,
      maxWidth: 60
    },
    headerStyle: {
      width: 60,
      maxWidth: 60
    }
  },
  {
    title: "Opp.No",
    field: "oppositionId",
    cellStyle: {
      width: 60,
      maxWidth: 60
    },
    headerStyle: {
      width: 60,
      maxWidth: 60
    }
  },
  {
    title: "App.No",
    field: "tradeMarkId",
    cellStyle: {
      width: 60,
      maxWidth: 60
    },
    headerStyle: {
      width: 60,
      maxWidth: 60
    }
  },
  {
    title: "Name",
    field: "name",
    cellStyle: {
      width: 60,
      maxWidth: 60
    },
    headerStyle: {
      width: 60,
      maxWidth: 60
    }
  },
  {
    title: "Class",
    field: "class",
    cellStyle: {
      width: 2,
      maxWidth: 2
    },
    headerStyle: {
      width: 2,
      maxWidth: 2
    }
  },
  {
    title: "Document Status",
    field: "documentStatus",
    cellStyle: {
      width: 60,
      maxWidth: 60
    },
    headerStyle: {
      width: 60,
      maxWidth: 60
    }
  },
  {
    title: "Opponent Name",
    field: "opponentName",
    cellStyle: {
      width: 60,
      maxWidth: 60
    },
    headerStyle: {
      width: 60,
      maxWidth: 60
    }
  },
  {
    title: "Applicant Name",
    field: "applicantName",
    cellStyle: {
      width: 60,
      maxWidth: 60
    },
    headerStyle: {
      width: 60,
      maxWidth: 60
    }
  },
  {
    title: "Jurisdiction",
    field: "jurisdiction",
    cellStyle: {
      width: 60,
      maxWidth: 60
    },
    headerStyle: {
      width: 60,
      maxWidth: 60
    }
  },
  {
    title: "TMO",
    field: "tmoFiled",
    cellStyle: {
      width: 60,
      maxWidth: 60
    },
    headerStyle: {
      width: 60,
      maxWidth: 60
    }
  },
  {
    title: "TMO Counter",
    field: "tmoCounterFiled",
    cellStyle: {
      width: 60,
      maxWidth: 60
    },
    headerStyle: {
      width: 60,
      maxWidth: 60
    }
  },
  {
    title: "Rule45",
    field: "rule45",
    cellStyle: {
      width: 60,
      maxWidth: 60
    },
    headerStyle: {
      width: 60,
      maxWidth: 60
    }
  },
  {
    title: "Rule46",
    field: "rule46",
    cellStyle: {
      width: 60,
      maxWidth: 60
    },
    headerStyle: {
      width: 60,
      maxWidth: 60
    }
  },
  {
    title: "Rule47",
    field: "rule47",
    cellStyle: {
      width: 60,
      maxWidth: 60
    },
    headerStyle: {
      width: 60,
      maxWidth: 60
    }
  },
  {
    title: "Status",
    field: "oppositionStatus",
    cellStyle: {
      width: 120,
      maxWidth: 120
    },
    headerStyle: {
      width: 120,
      maxWidth: 120
    }
  },
  {
    title: "Actions",
    cellStyle: {
      width: 10,
      maxWidth: 10,
      textAlign: "right"
    },
    headerStyle: {
      width: 10,
      maxWidth: 10,
      textAlign: "right"
    }
  }
];
