const MENUS = [
  {
    label: "Dashboard",
    to: "/dashboard"
  },
  {
    label: "Patents",
    to: "/dashboard/patents"
  },
  {
    label: "Trade Marks",
    to: "/dashboard/trademarks",
    content: [
      {
        label: "List",
        to: "/dashboard/trademarks"
      },
      {
        label: "Opposition",
        to: "/dashboard/trademarks/opposition"
      }
    ]
  },
  {
    label: "Billing",
    to: "/dashboard/bills"
  },
  {
    label: "Clients",
    to: "/dashboard/clients"
  }
];

export default MENUS;
