import React, { useEffect, useRef, useState } from "react";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button
} from "@material-ui/core";
import { connect } from "react-redux";
import SectionCard from "components/shared/SectionCard";
import PopUp from "components/shared/Popup";
import Toaster from "components/shared/Toaster";
import UIDatepicker from "components/shared/Datepicker";
import {
  oppositionDocumentStatus,
  oppositionJurisdiction,
  oppositionStatus,
  trademarkClass
} from "utils/dropdownData";
import moment from "moment";
import {
  getTrademarkOppositionById,
  postTrademarkOpposition,
  putTrademarkOpposition
} from "api/trademarkOppositionApi";

function CreateTrademarkOpposition(props) {
  let [trademarkOppInfo, setTrademarkOppInfo] = useState({
    hearingHistory: [
      {
        comment: "",
        date: ""
      }
    ],
    fileNo: "",
    tradeMarkId: "",
    name: "",
    class: "",
    companyId: "",
    applicantName: "",
    documentStatus: "",
    oppositionId: "",
    opponentName: "",
    notes: "",
    oppositionStatus: "",
    jurisdiction: "",
    tmoDeadline: "",
    tmoFiled: "",
    tmoCounterDeadline: "",
    tmoCounterFiled: "",
    rule45Deadline: "",
    rule45Filed: "",
    rule46Deadline: "",
    rule46Filed: "",
    rule47Deadline: "",
    rule47Filed: "",
    tmoDetails: "",
    tmoCounterDetails: "",
    rule45Details: "",
    rule46Details: "",
    rule47Details: ""
  });
  let [error, setError] = useState({});

  let [toasterInfo, setToasterInfo] = useState({
    show: false,
    message: "",
    type: ""
  });

  let formRef = useRef();
  let selectRef = useRef();
  let oppositionStatusRef = useRef();
  let jurisdictionRef = useRef();
  let documentStatusRef = useRef();

  let { edit } = props;

  useEffect(init, []);

  function init() {
    if (edit) {
      fetchTrademarkOppositionInfo();
    }
  }

  async function fetchTrademarkOppositionInfo() {
    let info = await getTraderMarkOppositionInfo();
    if (info) {
      info.tmoDetails = info.tmoDeadline
        ? "tmoDeadline"
        : info.tmoFiled
        ? "tmoFiled"
        : "";
      info.tmoCounterDetails = info.tmoCounterDeadline
        ? "tmoCounterDeadline"
        : info.tmoCounterFiled
        ? "tmoCounterFiled"
        : "";
      info.rule45Details = info.rule45Deadline
        ? "rule45Deadline"
        : info.rule45Filed
        ? "rule45Filed"
        : "";
      info.rule46Details = info.rule46Deadline
        ? "rule46Deadline"
        : info.rule46Filed
        ? "rule46Filed"
        : "";
      info.rule47Details = info.rule47Deadline
        ? "rule47Deadline"
        : info.rule47Filed
        ? "rule47Filed"
        : "";
      setTrademarkOppInfo(info);
    }
  }

  async function getTraderMarkOppositionInfo() {
    try {
      let response = await getTrademarkOppositionById(
        props.tradeMarkId,
        props.company
      );
      if (response && response.data) {
        return response.data;
      }
    } catch {}
  }

  function handleInputChange(value, field) {
    let updatedInfo = { ...trademarkOppInfo };
    if (value === "tmoDeadline") {
      updatedInfo.tmoFiled = "";
    } else if (value === "tmoFiled") {
      updatedInfo.tmoDeadline = "";
    } else if (value === "tmoCounterFiled") {
      updatedInfo.tmoCounterDeadline = "";
    } else if (value === "tmoCounterDeadline") {
      updatedInfo.tmoCounterFiled = "";
    } else if (value === "rule45Filed") {
      updatedInfo.rule45Deadline = "";
    } else if (value === "rule45Deadline") {
      updatedInfo.rule45Filed = "";
    } else if (value === "rule46Filed") {
      updatedInfo.rule46Deadline = "";
    } else if (value === "rule46Deadline") {
      updatedInfo.rule46Filed = "";
    } else if (value === "rule47Filed") {
      updatedInfo.rule47Deadline = "";
    } else if (value === "rule47Deadline") {
      updatedInfo.rule47Filed = "";
    }
    if (
      field === "tmoCounterFiled" &&
      updatedInfo.documentStatus === "Filed" &&
      updatedInfo.rule45Details !== "rule45Filed"
    ) {
      updatedInfo.rule45Details = "rule45Deadline";
      updatedInfo.rule45Deadline = getDate(value, 2);
    } else if (
      field === "rule46Filed" &&
      updatedInfo.documentStatus === "Filed" &&
      updatedInfo.rule47Details !== "rule47Filed"
    ) {
      updatedInfo.rule47Details = "rule47Deadline";
      updatedInfo.rule47Deadline = getDate(value, 1);
    } else if (
      updatedInfo.documentStatus === "Received" &&
      field === "tmoFiled" &&
      updatedInfo.tmoCounterDetails !== "tmoCounterFiled"
    ) {
      updatedInfo.tmoCounterDetails = "tmoCounterDeadline";
      updatedInfo.tmoCounterDeadline = getDate(value, 2);
    } else if (
      updatedInfo.documentStatus === "Received" &&
      field === "rule45Filed" &&
      updatedInfo.rule46Details !== "rule46Filed"
    ) {
      updatedInfo.rule46Details = "rule46Deadline";
      updatedInfo.rule46Deadline = getDate(value, 2);
    }
    setTrademarkOppInfo({ ...updatedInfo, [field]: value });
    setError({ ...error, [field]: false });
  }

  function getDate(value, diff) {
    return moment(value, "DD/MM/YYYY").add(diff, "month").format("DD/MM/YYYY");
  }

  function handleHearingDateChange(value, field, i) {
    let hearingInfo = [...trademarkOppInfo.hearingHistory];
    hearingInfo[i][field] = value;
    setTrademarkOppInfo({ ...trademarkOppInfo, hearingHistory: hearingInfo });
  }
  function addHearing() {
    let hearingInfo = [...trademarkOppInfo.hearingHistory];
    let isInvalidDate = hearingInfo.filter((val) => {
      if (!val.date) {
        return val;
      }
    });
    if (isInvalidDate.length > 0) {
      setToasterInfo({
        message: "Please select hearing date",
        type: "error",
        show: true
      });
      return;
    }
    hearingInfo.push({
      comment: "",
      date: ""
    });
    setTrademarkOppInfo({ ...trademarkOppInfo, hearingHistory: hearingInfo });
  }
  function deleteHearing(i) {
    let hearingInfo = [...trademarkOppInfo.hearingHistory];
    hearingInfo = hearingInfo.filter((val, index) => {
      if (index !== i) {
        return val;
      }
    });
    setTrademarkOppInfo({ ...trademarkOppInfo, hearingHistory: hearingInfo });
  }
  async function handleSave() {
    validateFiledDate();
    validateReceivedDate();
    let validPayload = validatePayload();
    if (validPayload) {
      let payload = { ...trademarkOppInfo };
      if (payload.tmoDetails === "tmoFiled") {
        payload.tmoFiled = payload.tmoFiled;
        payload.tmoDeadline = "";
      } else if (payload.tmoDetails === "tmoDeadline") {
        payload.tmoFiled = "";
        payload.tmoDeadline = payload.tmoDeadline;
      }
      if (payload.tmoCounterDetails === "tmoCounterFiled") {
        payload.tmoCounterFiled = payload.tmoCounterFiled;
        payload.tmoCounterDeadline = "";
      } else if (payload.tmoDetails === "tmoCounterDeadline") {
        payload.tmoCounterFiled = "";
        payload.tmoCounterDeadline = payload.tmoCounterDeadline;
      }
      if (payload.rule45Details === "rule45Filed") {
        payload.rule45Filed = payload.rule45Filed;
        payload.rule45Deadline = "";
      } else if (payload.tmoDetails === "rule45Deadline") {
        payload.rule45Filed = "";
        payload.rule45Deadline = payload.rule45Deadline;
      }
      if (payload.rule46Details === "rule46Filed") {
        payload.rule46Filed = payload.rule46Filed;
        payload.rule46Deadline = "";
      } else if (payload.tmoDetails === "rule46Deadline") {
        payload.rule46Filed = "";
        payload.rule46Deadline = payload.rule46Deadline;
      }
      if (payload.rule47Details === "rule47Filed") {
        payload.rule47Filed = payload.rule47Filed;
        payload.rule47Deadline = "";
      } else if (payload.tmoDetails === "rule47Deadline") {
        payload.rule47Filed = "";
        payload.rule47Deadline = payload.rule47Deadline;
      }
      if (edit) {
        let clientCreated = await proceedUpdateClient(payload);
        if (clientCreated) {
          setToasterInfo({
            message: "Trade mark opposition Updated Successfully",
            show: true,
            type: "success"
          });
          setTimeout(() => {
            props.onClose(true);
          }, 1000);
        }
      } else {
        let clientCreated = await proceedCreateClient(payload);
        if (clientCreated) {
          setToasterInfo({
            message: "Trade mark opposition Created Successfully",
            type: "success",
            show: true
          });
          setTimeout(() => {
            props.onClose(true);
          }, 1000);
        }
      }
    }
  }

  async function proceedCreateClient(payload) {
    payload.oppositionId = Number(trademarkOppInfo.oppositionId);
    try {
      let response = await postTrademarkOpposition(payload, payload.companyId);
      return response;
    } catch {}
  }

  async function proceedUpdateClient(payload) {
    payload.oppositionId = Number(trademarkOppInfo.oppositionId);
    try {
      let response = await putTrademarkOpposition(
        payload,
        payload.companyId,
        payload.oppositionId
      );
      return response;
    } catch {}
  }

  function validatePayload() {
    let valid = true;
    let updatedError = { ...error };
    if (!trademarkOppInfo.companyId) {
      selectRef.current.focus();
      updatedError.companyId = true;
      valid = false;
    } else if (
      !trademarkOppInfo.fileNo ||
      (trademarkOppInfo.fileNo &&
        (trademarkOppInfo.fileNo.length < 3 ||
          trademarkOppInfo.fileNo.length > 8))
    ) {
      const field = getFieldElement("fileNo");
      field.focus();
      updatedError.fileNo = true;
      valid = false;
    } else if (!trademarkOppInfo.tradeMarkId) {
      const field = getFieldElement("tradeMarkId");
      field.focus();
      updatedError.tradeMarkId = true;
      valid = false;
    } else if (!trademarkOppInfo.oppositionId) {
      const field = getFieldElement("oppositionId");
      field.focus();
      updatedError.oppositionId = true;
      valid = false;
    } else if (!trademarkOppInfo.name) {
      const field = getFieldElement("name");
      field.focus();
      updatedError.name = true;
      valid = false;
    } else if (!trademarkOppInfo.applicantName) {
      const field = getFieldElement("applicantName");
      field.focus();
      updatedError.applicantName = true;
      valid = false;
    } else if (!trademarkOppInfo.opponentName) {
      const field = getFieldElement("opponentName");
      field.focus();
      updatedError.opponentName = true;
      valid = false;
    } else if (!trademarkOppInfo.documentStatus) {
      documentStatusRef.current.focus();
      updatedError.documentStatus = true;
      valid = false;
    } else if (!trademarkOppInfo.oppositionStatus) {
      oppositionStatusRef.current.focus();
      updatedError.oppositionStatus = true;
      valid = false;
    } else if (!trademarkOppInfo.jurisdiction) {
      jurisdictionRef.current.focus();
      updatedError.jurisdiction = true;
      valid = false;
    }
    if (trademarkOppInfo.documentStatus === "Filed") {
      valid = validateFiledDate();
    } else if (trademarkOppInfo.documentStatus === "Received") {
      valid = validateReceivedDate();
    }
    setError(updatedError);
    return valid;
  }

  function validateFiledDate() {
    let {
      tmoCounterFiled,
      rule45Deadline,
      rule46Filed,
      rule47Deadline
    } = trademarkOppInfo;
    if (tmoCounterFiled && rule45Deadline) {
      let difference = getDifference(tmoCounterFiled, rule45Deadline);
      if (difference < 2) {
        setToasterInfo({
          message:
            "Difference between rule 45 deadline and tmo counter filed has to be 2 months",
          show: true,
          type: "error"
        });
        return false;
      }
    }
    if (rule46Filed && rule47Deadline) {
      let difference = getDifference(rule46Filed, rule47Deadline);
      if (difference < 1) {
        setToasterInfo({
          message:
            "Difference between rule 47 deadline and rule 46 filed has to be 1 month",
          show: true,
          type: "error"
        });
        return false;
      }
    }
    return true;
  }

  function validateReceivedDate() {
    let {
      tmoFiled,
      tmoCounterDeadline,
      rule45Filed,
      rule46Deadline
    } = trademarkOppInfo;
    if (tmoFiled && tmoCounterDeadline) {
      let difference = getDifference(tmoFiled, tmoCounterDeadline);
      if (difference < 2) {
        setToasterInfo({
          message:
            "Difference between TMO Counter deadline and tmo filed has to be 2 months",
          show: true,
          type: "error"
        });
        return false;
      }
    }
    if (rule45Filed && rule46Deadline) {
      let difference = getDifference(rule45Filed, rule46Deadline);
      if (difference < 2) {
        setToasterInfo({
          message:
            "Difference between rule 46 deadline and rule 45 filed has to be 2 month",
          show: true,
          type: "error"
        });
        return false;
      }
    }
    return true;
  }

  function getDifference(fromMonth, toMonth) {
    return moment(toMonth, "DD/MM/YYYY").diff(
      moment(fromMonth, "DD/MM/YYYY"),
      "months"
    );
  }

  function getFieldElement(fieldName) {
    return formRef.current.querySelector(`[name=${fieldName}]`);
  }

  function getCompanies() {
    return props.companies.filter((company) => {
      return ["vtm", "absolute", "active", "va"].includes(company.companyId);
    });
  }

  return (
    <PopUp
      size="md"
      open={true}
      fullWidth
      onClose={() => props.onClose()}
      title={`${edit ? "Update" : "Create"} Trade mark Opposition`}
      onSave={handleSave}
      actionLabel={`${edit ? "Update" : "Save"}`}
    >
      {" "}
      <div ref={formRef}>
        <SectionCard entity="trademark" title="File Details" bg={true}>
          <Grid container spacing="1">
            <Grid item xs="6">
              <FormControl required fullWidth variant="outlined">
                <InputLabel>Company</InputLabel>
                <Select
                  disabled={edit || false}
                  error={error.companyId}
                  inputRef={selectRef}
                  label="Company"
                  value={trademarkOppInfo.companyId}
                  required
                  onChange={(event) =>
                    handleInputChange(event.target.value, "companyId")
                  }
                >
                  {getCompanies().map((company, index) => (
                    <MenuItem
                      key={"compnay-" + index}
                      value={company.companyId}
                    >
                      {company.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs="6">
              <TextField
                className="mb-3 w-full"
                label="File No."
                variant="outlined"
                name="fileNo"
                disabled={edit}
                type="number"
                required
                value={trademarkOppInfo.fileNo}
                error={error.fileNo}
                helperText={
                  error.fileNo &&
                  "File No. should be between 3 to 8 characters only"
                }
                onChange={(event) =>
                  handleInputChange(event.target.value, "fileNo")
                }
              />
            </Grid>
          </Grid>
        </SectionCard>
        <SectionCard entity="trademark" title="Opposition Details" bg={true}>
          <Grid container spacing="1">
            <Grid item xs="4">
              <TextField
                className="mb-3 w-full"
                label="Opposition No"
                variant="outlined"
                required
                name="oppositionId"
                type="text"
                value={trademarkOppInfo.oppositionId}
                error={error.oppositionId}
                onChange={(event) =>
                  handleInputChange(event.target.value, "oppositionId")
                }
              />
            </Grid>
            <Grid item xs="4">
              <TextField
                className="mb-3 w-full"
                label="Application No"
                variant="outlined"
                required
                name="tradeMarkId"
                type="text"
                value={trademarkOppInfo.tradeMarkId}
                error={error.tradeMarkId}
                onChange={(event) =>
                  handleInputChange(event.target.value, "tradeMarkId")
                }
              />
            </Grid>
            <Grid item xs="4">
              <TextField
                required
                className="mb-3 w-full"
                label="Name/TM"
                variant="outlined"
                name="name"
                type="text"
                value={trademarkOppInfo.name}
                error={error.name}
                onChange={(event) =>
                  handleInputChange(event.target.value, "name")
                }
              />
            </Grid>
            <Grid item xs="4">
              {" "}
              <FormControl fullWidth variant="outlined">
                <InputLabel>Class</InputLabel>
                <Select
                  error={error.class}
                  label="Status"
                  value={trademarkOppInfo.class}
                  name="class"
                  onChange={(e) => handleInputChange(e.target.value, "class")}
                >
                  {trademarkClass.map((status, index) => (
                    <MenuItem key={"status-" + index} value={status}>
                      {status}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs="4">
              <TextField
                required
                className="mb-3 w-full"
                label="Application Name"
                variant="outlined"
                name="applicantName"
                type="text"
                error={error.applicantName}
                value={trademarkOppInfo.applicantName}
                onChange={(event) =>
                  handleInputChange(event.target.value, "applicantName")
                }
              />
            </Grid>
            <Grid item xs="4">
              <TextField
                required
                className="mb-3 w-full"
                label="Opponent Name"
                variant="outlined"
                name="opponentName"
                type="text"
                error={error.opponentName}
                value={trademarkOppInfo.opponentName}
                onChange={(event) =>
                  handleInputChange(event.target.value, "opponentName")
                }
              />
            </Grid>
            <Grid item xs="4">
              <FormControl required fullWidth variant="outlined">
                <InputLabel>Document Status</InputLabel>
                <Select
                  label="Document Status"
                  error={error.documentStatus}
                  inputRef={documentStatusRef}
                  value={trademarkOppInfo.documentStatus}
                  required
                  onChange={(event) =>
                    handleInputChange(event.target.value, "documentStatus")
                  }
                >
                  {oppositionDocumentStatus.map((doc, index) => (
                    <MenuItem key={"doc-" + index} value={doc}>
                      {doc}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs="4">
              <FormControl required fullWidth variant="outlined">
                <InputLabel>Opposition Status</InputLabel>
                <Select
                  label="Opposition Status"
                  error={error.oppositionStatus}
                  inputRef={oppositionStatusRef}
                  value={trademarkOppInfo.oppositionStatus}
                  required
                  onChange={(event) =>
                    handleInputChange(event.target.value, "oppositionStatus")
                  }
                >
                  {oppositionStatus.map((status, index) => (
                    <MenuItem key={"status-" + index} value={status}>
                      {status}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs="4">
              <FormControl required fullWidth variant="outlined">
                <InputLabel>Jurisdiction</InputLabel>
                <Select
                  label="Jurisdiction"
                  error={error.jurisdiction}
                  inputRef={jurisdictionRef}
                  value={trademarkOppInfo.jurisdiction}
                  required
                  onChange={(event) =>
                    handleInputChange(event.target.value, "jurisdiction")
                  }
                >
                  {oppositionJurisdiction.map((jurisdiction, index) => (
                    <MenuItem
                      key={"jurisdiction-" + index}
                      value={jurisdiction}
                    >
                      {jurisdiction}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </SectionCard>
        <Grid container spacing="1" className="mb-3">
          <Grid item xs="6">
            <SectionCard entity="trademark" title="TMO Details" bg={true}>
              <Grid container spacing="1">
                <Grid item xs="6">
                  <FormControl component="fieldset">
                    <RadioGroup
                      value={trademarkOppInfo.tmoDetails}
                      onChange={(event) =>
                        handleInputChange(event.target.value, "tmoDetails")
                      }
                    >
                      <FormControlLabel
                        value="tmoDeadline"
                        control={<Radio />}
                        label="Deadline"
                      />
                      <FormControlLabel
                        value="tmoFiled"
                        control={<Radio />}
                        label="Filed Date"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs="6">
                  {trademarkOppInfo.tmoDetails === "tmoDeadline" && (
                    <UIDatepicker
                      label="Deadline Date"
                      onChange={(date) =>
                        handleInputChange(date, "tmoDeadline")
                      }
                      value={trademarkOppInfo.tmoDeadline}
                    />
                  )}{" "}
                  {trademarkOppInfo.tmoDetails === "tmoFiled" && (
                    <UIDatepicker
                      label="Filed Date"
                      onChange={(date) => handleInputChange(date, "tmoFiled")}
                      value={trademarkOppInfo.tmoFiled}
                    />
                  )}
                </Grid>
              </Grid>
            </SectionCard>
          </Grid>
          <Grid item xs="6">
            <SectionCard
              entity="trademark"
              title="TMO Counter details"
              bg={true}
            >
              <Grid container spacing="1">
                <Grid item xs="6">
                  <FormControl component="fieldset">
                    <RadioGroup
                      value={trademarkOppInfo.tmoCounterDetails}
                      onChange={(event) =>
                        handleInputChange(
                          event.target.value,
                          "tmoCounterDetails"
                        )
                      }
                    >
                      <FormControlLabel
                        value="tmoCounterDeadline"
                        control={<Radio />}
                        label="Deadline"
                      />
                      <FormControlLabel
                        value="tmoCounterFiled"
                        control={<Radio />}
                        label="Filed Date"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>

                <Grid item xs="6">
                  {trademarkOppInfo.tmoCounterDetails ===
                    "tmoCounterDeadline" && (
                    <UIDatepicker
                      label="Deadline Date"
                      onChange={(date) =>
                        handleInputChange(date, "tmoCounterDeadline")
                      }
                      value={trademarkOppInfo.tmoCounterDeadline}
                    />
                  )}{" "}
                  {trademarkOppInfo.tmoCounterDetails === "tmoCounterFiled" && (
                    <UIDatepicker
                      label="Filed Date"
                      onChange={(date) =>
                        handleInputChange(date, "tmoCounterFiled")
                      }
                      value={trademarkOppInfo.tmoCounterFiled}
                    />
                  )}
                </Grid>
              </Grid>
            </SectionCard>
          </Grid>
        </Grid>
        <Grid container spacing="1" className="mb-3">
          <Grid item xs="6">
            <SectionCard entity="trademark" title="Rule45 Details" bg={true}>
              <Grid container spacing="1">
                <Grid item xs="6">
                  <FormControl component="fieldset">
                    <RadioGroup
                      value={trademarkOppInfo.rule45Details}
                      onChange={(event) =>
                        handleInputChange(event.target.value, "rule45Details")
                      }
                    >
                      <FormControlLabel
                        value="rule45Deadline"
                        control={<Radio />}
                        label="Deadline"
                      />
                      <FormControlLabel
                        value="rule45Filed"
                        control={<Radio />}
                        label="Filed Date"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs="6">
                  {trademarkOppInfo.rule45Details === "rule45Deadline" && (
                    <UIDatepicker
                      label="Deadline Date"
                      onChange={(date) =>
                        handleInputChange(date, "rule45Deadline")
                      }
                      value={trademarkOppInfo.rule45Deadline}
                    />
                  )}{" "}
                  {trademarkOppInfo.rule45Details === "rule45Filed" && (
                    <UIDatepicker
                      label="Filed Date"
                      onChange={(date) =>
                        handleInputChange(date, "rule45Filed")
                      }
                      value={trademarkOppInfo.rule45Filed}
                    />
                  )}
                </Grid>
              </Grid>
            </SectionCard>
          </Grid>
          <Grid item xs="6">
            <SectionCard entity="trademark" title="Rule46 details" bg={true}>
              <Grid container spacing="1">
                <Grid item xs="6">
                  <FormControl component="fieldset">
                    <RadioGroup
                      value={trademarkOppInfo.rule46Details}
                      onChange={(event) =>
                        handleInputChange(event.target.value, "rule46Details")
                      }
                    >
                      <FormControlLabel
                        value="rule46Deadline"
                        control={<Radio />}
                        label="Deadline"
                      />
                      <FormControlLabel
                        value="rule46Filed"
                        control={<Radio />}
                        label="Filed Date"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs="6">
                  {trademarkOppInfo.rule46Details === "rule46Deadline" && (
                    <UIDatepicker
                      label="Deadline Date"
                      onChange={(date) =>
                        handleInputChange(date, "rule46Deadline")
                      }
                      value={trademarkOppInfo.rule46Deadline}
                    />
                  )}{" "}
                  {trademarkOppInfo.rule46Details === "rule46Filed" && (
                    <UIDatepicker
                      label="Filed Date"
                      onChange={(date) =>
                        handleInputChange(date, "rule46Filed")
                      }
                      value={trademarkOppInfo.rule46Filed}
                    />
                  )}
                </Grid>
              </Grid>
            </SectionCard>
          </Grid>
        </Grid>
        <Grid container spacing="1" className="mb-3">
          <Grid item xs="6">
            <SectionCard entity="trademark" title="Rule47 details" bg={true}>
              <Grid container spacing="1">
                <Grid item xs="6">
                  <FormControl component="fieldset">
                    <RadioGroup
                      value={trademarkOppInfo.rule47Details}
                      onChange={(event) =>
                        handleInputChange(event.target.value, "rule47Details")
                      }
                    >
                      <FormControlLabel
                        value="rule47Deadline"
                        control={<Radio />}
                        label="Deadline"
                      />
                      <FormControlLabel
                        value="rule47Filed"
                        control={<Radio />}
                        label="Filed Date"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs="6">
                  {trademarkOppInfo.rule47Details === "rule47Deadline" && (
                    <UIDatepicker
                      label="Deadline Date"
                      onChange={(date) =>
                        handleInputChange(date, "rule47Deadline")
                      }
                      value={trademarkOppInfo.rule47Deadline}
                    />
                  )}{" "}
                  {trademarkOppInfo.rule47Details === "rule47Filed" && (
                    <UIDatepicker
                      label="Filed Date"
                      onChange={(date) =>
                        handleInputChange(date, "rule47Filed")
                      }
                      value={trademarkOppInfo.rule47Filed}
                    />
                  )}
                </Grid>
              </Grid>
            </SectionCard>
          </Grid>
          <Grid item xs="6">
            <SectionCard entity="trademark" title="Notes" bg={true}>
              <Grid container spacing="1">
                <Grid item xs="12">
                  <TextField
                    className="mb-3 w-full"
                    label="Notes"
                    variant="outlined"
                    name="notes"
                    type="text"
                    multiline
                    rows={3}
                    value={trademarkOppInfo.notes}
                    onChange={(event) =>
                      handleInputChange(event.target.value, "notes")
                    }
                  />
                </Grid>
              </Grid>
            </SectionCard>
          </Grid>
        </Grid>
        <SectionCard entity="trademark" title="Hearing Details" bg={true}>
          {trademarkOppInfo.hearingHistory &&
            trademarkOppInfo.hearingHistory.map((val, i) => {
              return (
                <Grid container spacing="1" key={"trademark-" + i}>
                  <Grid item xs="4">
                    <UIDatepicker
                      required
                      label="Hearing Date"
                      onChange={(date) =>
                        handleHearingDateChange(date, "date", i)
                      }
                      value={val.date}
                    />
                  </Grid>
                  <Grid item xs="6">
                    <TextField
                      className="mb-3 w-full"
                      label="Hearing Comment"
                      variant="outlined"
                      name="comment"
                      type="text"
                      value={val.comment}
                      onChange={(event) =>
                        handleHearingDateChange(
                          event.target.value,
                          "comment",
                          i
                        )
                      }
                    />
                  </Grid>
                  {i > 0 && (
                    <Grid item xs="2">
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => deleteHearing(i)}
                      >
                        Delete Hearing
                      </Button>
                    </Grid>
                  )}
                </Grid>
              );
            })}
          <Grid item xs="2">
            <Button color="primary" variant="contained" onClick={addHearing}>
              Add Hearing
            </Button>
          </Grid>
        </SectionCard>
      </div>
      <Toaster
        open={toasterInfo.show}
        message={toasterInfo.message}
        type={toasterInfo.type}
        onClose={() => setToasterInfo({})}
      />
    </PopUp>
  );
}

const mapStateToProps = (state) => {
  return {
    companies: state.tableReducer.companies
  };
};

export default connect(mapStateToProps)(CreateTrademarkOpposition);
