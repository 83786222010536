import React, { forwardRef, useEffect, useState } from "react";

import clsx from "clsx";
import { Link, useHistory, useLocation } from "react-router-dom";

import { Hidden, AppBar, Box, Menu, ListItem, List } from "@material-ui/core";
import Cookies from "js-cookie";
import projectLogo from "assets/images/ipbloc.png";

import HeaderUserbox from "../HeaderUserbox";

import { getUserById } from "api/userApi";

import "./header.scss";
import MENUS from "utils/menus";

const LoadSubMenus = (props) => {
  return (
    <>
      <span
        key={"menu" + props.index}
        className={props.activeRoute.includes(props.menu.to) ? "active" : ""}
        onClick={(event) =>
          props.handleMenuClick(event.currentTarget, props.menu)
        }
      >
        {props.menu.label}
      </span>
    </>
  );
};

const SubMenu = forwardRef((props, ref) => {
  return (
    <Menu
      anchorEl={ref}
      open={Boolean(ref)}
      anchorOrigin={{
        vertical: "center",
        horizontal: "center"
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "center"
      }}
      style={{
        width: "200px"
      }}
      onClose={props.onClose}
      // className="ml-2"
    >
      <div className="dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
        <List className="text-left bg-transparent d-flex align-items-center flex-column pt-0">
          {props.subMenu.map((menu, index) => {
            return (
              <ListItem
                key={"submenu-" + index}
                button
                onClick={() => props.handleSubMenuClick(menu)}
              >
                {menu.label}
              </ListItem>
            );
          })}
        </List>
      </div>
    </Menu>
  );
});

const Header = () => {
  const history = useHistory();
  const location = useLocation();
  const [user, setUser] = useState("");
  const [activeRoute, setActiveRoute] = useState("");

  let [subMenuTarget, setSubMenuTarget] = useState(null);
  let [selectedMenu, setSelectedMenu] = useState({});

  useEffect(() => {
    getUser();
  }, []);
  useEffect(() => {
    setActiveRoute(location.pathname.toString());
  }, [location.pathname]);

  function handleMenuClick(target, menuInfo) {
    setSelectedMenu(menuInfo);
    setSubMenuTarget(target);
  }
  function changeRoute(path) {
    history.push(path);
  }

  function handleSubMenuClick(menuInfo) {
    setSubMenuTarget(null);
    history.push(menuInfo.to);
  }
  async function getUser() {
    try {
      let usedId = Cookies.get("userId");
      let response = await getUserById(usedId);
      if (response && response.data) {
        setUser(response.data);
      }
    } catch {}
  }
  return (
    <>
      <AppBar
        color="secondary"
        className="app-header"
        position={"fixed"}
        elevation={3}
      >
        <Box className="app-header-toolbar">
          <Hidden mdDown>
            <div className={clsx("app-header-logo", {})}>
              <Box className="flex" title="IP-BLOC">
                <Link to="/dashboard" className="header-logo-wrapper-link">
                  <img
                    className="app-header-logo-img"
                    alt="IP-BLOC"
                    src={projectLogo}
                    width="70px"
                  />
                </Link>
              </Box>
            </div>
          </Hidden>
          <Box className="d-flex align-items-center">
            <div className="menus">
              {MENUS.map((menu, index) => {
                return menu.content ? (
                  <LoadSubMenus
                    key={"menu" + index}
                    menu={menu}
                    index={index}
                    activeRoute={activeRoute}
                    changeRoute={changeRoute}
                    handleMenuClick={handleMenuClick}
                  />
                ) : (
                  <span
                    className={activeRoute === menu.to ? "active" : ""}
                    key={"menu" + index}
                    onClick={() => changeRoute(menu.to)}
                  >
                    {menu.label}
                  </span>
                );
              })}
            </div>
            <HeaderUserbox user={user} />
          </Box>
        </Box>
        {subMenuTarget && (
          <SubMenu
            subMenu={selectedMenu.content}
            ref={subMenuTarget}
            onClose={() => setSubMenuTarget(null)}
            handleSubMenuClick={handleSubMenuClick}
          />
        )}
      </AppBar>
    </>
  );
};

export default Header;
