import axios from "axios";
import apiUrl from "config/environment";

import Cookies from "js-cookie";

export async function getTrademarkOpposition(
  company = "vtm",
  status = "active"
) {
  let url = `${apiUrl}/companies/${company}/tradeMarkOppositions?status=${status}`;
  const options = {
    method: "GET",
    headers: {
      Authorization: Cookies.get("token")
    },
    url: url
  };
  return axios(options);
}

export async function postTrademarkOpposition(trademark, company) {
  let url = `${apiUrl}/companies/${company}/tradeMarkOppositions`;
  const options = {
    method: "POST",
    headers: {
      Authorization: Cookies.get("token")
    },
    data: trademark,
    url: url
  };
  return axios(options);
}

export async function putTrademarkOpposition(
  trademark,
  company,
  tradeMarkOppositionId
) {
  let url = `${apiUrl}/companies/${company}/tradeMarkOppositions/${tradeMarkOppositionId}`;
  const options = {
    method: "PUT",
    headers: {
      Authorization: Cookies.get("token")
    },
    data: trademark,
    url: url
  };
  return axios(options);
}
export async function getTrademarkOppositionById(
  tradeMarkOppositionId,
  company = "vtm"
) {
  let url = `${apiUrl}/companies/${company}/tradeMarkOppositions/${tradeMarkOppositionId}`;
  const options = {
    method: "GET",
    headers: {
      Authorization: Cookies.get("token")
    },
    url: url
  };
  return axios(options);
}
export async function deleteTrademarkOpposition(
  company,
  tradeMarkOppositionId
) {
  let url = `${apiUrl}/companies/${company}/tradeMarkOppositions/${tradeMarkOppositionId}`;
  const options = {
    method: "DELETE",
    headers: {
      Authorization: Cookies.get("token")
    },
    url: url
  };
  return axios(options);
}

export async function getTrademarkOppositionByKey(company, key) {
  let url = `${apiUrl}/companies/${company}/tradeMarkOppositions?key=${key}`;
  const options = {
    method: "GET",
    headers: {
      Authorization: Cookies.get("token")
    },
    url: url
  };
  return axios(options);
}
