import React, { useState } from "react";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { Grid, IconButton, Paper, Typography } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { filterStyles } from "styles/dashboardStyles";
import Toaster from "components/shared/Toaster";
import DataList from "./DataList";
import CardWrapper from "components/shared/CardWrapper";

export default function Filter(props) {
  let [dateInfo, setDateInfo] = useState({
    startDate: null,
    endDate: null
  });
  let classes = filterStyles();
  let [showToaster, setShowToaster] = useState(false);
  let [searchApplied, setSearchApplied] = useState(false);
  let [toastMessage, setToastMessage] = useState("");

  function handleDateChange(date, field) {
    setDateInfo((prevInfo) => {
      return { ...prevInfo, [field]: date };
    });
  }

  function handleSearch() {
    let { startDate, endDate } = dateInfo;
    if (!startDate || !endDate) {
      setToastMessage("Enter both start date and end date");
      setShowToaster(true);
      return;
    } else if (endDate.isBefore(startDate)) {
      setToastMessage("End date should be greater than start date");
      setShowToaster(true);
      return;
    }
    let formatedStartDate = startDate.format("DD/MM/YYYY");
    let formatedEndDate = endDate.format("DD/MM/YYYY");
    setSearchApplied(true);
    props.onSearch(formatedStartDate, formatedEndDate);
  }

  let { filterResult } = props;

  return (
    <>
      <Paper className={classes.paperStyles}>
        <Grid container spacing="2">
          <Grid item xs="5">
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                autoOk
                inputVariant="outlined"
                variant="inline"
                label="Start Date"
                format="DD-MM-YYYY"
                id={`form3DateRangeStart`}
                KeyboardButtonProps={{
                  "aria-label": "change date"
                }}
                value={dateInfo.startDate}
                onChange={(date) => handleDateChange(date, "startDate")}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs="5">
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                autoOk
                inputVariant="outlined"
                variant="inline"
                label="End Date"
                format="DD-MM-YYYY"
                id={`form3DateRangeStart`}
                KeyboardButtonProps={{
                  "aria-label": "change date"
                }}
                onChange={(date) => handleDateChange(date, "endDate")}
                value={dateInfo.endDate}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs="2">
            <IconButton onClick={handleSearch}>
              <Search />
            </IconButton>
          </Grid>
        </Grid>
        <Toaster
          open={showToaster}
          message={toastMessage}
          type={"error"}
          onClose={() => setShowToaster(false)}
        />
      </Paper>
      {props.loading && (
        <CardWrapper className={classes.dataNotFound}>
          <Typography color="textSecondary">Loading...</Typography>
        </CardWrapper>
      )}
      {!props.loading && !filterResult && searchApplied ? (
        <CardWrapper className={classes.dataNotFound}>
          <Typography color="textSecondary">No Data Found</Typography>
        </CardWrapper>
      ) : (
        <Grid container spacing="2" className="cardContent">
          {filterResult &&
            Object.keys(filterResult).map((date) => {
              return (
                <Grid item xs="12" key={date}>
                  <DataList date={date} data={filterResult} key={date} />
                </Grid>
              );
            })}
        </Grid>
      )}
    </>
  );
}
