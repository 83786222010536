import axios from "axios";
import apiUrl from "config/environment";

import Cookies from "js-cookie";

export async function getPatent(company = "vtm", status = "active") {
  let url = `${apiUrl}/companies/${company}/patents?status=${status}`;
  const options = {
    method: "GET",
    headers: {
      Authorization: Cookies.get("token")
    },
    url: url
  };
  return axios(options);
}

export async function getPatentById(patentId, company = "vtm") {
  let _patentId = patentId.replace(/\//g, "_");
  let url = `${apiUrl}/companies/${company}/patents/${_patentId}`;
  const options = {
    method: "GET",
    headers: {
      Authorization: Cookies.get("token")
    },
    url: url
  };
  return axios(options);
}

export async function getPatentsByKey(company, key) {
  let url = `${apiUrl}/companies/${company}/patents?key=${key}`;
  const options = {
    method: "GET",
    headers: {
      Authorization: Cookies.get("token")
    },
    url: url
  };
  return axios(options);
}

export async function getPatentsByDate(company, key, startDate, endDate) {
  let url = `${apiUrl}/companies/${company}/patents?key=${key}&startDate=${startDate}&endDate=${endDate}`;
  const options = {
    method: "GET",
    headers: {
      Authorization: Cookies.get("token")
    },
    url: url
  };
  return axios(options);
}

export async function postPatent(patent, company) {
  let url = `${apiUrl}/companies/${company}/patents`;
  const options = {
    method: "POST",
    headers: {
      Authorization: Cookies.get("token")
    },
    data: patent,
    url: url
  };
  return axios(options);
}

export async function putPatent(patent, company, patentId) {
  let _patentId = patentId.replace(/\//g, "_");
  let url = `${apiUrl}/companies/${company}/patents/${_patentId}`;
  const options = {
    method: "PUT",
    headers: {
      Authorization: Cookies.get("token")
    },
    data: patent,
    url: url
  };
  return axios(options);
}

export async function deletePatent(company, patentId) {
  let _patentId = patentId.replace(/\//g, "_");
  let url = `${apiUrl}/companies/${company}/patents/${_patentId}`;
  const options = {
    method: "DELETE",
    headers: {
      Authorization: Cookies.get("token")
    },
    url: url
  };
  return axios(options);
}
