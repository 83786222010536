import React from "react";

import {
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button
} from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import CardWrapper from "components/shared/CardWrapper";
import { connect } from "react-redux";

function SearchContainer(props) {
  function getFilteredCompanies() {
    if (
      ["Trademark Opposition", "Trademark", "Patents"].includes(props.entity)
    ) {
      return props.companies.filter((company) => {
        return ["vtm", "absolute", "active", "va"].includes(company.companyId);
      });
    }
    return props.companies;
  }

  return (
    <CardWrapper sectionHeading="Trade Marks List">
      <Grid container spacing="2">
        <Grid item xs="3">
          <TextField
            label="Search"
            fullWidth
            onChange={(event) => props.setSearchedWord(event.target.value)}
            variant="outlined"
            value={props.searchedWord}
          />
        </Grid>
        {!props.avoidTypeFilter && (
          <Grid item xs="3">
            <FormControl fullWidth variant="outlined">
              <InputLabel>Type</InputLabel>
              <Select
                label="Type"
                onChange={(event) => props.setSelectedType(event.target.value)}
                value={props.selectedType}
              >
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="archived">Archived</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        )}
        <Grid item xs="3">
          <FormControl fullWidth variant="outlined">
            <InputLabel>Company</InputLabel>
            <Select
              onChange={(event) => props.setSearchedCompany(event.target.value)}
              label="Company"
              value={props.searchedCompany}
            >
              {props.isAllFilter && <MenuItem value="all">All</MenuItem>}
              {getFilteredCompanies().map((company, index) => (
                <MenuItem key={"compnay-" + index} value={company.companyId}>
                  {company.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          xs="3"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            marginLeft: "auto"
          }}
        >
          <Button
            style={{ display: "flex", marginLeft: "auto" }}
            color="primary"
            onClick={props.handleDataExport}
          >
            Export as Excel
            <GetAppIcon style={{ marginLeft: "8px" }} />
          </Button>
        </Grid>
      </Grid>
    </CardWrapper>
  );
}

const mapStateToProps = (state) => {
  return {
    companies: state.tableReducer.companies
  };
};

export default connect(mapStateToProps)(SearchContainer);
