import React, { useState } from "react";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";
import FolderIcon from "@material-ui/icons/Folder";
import Badge from "@material-ui/core/Badge";
import "./dashboard.scss";
import { CardActions, Typography } from "@material-ui/core";
import DataList from "./DataList";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import Skeleton from "react-loading-skeleton";

function UIskeleton() {
  return (
    <div>
      <Skeleton duration={2} circle={true} height={20} width={20} />
      <div>
        <Skeleton duration={2} width="20%" />
      </div>
      <div>
        <Skeleton duration={2} width="50%" />
      </div>
      <div>
        <Skeleton duration={2} width="80%" />
      </div>
      <Skeleton duration={2} />
    </div>
  );
}
export default function DashboardCard(props) {
  let [expanded, setExpanded] = useState(false);
  return (
    <Card>
      <CardHeader
        className={props.entity === "patent" && "head2"}
        avatar={
          <Badge badgeContent={props.count || 0} color="error">
            <FolderIcon aria-label="recipe"></FolderIcon>
          </Badge>
        }
        action={
          <IconButton
            aria-label="settings"
            onClick={() => props.handleFilter(props.field)}
          >
            <FilterListIcon />
          </IconButton>
        }
        title={props.field}
      />
      <CardContent className={!props.data ? "cardContent" : "cardContent bg"}>
        {!props.data ? (
          <>
            {" "}
            {!props.loading && (
              <Typography color="textSecondary">No Data Found</Typography>
            )}
            {props.loading && UIskeleton()}
          </>
        ) : (
          <>
            <DataList
              entity={props.entity}
              date={Object.keys(props.data)[0]}
              data={props.data}
              key={Object.keys(props.data)[0]}
              handleAction={props.handleAction}
            />
            {expanded &&
              Object.keys(props.data)
                .slice(1, Object.keys(props.data).length)
                .map((date) => {
                  return (
                    <DataList
                      entity={props.entity}
                      date={date}
                      data={props.data}
                      key={date}
                      handleAction={props.handleAction}
                    />
                  );
                })}
          </>
        )}
      </CardContent>
      {props.data && Object.keys(props.data).length > 1 && (
        <CardActions disableSpacing>
          <IconButton
            onClick={() => setExpanded((prevState) => !prevState)}
            aria-label="show more"
          >
            {expanded ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </CardActions>
      )}
    </Card>
  );
}
