import axios from "axios";
import apiUrl from "config/environment";

import Cookies from "js-cookie";

export async function getCompanies() {
  let url = `${apiUrl}/companies`;
  const options = {
    method: "GET",
    headers: {
      Authorization: Cookies.get("token")
    },
    url: url
  };
  return axios(options);
}

export async function getCompanyByName(company) {
  let url = `${apiUrl}/companies/${company}`;
  const options = {
    method: "GET",
    headers: {
      Authorization: Cookies.get("token")
    },
    url: url
  };
  return axios(options);
}

export async function getCompanyBankDetials(company) {
  let url = `${apiUrl}/companies/${company}/bankDetails`;
  const options = {
    method: "GET",
    headers: {
      Authorization: Cookies.get("token")
    },
    url: url
  };
  return axios(options);
}
