import React, { useEffect, useState } from "react";
import PopUp from "components/shared/Popup";
import SectionCard from "components/shared/SectionCard";
import { Grid } from "@material-ui/core";
import "./trademark.scss";
import UIDatepicker from "components/shared/Datepicker";
import Toaster from "components/shared/Toaster";
import { putPatent } from "api/patentApi";

export default function PatentView(props) {
  let { patentInfo, currentField } = props;
  let [updatedPatentInfo, setPatentInfo] = useState({});
  let [toasterInfo, setToasterInfo] = useState({});
  let deadlineLabel = {
    "Form 27": "form27Deadline",
    "Form 18": "form18Deadline",
    FER: "ferDeadline",
    "Form 3": "form3Deadline",
    "Form 1": "form1Deadline",
    "Renewal Due Date": "renewalDeadline",
    "Eng Trans Pr. Doc": "priorityDocumentDeadline",
    "Eng Trans PCT Doc": "pctDocumentDeadline",
    Hearing: "hearingDate"
  };

  useEffect(() => {
    setPatentInfo({ ...patentInfo });
  }, [patentInfo]);

  function handleInputChange(value, field) {
    setPatentInfo({ ...updatedPatentInfo, [field]: value });
  }

  async function handleUpdate() {
    let updatedSuccess = await proceedUpdatePatent(updatedPatentInfo);
    if (updatedSuccess) {
      setToasterInfo({
        show: true,
        message: "Updated Success",
        type: "success"
      });
      setTimeout(() => {
        props.onClose(true);
      }, 500);
    }
  }

  async function proceedUpdatePatent(payload) {
    try {
      let response = await putPatent(
        payload,
        payload.companyId,
        payload.patentId
      );
      return response;
    } catch {
      setToasterInfo({
        show: true,
        message: "Something went wrong",
        type: "error"
      });
    }
  }

  return (
    <PopUp
      size="sm"
      open={true}
      fullWidth
      onClose={() => props.onClose()}
      title="View Patent"
      actionLabel="Update"
      onSave={handleUpdate}
    >
      <div className="view-trademark">
        <SectionCard entity="patent" title="Patent Details" bg={true}>
          <Grid container spacing="1">
            <Grid item xs="6">
              <label>File No</label>
              <p>{patentInfo.fileNo || "-NA-"}</p>
            </Grid>
            <Grid item xs="6">
              <label>Reference No</label>
              <p>{patentInfo.referenceNo || "-NA-"}</p>
            </Grid>
            <Grid item xs="6">
              <label>Status</label>
              <p>{patentInfo.status || "-NA-"}</p>
            </Grid>
            <Grid item xs="6">
              <label>Application No.</label>
              <p>{patentInfo.applicationNo || "-NA-"}</p>
            </Grid>
            <Grid item xs="6">
              <label>Attorney Name</label>
              <p>{patentInfo.attorneyName || "-NA-"}</p>
            </Grid>
            <Grid item xs="12">
              <UIDatepicker
                label={`${currentField} Deadline`}
                onChange={(date) =>
                  handleInputChange(date, deadlineLabel[currentField])
                }
                value={updatedPatentInfo[deadlineLabel[currentField]] || null}
              />
            </Grid>
          </Grid>
        </SectionCard>
      </div>
      <Toaster
        open={toasterInfo.show}
        message={toasterInfo.message}
        type={toasterInfo.type}
        onClose={() => setToasterInfo({})}
      />
    </PopUp>
  );
}
