import React, { useEffect, useState } from "react";
import PopUp from "components/shared/Popup";
import SectionCard from "components/shared/SectionCard";
import { Button, Grid, IconButton, TextField } from "@material-ui/core";
import "./trademark.scss";
import UIDatepicker from "components/shared/Datepicker";
import { putTrademark } from "api/trademarkApi";
import Toaster from "components/shared/Toaster";
import { Delete } from "@material-ui/icons";
import { putTrademarkOpposition } from "api/trademarkOppositionApi";
export default function TradeMarkView(props) {
  let { trademarkInfo, currentField } = props;
  let [updatedTrademarkInfo, setTrademarkInfo] = useState({});
  let [toasterInfo, setToasterInfo] = useState({});
  let [hearingHistory, setHearingHistory] = useState([
    {
      hearingHistoryComment: "",
      hearingHistoryDate: null
    }
  ]);
  const labelFieldMapping = {
    "ER Issue": "erReplyDeadline",
    "Renewed Upto": "renewedUptoDate",
    "Show Cause Hearing": "showCauseHearingDate",
    "TM-O (Notice of opp.)": "tmoDeadline",
    "TM-O (Counter St.)": "tmoCounterDeadline",
    "Opposition Hearing": "hearing",
    "Rule 45": "rule45Deadline",
    "Rule 46": "rule46Deadline",
    "Rule 47": "rule47Deadline"
  };

  useEffect(() => {
    setTrademarkInfo({ ...trademarkInfo });
  }, [trademarkInfo]);

  function handleInputChange(value, field) {
    setTrademarkInfo({ ...updatedTrademarkInfo, [field]: value });
  }

  async function handleUpdate() {
    let updatedSuccess;
    if (props.type === "Trademark") {
      updatedSuccess = await proceedUpdateTrademark(updatedTrademarkInfo);
    } else {
      updatedSuccess = await proceedUpdateTrademarkOpp(updatedTrademarkInfo);
    }
    if (updatedSuccess) {
      setToasterInfo({
        show: true,
        message: "Updated Success",
        type: "success"
      });
      setTimeout(() => {
        props.onClose(true);
      }, 500);
    }
  }

  async function proceedUpdateTrademarkOpp(payload) {
    if (currentField === "Opposition Hearing") {
      payload.hearingHistory = hearingHistory;
    }

    try {
      let response = await putTrademarkOpposition(
        payload,
        payload.companyId,
        payload.oppositionId
      );
      return response;
    } catch {
      setToasterInfo({
        show: true,
        message: "Something went wrong",
        type: "error"
      });
    }
  }

  async function proceedUpdateTrademark(payload) {
    payload.tradeMarkId = Number(trademarkInfo.tradeMarkId);
    try {
      let response = await putTrademark(
        payload,
        payload.companyId,
        payload.tradeMarkId
      );
      return response;
    } catch {
      setToasterInfo({
        show: true,
        message: "Something went wrong",
        type: "error"
      });
    }
  }

  function handleAddHearing() {
    if (hearingHistory.length > 0) {
      let lastEdited = hearingHistory[hearingHistory.length - 1];
      if (lastEdited.hearingHistoryComment && lastEdited.hearingHistoryDate) {
        setHearingHistory([
          ...hearingHistory,
          {
            hearingHistoryComment: "",
            hearingHistoryDate: null
          }
        ]);
      } else {
        setToasterInfo({
          type: "error",
          show: true,
          message: "Enter Values for previously added hearing"
        });
      }
    } else {
      setHearingHistory([
        {
          hearingHistoryComment: "",
          hearingHistoryDate: null
        }
      ]);
    }
  }

  function handleHearingInfoChange(value, field, index) {
    let updatedHearingHistory = [...hearingHistory];
    updatedHearingHistory[index][field] = value;
    setHearingHistory([...updatedHearingHistory]);
  }

  function handleHearingDelete(index) {
    let updatedHearingHistory = [...hearingHistory];
    updatedHearingHistory.splice(index, 1);
    setHearingHistory(updatedHearingHistory);
  }

  return (
    <PopUp
      size="sm"
      open={true}
      fullWidth
      onClose={() => props.onClose()}
      title="View Trade mark"
      actionLabel="Update"
      onSave={handleUpdate}
    >
      <div className="view-trademark">
        <SectionCard entity="trademark" title="File Details" bg={true}>
          <Grid container spacing="1">
            <Grid item xs="6">
              <label>File No</label>
              <p>{trademarkInfo.fileNo || "-NA-"}</p>
            </Grid>
            <Grid item xs="6">
              <label>Application No</label>
              <p>{trademarkInfo.tradeMarkId || "-NA-"}</p>
            </Grid>
            <Grid item xs="6">
              <label>Name/TM</label>
              <p>{trademarkInfo.name || "-NA-"}</p>
            </Grid>
            <Grid item xs="6">
              <label>Class</label>
              <p>{trademarkInfo.class || "-NA-"}</p>
            </Grid>
            <Grid item xs="6">
              <label>Applicant Name</label>
              <p>{trademarkInfo.applicantName || "-NA-"}</p>
            </Grid>
            <Grid item xs="6">
              <label>Status</label>
              <p>{trademarkInfo.status || "-NA-"}</p>
            </Grid>
            <Grid item xs="6">
              <label>Date/ Proposed to be used </label>
              <p>{trademarkInfo.usedSinceDate || "-NA-"}</p>
            </Grid>
            <Grid item xs="6">
              <label>Deadline</label>
              <p>{trademarkInfo.erReplyDeadline || "-NA-"}</p>
            </Grid>
            <Grid item xs="12">
              {currentField !== "Opposition Hearing" && (
                <>
                  <label>{[currentField]} date</label>
                  <UIDatepicker
                    label={currentField}
                    onChange={(date) =>
                      handleInputChange(date, labelFieldMapping[currentField])
                    }
                    value={
                      updatedTrademarkInfo[labelFieldMapping[currentField]] ||
                      null
                    }
                  />
                </>
              )}
            </Grid>
          </Grid>
          <Grid container style={{ marginBottom: "16px" }} spacing="2">
            {currentField === "Opposition Hearing" &&
              hearingHistory.map((hearing, index) => {
                return (
                  <>
                    <Grid item xs="5">
                      <TextField
                        label="Hearing Comment"
                        variant="outlined"
                        value={hearing.hearingHistoryComment}
                        onChange={(event) =>
                          handleHearingInfoChange(
                            event.target.value,
                            "hearingHistoryComment",
                            index
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs="5">
                      <UIDatepicker
                        label={"Hearing History Date"}
                        onChange={(date) =>
                          handleHearingInfoChange(
                            date,
                            "hearingHistoryDate",
                            index
                          )
                        }
                        value={hearing.hearingHistoryDate}
                      />
                    </Grid>
                    <Grid item xs="2">
                      <IconButton onClick={() => handleHearingDelete(index)}>
                        <Delete />
                      </IconButton>
                    </Grid>
                  </>
                );
              })}
          </Grid>
          {currentField === "Opposition Hearing" && (
            <Grid item xs="12">
              <Button
                color="primary"
                variant="outlined"
                onClick={handleAddHearing}
              >
                Add Hearing
              </Button>
            </Grid>
          )}
        </SectionCard>
      </div>
      <Toaster
        open={toasterInfo.show}
        message={toasterInfo.message}
        type={toasterInfo.type}
        onClose={() => setToasterInfo({})}
      />
    </PopUp>
  );
}
