import React, { useEffect, useRef, useState } from "react";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@material-ui/core";
import { connect } from "react-redux";
import SectionCard from "components/shared/SectionCard";
import PopUp from "components/shared/Popup";
import { postClient, putClient } from "api/clientApi";
import { isValidEmail } from "utils/common";
import Toaster from "components/shared/Toaster";

function CreateClients(props) {
  let [clientInfo, setClientInfo] = useState({
    clientId: "",
    name: "",
    address: "",
    gstin: "",
    email: "",
    phone: "",
    companyId: "",
    representativeName: ""
  });
  let [error, setError] = useState({});
  let [showToaster, setShowToaster] = useState(false);
  let [toastMessage, setToastMessage] = useState("");
  let [toastType, setToastType] = useState("error");

  let formRef = useRef();
  let selectRef = useRef();
  let { edit } = props;

  useEffect(init, []);

  function init() {
    if (edit) {
      setClientInfo({ ...props.editedClient });
    }
  }

  function handleInputChange(value, field, event) {
    if (field === "phone" && !event.target.validity.valid) {
      return;
    }
    setClientInfo({ ...clientInfo, [field]: value });
    setError({ ...error, [field]: false });
  }

  async function handleSave() {
    let validPayload = validatePayload();
    if (validPayload) {
      if (edit) {
        let clientCreated = await proceedUpdateClient();
        if (clientCreated) {
          setToastMessage("Client Updated Successfully");
          setToastType("success");
          setShowToaster(true);
          setTimeout(() => {
            props.onClose(true);
          }, 1000);
        }
      } else {
        let clientCreated = await proceedCreateClient();
        if (clientCreated) {
          setToastMessage("Client Created Successfully");
          setToastType("success");
          setShowToaster(true);
          setTimeout(() => {
            props.onClose(true);
          }, 1000);
        }
      }
    }
  }

  async function proceedCreateClient() {
    let payload = { ...clientInfo };
    payload.clientId = Number(clientInfo.clientId);
    try {
      let response = await postClient(payload, payload.companyId);
      return response;
    } catch {}
  }

  async function proceedUpdateClient() {
    let payload = { ...clientInfo };
    payload.clientId = Number(clientInfo.clientId);
    try {
      let response = await putClient(
        payload,
        payload.companyId,
        payload.clientId
      );
      return response;
    } catch {}
  }

  function validatePayload() {
    let valid = true;
    let updatedError = { ...error };
    if (!clientInfo.companyId) {
      selectRef.current.focus();
      updatedError.companyId = true;
      valid = false;
    } else if (
      !clientInfo.clientId ||
      (clientInfo.clientId &&
        (clientInfo.clientId.length < 3 || clientInfo.clientId.length > 8))
    ) {
      const field = getFieldElement("fileNo");
      field.focus();
      updatedError.clientId = true;
      valid = false;
    } else if (!clientInfo.name) {
      const field = getFieldElement("name");
      field.focus();
      updatedError.name = true;
      valid = false;
    } else if (!clientInfo.address) {
      const field = getFieldElement("address");
      field.focus();
      updatedError.address = true;
      valid = false;
    } else if (clientInfo.email && !isValidEmail(clientInfo.email)) {
      const field = getFieldElement("email");
      field.focus();
      updatedError.email = true;
      valid = false;
    } else if (clientInfo.gstin && clientInfo.gstin.length < 15) {
      const field = getFieldElement("gstin");
      field.focus();
      updatedError.gstin = true;
      valid = false;
      setToastMessage("GST No must be 15 digit");
      setToastType("error");
      setShowToaster(true);
    }
    setError(updatedError);
    return valid;
  }

  function getFieldElement(fieldName) {
    return formRef.current.querySelector(`[name=${fieldName}]`);
  }

  return (
    <PopUp
      size="md"
      open={true}
      fullWidth
      onClose={() => props.onClose()}
      title={`${edit ? "Update" : "Create"} Client`}
      onSave={handleSave}
      actionLabel={`${edit ? "Update" : "Save"}`}
    >
      <SectionCard title="Client Details" bg={true}>
        <Grid container spacing="1" ref={formRef}>
          <Grid item xs="4">
            <FormControl required fullWidth variant="outlined">
              <InputLabel>Company</InputLabel>
              <Select
                disabled={edit ? true : false}
                error={error.companyId}
                inputRef={selectRef}
                label="Company"
                value={clientInfo.companyId}
                required
                onChange={(event) =>
                  handleInputChange(event.target.value, "companyId")
                }
              >
                {props.companies.map((company, index) => (
                  <MenuItem key={"compnay-" + index} value={company.companyId}>
                    {company.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs="4">
            <TextField
              className="mb-3 w-full"
              label="File No."
              variant="outlined"
              name="fileNo"
              disabled={edit}
              type="number"
              required
              value={clientInfo.clientId}
              error={error.clientId}
              helperText={
                error.clientId &&
                "File No. should be between 3 to 8 characters only"
              }
              onChange={(event) =>
                handleInputChange(event.target.value, "clientId")
              }
            />
          </Grid>
          <Grid item xs="4">
            <TextField
              className="mb-3 w-full"
              label="Name"
              variant="outlined"
              required
              name="name"
              type="text"
              value={clientInfo.name}
              error={error.name}
              onChange={(event) =>
                handleInputChange(event.target.value, "name")
              }
            />
          </Grid>
          <Grid item xs="8">
            <TextField
              required
              className="mb-3 w-full"
              label="Address"
              variant="outlined"
              name="address"
              type="text"
              multiline
              rows={3}
              value={clientInfo.address}
              error={error.address}
              onChange={(event) =>
                handleInputChange(event.target.value, "address")
              }
            />
          </Grid>
          <Grid item xs="4">
            <TextField
              className="mb-3 w-full"
              label="Email"
              variant="outlined"
              name="email"
              type="text"
              error={error.email}
              helperText={error.email && "Please enter valid email"}
              value={clientInfo.email}
              onChange={(event) =>
                handleInputChange(event.target.value, "email")
              }
            />
          </Grid>
          <Grid item xs="4">
            <TextField
              className="mb-3 w-full"
              label="Phone"
              variant="outlined"
              name="fileNo"
              type="text"
              inputProps={{ pattern: "^[0-9+-]+$" }}
              value={clientInfo.phone}
              onChange={(event) =>
                handleInputChange(event.target.value, "phone", event)
              }
            />
          </Grid>
          <Grid item xs="4">
            <TextField
              className="mb-3 w-full"
              label="GST No."
              error={error.gstin}
              variant="outlined"
              name="gstin"
              inputProps={{
                maxLength: 15
              }}
              type="text"
              value={clientInfo.gstin}
              onChange={(event) =>
                handleInputChange(event.target.value, "gstin")
              }
            />
          </Grid>
          <Grid item xs="4">
            <TextField
              className="mb-3 w-full"
              label="Representative Name"
              variant="outlined"
              name="fileNo"
              type="text"
              value={clientInfo.representativeName}
              onChange={(event) =>
                handleInputChange(event.target.value, "representativeName")
              }
            />
          </Grid>
        </Grid>
      </SectionCard>
      <Toaster
        open={showToaster}
        message={toastMessage}
        type={toastType}
        onClose={() => setShowToaster(false)}
      />
    </PopUp>
  );
}

const mapStateToProps = (state) => {
  return {
    companies: state.tableReducer.companies
  };
};

export default connect(mapStateToProps)(CreateClients);
