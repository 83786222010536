import React, { useEffect, useRef, useState } from "react";
import MaterialTable from "components/shared/Material-Table";
import { resetLoader, setLoader } from "store/actions/table";
import { connect } from "react-redux";
import SearchContainer from "../../shared/Search";
import queryString from "query-string";
import ClientColumns from "../../../utils/tableColumns/client";
import AddButton from "components/shared/AddButton";
import { getClients } from "api/clientApi";
import ReactExport from "react-data-export";
import CreateClients from "./CreateClients";
import ViewClient from "./ViewClient";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

function Clients(props) {
  let [tableData, setTableData] = useState([]);
  let [searchedWord, setSearchedWord] = useState("");
  let [searchedCompany, setSearchedCompany] = useState("");
  let fullData = useRef([]);
  let [isDownloadExcel, setIsDownloadExcel] = useState(false);
  let [excelData, setExcelData] = useState([]);
  let [showCreate, setShowCreate] = useState(false);
  let [editedClient, setEditedClient] = useState({});
  let [showPreview, setShowPreview] = useState(false);

  useEffect(init, []);

  function init() {
    fetchClients();
  }

  async function fetchClients() {
    let search = queryString.parse(props.history.location.search);
    if (!search.company) {
      search.company = "va";
      props.history.push(
        `${props.history.location.pathname}?${queryString.stringify(search)}`
      );
      fechClientsByCompany(search.company);
    } else {
      fechClientsByCompany(search.company);
    }
    setSearchedCompany(search.company);
  }

  async function fechClientsByCompany(company) {
    props.setLoader();
    try {
      let response = await getClients(company);
      if (response && response.data) {
        fullData.current = response.data;
        let search = queryString.parse(props.history.location.search);
        delete search.company;
        if (Object.keys(search).length === 0) {
          setTableData(response.data);
        } else {
          getFileredResult(response.data, search);
        }
      }
    } catch {}
    props.resetLoader();
  }

  function getFileredResult(allBils, searchParams) {
    if (searchParams.q) {
      setSearchedWord(searchParams.q);
      allBils = filterDataByValue(searchParams.q, allBils);
    }
    setTableData(allBils);
  }

  function handleSearchedCompany(value) {
    setSearchedCompany(value);
    let search = queryString.parse(props.history.location.search);

    if (value) {
      search.company = value;
      fechClientsByCompany(value);
    } else if (!search.q) {
      delete search.company;
      fechClientsByCompany("all");
    } else if (search.q) {
      let filteredData = filterDataByValue(search.q);
      setTableData(filteredData);
    }
    props.history.push(
      `${props.history.location.pathname}?${queryString.stringify(search)}`
    );
  }

  function handleSearchedWord(value) {
    setSearchedWord(value);
    let text = value.toLowerCase();
    let search = queryString.parse(props.history.location.search);
    let data = [...fullData.current];
    if (search.company && tableData.length > 0) {
      data = [...tableData];
    }
    if (value) {
      search.q = value;
      let filteredData = filterDataByValue(text, data);
      setTableData(filteredData);
    } else if (!searchedCompany) {
      delete search.q;
      setTableData([...fullData.current]);
    } else {
      delete search.q;
      setTableData([...fullData.current]);
    }
    props.history.push(
      `${props.history.location.pathname}?${queryString.stringify(search)}`
    );
  }

  function filterDataByValue(text, allClients = fullData.current) {
    let filteredData = allClients.filter((data) => {
      return (
        String(data.clientId).includes(text) ||
        data.name.toLowerCase().includes(text) ||
        data.address.toLowerCase().includes(text) ||
        data.gstin.includes(text) ||
        data.email.toLowerCase().includes(text) ||
        data.phone.toLowerCase().includes(text)
      );
    });
    return filteredData;
  }

  function handleDataExport() {
    let { columnField, columnLabel } = ClientColumns.reduce(
      (acc, column) => {
        if (column.title !== "Actions") {
          acc.columnField.push(column.field);
          acc.columnLabel.push(column.title);
        }
        return acc;
      },
      {
        columnField: [],
        columnLabel: []
      }
    );
    let exportData = tableData.reduce((acc, data) => {
      let values = columnField.map((field) => {
        return data[field];
      });
      acc.push(values);
      return acc;
    }, []);
    let search = queryString.parse(props.history.location.search);
    exportData = exportData.slice(0, Number(search.entries || 10));
    let dataSet = [
      {
        columns: columnLabel,
        data: exportData
      }
    ];
    setExcelData(dataSet);
    setIsDownloadExcel(true);
    setTimeout(() => {
      setIsDownloadExcel(false);
    }, 2000);
  }

  function handleAction(clientInfo, action) {
    if (action === "preview") {
      let companyInfo = props.companies.find(
        (company) => company.companyId === clientInfo.companyId
      );
      if (companyInfo) {
        clientInfo.company = companyInfo.name;
      }
    }
    setEditedClient(clientInfo);
    setShowPreview(action === "preview");
    setShowCreate(action === "edit");
  }

  function handleCreateClose(isUpdate) {
    setShowCreate(false);
    if (editedClient.clientId) {
      setEditedClient({});
    }
    if (isUpdate) {
      init();
    }
  }

  function handlePreviewClose(isUpdate) {
    setShowPreview(false);
    if (editedClient.clientId) {
      setEditedClient({});
    }
    if (isUpdate) {
      init();
    }
  }

  return (
    <>
      <SearchContainer
        setSearchedCompany={handleSearchedCompany}
        setSearchedWord={handleSearchedWord}
        handleDataExport={handleDataExport}
        searchedWord={searchedWord}
        searchedCompany={searchedCompany}
        avoidTypeFilter={true}
      />
      {props.companies.length > 0 && (
        <MaterialTable
          data={tableData}
          columns={ClientColumns}
          handleAction={handleAction}
        />
      )}
      <div
        style={{
          marginTop: "100px"
        }}
      >
        <AddButton title="Clients" onClick={() => setShowCreate(true)} />
      </div>
      {/* Dowload excel client */}
      {isDownloadExcel && (
        <ExcelFile hideElement={true} filename={"Clients"}>
          <ExcelSheet dataSet={excelData} name="Clients" />
        </ExcelFile>
      )}
      {/* Create Client dialog */}
      {showCreate && (
        <CreateClients
          onClose={handleCreateClose}
          editedClient={editedClient}
          edit={editedClient.clientId ? true : false}
        />
      )}
      {/* View Client dialog */}
      {showPreview && (
        <ViewClient onClose={handlePreviewClose} clientInfo={editedClient} />
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    companies: state.tableReducer.companies
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoader: () => dispatch(setLoader()),
    resetLoader: () => dispatch(resetLoader())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Clients);
