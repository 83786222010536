const { makeStyles } = require("@material-ui/core");
export const filterStyles = makeStyles((theme) => ({
  paperStyles: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
    width: "100%"
  },
  dataNotFound: {
    display: "flex",
    margin: theme.spacing(2, 0),
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(2, 0)
  }
}));
