import React, { useState } from "react";
import PopUp from "components/shared/Popup";
import SectionCard from "components/shared/SectionCard";
import { Button, Grid, Typography } from "@material-ui/core";
import "./viewClient.scss";
import { deleteClient } from "api/clientApi";
import Toaster from "components/shared/Toaster";

export default function ViewClient(props) {
  let {
    clientInfo: { company, name, clientId, gstin, email, address, companyId }
  } = props;
  let fields = {
    Company: company,
    Name: name,
    "File No": clientId,
    "GST No": gstin,
    Email: email,
    Address: address
  };

  let [confirmDelete, setConfirmDelete] = useState(false);
  let [toasterInfo, setToasterInfo] = useState({
    show: false,
    message: "",
    type: ""
  });

  async function handleDelete() {
    let deletedSuccess = await proceedClientDelete();
    if (deletedSuccess) {
      setToasterInfo({
        show: true,
        message: "Client deleted success",
        type: "success"
      });
      setTimeout(() => {
        props.onClose(true);
      }, 1000);
    }
  }

  async function proceedClientDelete() {
    try {
      await deleteClient(companyId, clientId);
      return "deleted success";
    } catch {
      setToasterInfo({
        show: true,
        message: "Something went wrong",
        type: "error"
      });
    }
  }

  function renderActions() {
    if (confirmDelete) {
      return (
        <>
          <Typography>
            Are you sure you want to delete File No. {clientId}.
          </Typography>
          <Button color="primary" variant="contained" onClick={handleDelete}>
            Yes
          </Button>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => setConfirmDelete(false)}
          >
            No
          </Button>
        </>
      );
    } else {
      return (
        <Button
          color="primary"
          variant="contained"
          onClick={() => setConfirmDelete(true)}
        >
          Delete Client
        </Button>
      );
    }
  }

  return (
    <PopUp
      size="sm"
      open={true}
      fullWidth
      onClose={() => props.onClose()}
      title="View Client"
      renderActions={renderActions}
    >
      <SectionCard title="Client Details" nopadding bg>
        {Object.keys(fields).map((field, i) => {
          return (
            <Grid container className="listStyle" key={"details-" + i}>
              <Grid item xs="6">
                <Typography variant="h5" component="span">
                  <b>{field}</b>
                </Typography>
              </Grid>
              <Grid item xs="6">
                <Typography variant="h6" component="span">
                  {fields[field] || "N/A"}
                </Typography>
              </Grid>
            </Grid>
          );
        })}
      </SectionCard>
      <Toaster
        open={toasterInfo.show}
        message={toasterInfo.message}
        type={toasterInfo.type}
        onClose={() => setToasterInfo({})}
      />
    </PopUp>
  );
}
