import axios from "axios";
import apiUrl from "config/environment";

import Cookies from "js-cookie";

export async function getClients(company) {
  let url = `${apiUrl}/companies/${company}/clients`;
  const options = {
    method: "GET",
    headers: {
      Authorization: Cookies.get("token")
    },
    url: url
  };
  return axios(options);
}

export async function postClient(client, company) {
  let url = `${apiUrl}/companies/${company}/clients`;
  const options = {
    method: "POST",
    headers: {
      Authorization: Cookies.get("token")
    },
    data: client,
    url: url
  };
  return axios(options);
}

export async function putClient(client, company, clientId) {
  let url = `${apiUrl}/companies/${company}/clients/${clientId}`;
  const options = {
    method: "PUT",
    headers: {
      Authorization: Cookies.get("token")
    },
    data: client,
    url: url
  };
  return axios(options);
}

export async function getClientById(clientId, company = "vtm") {
  let url = `${apiUrl}/companies/${company}/clients/${clientId}`;
  const options = {
    method: "GET",
    headers: {
      Authorization: Cookies.get("token")
    },
    url: url
  };
  return axios(options);
}

export async function deleteClient(company, clientId) {
  let url = `${apiUrl}/companies/${company}/clients/${clientId}`;
  const options = {
    method: "DELETE",
    headers: {
      Authorization: Cookies.get("token")
    },
    url: url
  };
  return axios(options);
}
