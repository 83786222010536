import axios from "axios";
import apiUrl from "config/environment";

import Cookies from "js-cookie";

export async function getTrademark(company = "vtm", status = "active") {
  let url = `${apiUrl}/companies/${company}/tradeMarks?status=${status}`;
  const options = {
    method: "GET",
    headers: {
      Authorization: Cookies.get("token")
    },
    url: url
  };
  return axios(options);
}

export async function postTrademark(trademark, company) {
  let url = `${apiUrl}/companies/${company}/tradeMarks`;
  const options = {
    method: "POST",
    headers: {
      Authorization: Cookies.get("token")
    },
    data: trademark,
    url: url
  };
  return axios(options);
}

export async function putTrademark(trademark, company, trademarkId) {
  let url = `${apiUrl}/companies/${company}/tradeMarks/${trademarkId}`;
  const options = {
    method: "PUT",
    headers: {
      Authorization: Cookies.get("token")
    },
    data: trademark,
    url: url
  };
  return axios(options);
}
export async function getTrademarkById(tradeMarkId, company = "vtm") {
  let url = `${apiUrl}/companies/${company}/tradeMarks/${tradeMarkId}`;
  const options = {
    method: "GET",
    headers: {
      Authorization: Cookies.get("token")
    },
    url: url
  };
  return axios(options);
}

export async function getTrademarkByKey(company, key) {
  let url = `${apiUrl}/companies/${company}/tradeMarks?key=${key}`;
  const options = {
    method: "GET",
    headers: {
      Authorization: Cookies.get("token")
    },
    url: url
  };
  return axios(options);
}

export async function getTrademarkByDate(company, key, startDate, endDate) {
  let url = `${apiUrl}/companies/${company}/tradeMarks?key=${key}&startDate=${startDate}&endDate=${endDate}`;
  const options = {
    method: "GET",
    headers: {
      Authorization: Cookies.get("token")
    },
    url: url
  };
  return axios(options);
}
export async function deleteTrademark(company, trademarkId) {
  let url = `${apiUrl}/companies/${company}/tradeMarks/${trademarkId}`;
  const options = {
    method: "DELETE",
    headers: {
      Authorization: Cookies.get("token")
    },
    url: url
  };
  return axios(options);
}
