const trademarkStatus = [
  "Abandoned",
  "Accepted",
  "Closed",
  "ER Replied",
  "ER Issued",
  "Marked for Exam",
  "New Application",
  "Objected",
  "Opposition Hearing",
  "Opposed",
  "Protection Granted",
  "Registered",
  "Rectification Filed",
  "Refused",
  "Showcause Hearing",
  "Withdrawn"
];
const trademarkClass = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
  32,
  33,
  34,
  35,
  36,
  37,
  38,
  39,
  40,
  41,
  42,
  43,
  44,
  45,
  99
];
const oppositionDocumentStatus = ["Filed", "Received"];
const oppositionStatus = [
  "Pending",
  "Abandoned",
  "Pleadings Complete",
  "Hearing",
  "Withdrawn",
  "Registered"
];
const oppositionJurisdiction = [
  "Chennai",
  "Delhi",
  "Mumbai",
  "Ahemdabad",
  "Kolkata",
  "IR Division"
];
export {
  trademarkStatus,
  trademarkClass,
  oppositionDocumentStatus,
  oppositionStatus,
  oppositionJurisdiction
};
