import React, { useEffect, useState } from "react";
import PopUp from "components/shared/Popup";
import SectionCard from "components/shared/SectionCard";
import { Button, Grid, Typography } from "@material-ui/core";
import { deleteTrademark, getTrademarkById } from "api/trademarkApi";
import Toaster from "components/shared/Toaster";
import "./trademark.scss";

export default function ViewTrademark(props) {
  let [confirmDelete, setConfirmDelete] = useState(false);
  let [trademarkInfo, setTrademarkInfo] = useState({});
  let [toasterInfo, setToasterInfo] = useState({
    show: false,
    message: "",
    type: ""
  });
  // let { tradeMarkId, companyId } = trademarkInfo;

  useEffect(init, []);

  function init() {
    getTrademarkInfo();
  }

  async function getTrademarkInfo() {
    let info = await fetchEditedTrademark();

    setTrademarkInfo(info);
  }

  async function fetchEditedTrademark() {
    try {
      let response = await getTrademarkById(
        props.tradeMarkId,
        props.searchedCompany
      );
      if (response && response.data) {
        return response.data;
      }
    } catch {}
  }

  async function handleDelete() {
    let deletedSuccess = await proceedClientDelete();
    if (deletedSuccess) {
      setToasterInfo({
        show: true,
        message: "Trade mark deleted success",
        type: "success"
      });
      setTimeout(() => {
        props.onClose(true);
      }, 1000);
    }
  }

  async function proceedClientDelete() {
    try {
      await deleteTrademark(trademarkInfo.companyId, trademarkInfo.tradeMarkId);
      return "deleted success";
    } catch {
      setToasterInfo({
        show: true,
        message: "Something went wrong",
        type: "error"
      });
    }
  }

  function renderActions() {
    if (confirmDelete) {
      return (
        <>
          <Typography>
            Are you sure you want to delete File No. {trademarkInfo.tradeMarkId}
            .
          </Typography>
          <Button color="primary" variant="contained" onClick={handleDelete}>
            Yes
          </Button>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => setConfirmDelete(false)}
          >
            No
          </Button>
        </>
      );
    } else {
      return (
        <Button
          color="primary"
          variant="contained"
          onClick={() => setConfirmDelete(true)}
        >
          Delete Trade mark
        </Button>
      );
    }
  }

  return (
    <PopUp
      size="md"
      open={true}
      fullWidth
      onClose={() => props.onClose()}
      title="View Trade mark"
      renderActions={renderActions}
    >
      {/* <SectionCard entity="trademark" 
        title="Trade Mark Details"
        nopadding
        bg
        className="view-trademark"
      >
        <Grid container className="listStyle">
          {Object.keys(trademarkInfo).map((field, i) => {
            return (
              <Grid item xs="4" key={"details-" + i} className="details">
                <div className="content">
                  <Typography variant="h5" component="span">
                    <b>{field}</b>
                  </Typography>
                  <br></br>
                  <Typography variant="h6" component="div" class="trade-value">
                    {trademarkInfo[field] || "N/A"}
                  </Typography>
                </div>
              </Grid>
            );
          })}
        </Grid>
      </SectionCard> */}
      <div className="view-trademark">
        <SectionCard entity="trademark" title="File Details" bg={true}>
          <Grid container spacing="1">
            <Grid item xs="6">
              <label>Company</label>
              <p>{trademarkInfo.companyId || "-NA-"}</p>
            </Grid>
            <Grid item xs="6">
              <label>File No</label>
              <p>{trademarkInfo.fileNo || "-NA-"}</p>
            </Grid>
          </Grid>
        </SectionCard>
        <SectionCard entity="trademark" title="Application Details" bg={true}>
          <Grid container spacing="1">
            <Grid item xs="4">
              <label>Application No</label>
              <p>{trademarkInfo.tradeMarkId || "-NA-"}</p>
            </Grid>
            <Grid item xs="4">
              <label>Application Date</label>
              <p>{trademarkInfo.applicationDate || "-NA-"}</p>
            </Grid>
            <Grid item xs="4">
              <label>Name/TM</label>
              <p>{trademarkInfo.name || "-NA-"}</p>
            </Grid>
            <Grid item xs="4">
              <label>Class</label>
              <p>{trademarkInfo.class || "-NA-"}</p>
            </Grid>
            <Grid item xs="4">
              <label>Applicant Name</label>
              <p>{trademarkInfo.applicantName || "-NA-"}</p>
            </Grid>
            <Grid item xs="4">
              <label>Applicant Address</label>
              <p>{trademarkInfo.applicantAddress || "-NA-"}</p>
            </Grid>
            <Grid item xs="4">
              <label>Status</label>
              <p>{trademarkInfo.status || "-NA-"}</p>
            </Grid>
            <Grid item xs="6">
              <label>Goods/Services</label>
              <p>{trademarkInfo.goods || "-NA-"}</p>
            </Grid>
          </Grid>
        </SectionCard>
        <Grid container spacing="1" className="mb-3">
          <Grid item xs="6">
            <SectionCard entity="trademark" title="User Details" bg={true}>
              <Grid container spacing="1">
                <Grid item xs="6">
                  <label>Date/ Proposed to be used </label>
                  <p>{trademarkInfo.usedSinceDate || "-NA-"}</p>
                </Grid>
              </Grid>
            </SectionCard>
          </Grid>
          <Grid item xs="6">
            <SectionCard entity="trademark" title="ER Issue Details" bg={true}>
              <Grid container spacing="1">
                <Grid item xs="6">
                  <label>Deadline</label>
                  <p>{trademarkInfo.erReplyDeadline || "-NA-"}</p>
                </Grid>
                <Grid item xs="6">
                  <label>Filed date</label>
                  <p>{trademarkInfo.erReplyFiled || "-NA-"}</p>
                </Grid>
              </Grid>
            </SectionCard>
          </Grid>
        </Grid>
        <Grid container spacing="1" className="mb-3">
          <Grid item xs="4">
            <SectionCard
              entity="trademark"
              title="Showcase Hearing Date"
              bg={true}
            >
              <label>Date</label>
              <p>{trademarkInfo.showCauseHearingDate || "-NA-"}</p>
            </SectionCard>
          </Grid>
          <Grid item xs="8">
            <SectionCard entity="trademark" title="Journal Details" bg={true}>
              <Grid container spacing="1">
                <Grid item xs="6">
                  <label>Date</label>
                  <p>{trademarkInfo.journalNo || "-NA-"}</p>
                </Grid>
                <Grid item xs="6">
                  <label>Journal Date</label>
                  <p>{trademarkInfo.journalDate || "-NA-"}</p>
                </Grid>
              </Grid>
            </SectionCard>
          </Grid>
        </Grid>
        <Grid container spacing="1">
          <Grid item xs="4">
            <SectionCard entity="trademark" title="Renewed Upto Date" bg={true}>
              <label>Date</label>
              <p>{trademarkInfo.renewedUptoDate || "-NA-"}</p>
            </SectionCard>
          </Grid>
          <Grid item xs="4">
            <SectionCard entity="trademark" title="Notes" bg={true}>
              <label>Notes</label>
              <p>{trademarkInfo.notes || "-NA-"}</p>
            </SectionCard>
          </Grid>
          <Grid item xs="4">
            <SectionCard entity="trademark" title="Keywords" bg={true}>
              <label>Keywords</label>
              <p>{trademarkInfo.keywords || "-NA-"}</p>
            </SectionCard>
          </Grid>
        </Grid>
      </div>

      <Toaster
        open={toasterInfo.show}
        message={toasterInfo.message}
        type={toasterInfo.type}
        onClose={() => setToasterInfo({})}
      />
    </PopUp>
  );
}
