import React, { useEffect, useRef, useState } from "react";
import MaterialTable from "components/shared/Material-Table";
import TrademarkOppositionColumns from "utils/tableColumns/trademarkOpposition";
import { getTrademarkOpposition } from "api/trademarkOppositionApi";
import { resetLoader, setLoader, storeCompanies } from "store/actions/table";
import { connect } from "react-redux";
import { getCompanies } from "api/commonApi";
import SearchContainer from "../../shared/Search";
import queryString from "query-string";
import ReactExport from "react-data-export";

import CreateTrademarkOpposition from "./CreateTrademarkOpposition";
import ViewTrademarkOpposition from "./ViewTrademarkOpposition";
import AddButton from "components/shared/AddButton";
import Toaster from "components/shared/Toaster";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

function TradeMarkOpposition(props) {
  let [tableData, setTableData] = useState([]);
  let [searchedWord, setSearchedWord] = useState("");
  let [searchedCompany, setSearchedCompany] = useState("");
  let [selectedType, setSelectedType] = useState("");
  let fullData = useRef([]);
  let [isDownloadExcel, setIsDownloadExcel] = useState(false);
  let [excelData, setExcelData] = useState([]);
  let [selectedData, setSelectedData] = useState([]);
  let [showCreate, setShowCreate] = useState(false);
  let [editedTrademarkOpposition, setEditedTrademarkOpposition] = useState({});
  let [showPreview, setShowPreview] = useState(false);
  let [toasterInfo, setToasterInfo] = useState({
    show: false,
    message: "",
    type: ""
  });

  useEffect(init, []);

  function init() {
    if (props.companies.length === 0) {
      fetchCompanyOptions();
    } else {
      fetchTrademarks();
    }
  }

  async function fetchCompanyOptions() {
    try {
      let response = await getCompanies();
      if (response && response.data) {
        props.storeCompanies(response.data);
        fetchTrademarks(response.data);
      }
    } catch {}
  }

  function fetchTrademarks() {
    let search = queryString.parse(props.history.location.search);
    if (!search.status) {
      search.status = "active";
      search.company = "vtm";
      props.history.push(
        `${props.history.location.pathname}?${queryString.stringify(search)}`
      );
    }
    if (search.status) {
      setSelectedType(search.status);
    }
    if (search.company) {
      setSearchedCompany(search.company);
    }
    fetchTrademarkByStatus(search);
  }

  async function fetchTrademarkByStatus(search) {
    props.setLoader();
    try {
      let response = await getTrademarkOpposition(
        search.company,
        search.status
      );
      if (response && response.data) {
        let constructedData = getConstructedData(response.data);
        fullData.current = constructedData;
        let search = queryString.parse(props.history.location.search);
        delete search.status;
        if (Object.keys(search).length === 0) {
          setTableData(constructedData);
        } else {
          getFileredResult(constructedData, search);
        }
      }
    } catch {}
    props.resetLoader();
  }
  function getFileredResult(allTrademarks, searchParams) {
    // if (searchParams.company) {
    //   setSearchedCompany(searchParams.company);
    //   allTrademarks = allTrademarks.filter(
    //     (data) => data.companyId === searchParams.company
    //   );
    // }
    if (searchParams.q) {
      setSearchedWord(searchParams.q);
      allTrademarks = filterDataByValue(searchParams.q, allTrademarks);
    }
    setTableData(allTrademarks);
  }
  function getConstructedData(allData) {
    let constructedData = allData.map((data) => {
      data.rule45 = getRule45(data);
      data.rule46 = getRule46(data);
      data.rule47 = getRule47(data);
      data.tmoFiled = getTmoFiled(data);
      data.tmoCounterFiled = getTmoCounterFiled(data);
      return data;
    });
    return constructedData;
  }

  function getTmoFiled(trademark) {
    let getTmoFiled = {
      deadline: trademark.tmoDeadline,
      filed: trademark.tmoFiled
    };
    return getTmoFiled.filed
      ? getTmoFiled.filed
      : getTmoFiled.deadline
      ? "Deadline: " + getTmoFiled.deadline
      : "";
  }
  function getTmoCounterFiled(trademark) {
    let getTmoCounterFiled = {
      deadline: trademark.tmoCounterDeadline,
      filed: trademark.tmoCounterFiled
    };
    return getTmoCounterFiled.filed
      ? getTmoCounterFiled.filed
      : getTmoCounterFiled.deadline
      ? "Deadline: " + getTmoCounterFiled.deadline
      : "";
  }
  function getRule45(trademark) {
    let rule45 = {
      deadline: trademark.rule45Deadline,
      filed: trademark.rule45Filed
    };
    return rule45.filed
      ? rule45.filed
      : rule45.deadline
      ? "Deadline: " + rule45.deadline
      : "";
  }

  function getRule46(trademark) {
    let rule46 = {
      deadline: trademark.rule46Deadline,
      filed: trademark.rule46Filed
    };
    return rule46.filed
      ? rule46.filed
      : rule46.deadline
      ? "Deadline: " + rule46.deadline
      : "";
  }

  function getRule47(trademark) {
    let rule47 = {
      deadline: trademark.rule47Deadline,
      filed: trademark.rule47Filed
    };
    return rule47.filed
      ? rule47.filed
      : rule47.deadline
      ? "Deadline: " + rule47.deadline
      : "";
  }

  async function handleAction(tradeMarkInfomation, action) {
    setEditedTrademarkOpposition(tradeMarkInfomation);
    setShowPreview(action === "preview");
    setShowCreate(action === "edit");
  }
  function handleCreateClose(isUpdate) {
    setShowCreate(false);
    setEditedTrademarkOpposition({});
    if (isUpdate) {
      init();
    }
  }

  function handlePreviewClose(isUpdate) {
    setShowPreview(false);
    setEditedTrademarkOpposition({});

    if (isUpdate) {
      init();
    }
  }

  function handleSearchedCompany(value) {
    // setSearchedCompany(value);
    // let search = queryString.parse(props.history.location.search);
    // let data = [...fullData.current];
    // if (search.q) {
    //   data = [...tableData];
    // }
    // if (value) {
    //   search.company = value;
    //   let filteredData = data.filter((data) => {
    //     return data.companyId === value;
    //   });
    //   setTableData(filteredData);
    // } else if (!search.q) {
    //   delete search.company;
    //   setTableData([...fullData.current]);
    // } else if (search.q) {
    //   let filteredData = filterDataByValue(search.q);
    //   setTableData(filteredData);
    // }
    // props.history.push(
    //   `${props.history.location.pathname}?${queryString.stringify(search)}`
    // );
    setSearchedCompany(value);
    let search = queryString.parse(props.history.location.search);
    if (value) {
      search.company = value;
      fetchTrademarkByStatus({
        status: search.status,
        company: value
      });
    }
    props.history.push(
      `${props.history.location.pathname}?${queryString.stringify(search)}`
    );
  }

  async function handleSelectedType(value) {
    setSelectedType(value);
    let search = queryString.parse(props.history.location.search);
    if (value) {
      search.status = value;
      fetchTrademarkByStatus({
        status: value,
        company: search.company
      });
    }
    props.history.push(
      `${props.history.location.pathname}?${queryString.stringify(search)}`
    );
  }

  function handleSearchedWord(value) {
    setSearchedWord(value);
    let text = value.toLowerCase();
    let search = queryString.parse(props.history.location.search);
    let data = [...fullData.current];
    if (search.company && tableData.length > 0) {
      data = [...tableData];
    }
    if (value) {
      search.q = value;
      let filteredData = filterDataByValue(text, data);
      setTableData(filteredData);
    } else if (!searchedCompany) {
      delete search.q;
      setTableData([...fullData.current]);
    } else if (!value) {
      delete search.q;
      setTableData([...fullData.current]);
    }
    props.history.push(
      `${props.history.location.pathname}?${queryString.stringify(search)}`
    );
  }

  function filterDataByValue(text, allTrademarks = fullData.current) {
    let filteredData = allTrademarks.filter((data) => {
      return (
        String(data.fileNo).includes(text) ||
        String(data.oppositionId).includes(text) ||
        String(data.tradeMarkId).includes(text) ||
        data.name.toLowerCase().includes(text) ||
        String(data.class).includes(text) ||
        data.documentStatus.toLowerCase().includes(text) ||
        data.opponentName.toLowerCase().includes(text) ||
        data.applicantName.toLowerCase().includes(text) ||
        data.jurisdiction.toLowerCase().includes(text) ||
        data.tmoCounterFiled.toLowerCase().includes(text) ||
        data.tmoFiled.toLowerCase().includes(text) ||
        data.rule45.toLowerCase().includes(text) ||
        data.rule46.toLowerCase().includes(text) ||
        data.rule47.toLowerCase().includes(text) ||
        data.oppositionStatus.toLowerCase().includes(text)
      );
    });
    return filteredData;
  }

  function handleDataExport() {
    let data = selectedData.length > 0 ? selectedData : tableData;
    if (data.length === 0) {
      setToasterInfo({
        show: true,
        message: "No data available for export",
        type: "error"
      });
      return;
    }
    let { columnField, columnLabel } = TrademarkOppositionColumns.reduce(
      (acc, column) => {
        if (column.title !== "Actions") {
          acc.columnField.push(column.field);
          acc.columnLabel.push(column.title);
        }
        return acc;
      },
      {
        columnField: [],
        columnLabel: []
      }
    );
    let exportData = data.reduce((acc, data) => {
      let values = columnField.map((field) => {
        return data[field];
      });
      acc.push(values);
      return acc;
    }, []);
    let search = queryString.parse(props.history.location.search);
    exportData = exportData.slice(0, Number(search.entries || 10));
    let dataSet = [
      {
        columns: columnLabel,
        data: exportData
      }
    ];
    setExcelData(dataSet);
    setIsDownloadExcel(true);
    setTimeout(() => {
      setIsDownloadExcel(false);
    }, 2000);
  }

  function handleSelection(row) {
    setSelectedData(row);
  }

  return (
    <>
      <SearchContainer
        entity="Trademark Opposition"
        setSearchedCompany={handleSearchedCompany}
        setSearchedWord={handleSearchedWord}
        setSelectedType={handleSelectedType}
        handleDataExport={handleDataExport}
        searchedWord={searchedWord}
        searchedCompany={searchedCompany}
        selectedType={selectedType}
        isAllFilter
      />
      <MaterialTable
        selectable
        handleSelection={handleSelection}
        entity="Trademark Opposition"
        data={tableData}
        columns={TrademarkOppositionColumns}
        handleAction={handleAction}
      />
      <div
        style={{
          marginTop: "100px"
        }}
      >
        <AddButton
          title="Trademark Opposition"
          onClick={() => setShowCreate(true)}
        />
      </div>

      {/* Download trademark excel  */}
      {isDownloadExcel && (
        <ExcelFile hideElement={true} filename={"Trademarks Opposition"}>
          <ExcelSheet dataSet={excelData} name="Trademarks Opposition" />
        </ExcelFile>
      )}
      {/* Create Trademark opposition dialog */}
      {showCreate && (
        <CreateTrademarkOpposition
          onClose={handleCreateClose}
          tradeMarkId={editedTrademarkOpposition.oppositionId}
          company={editedTrademarkOpposition.companyId}
          edit={editedTrademarkOpposition.oppositionId ? true : false}
        />
      )}
      {/* View Trademark opposition dialog */}
      {showPreview && (
        <ViewTrademarkOpposition
          onClose={handlePreviewClose}
          tradeMarkId={editedTrademarkOpposition.oppositionId}
          company={editedTrademarkOpposition.companyId}
          trademarkOppInfo={editedTrademarkOpposition}
        />
      )}
      <Toaster
        open={toasterInfo.show}
        message={toasterInfo.message}
        type={toasterInfo.type}
        onClose={() => setToasterInfo({})}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    companies: state.tableReducer.companies
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoader: () => dispatch(setLoader()),
    resetLoader: () => dispatch(resetLoader()),
    storeCompanies: (companies) => dispatch(storeCompanies(companies))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TradeMarkOpposition);
