const renewalYearsOptions = [
  {
    text: "Renewal Years",
    value: "NA"
  },
  {
    text: "3rd Year",
    value: "3"
  },
  {
    text: "4th Year",
    value: "4"
  },
  {
    text: "5th Year",
    value: "5"
  },
  {
    text: "6th Year",
    value: "6"
  },
  {
    text: "7th Year",
    value: "7"
  },
  {
    text: "8th Year",
    value: "8"
  },
  {
    text: "9th Year",
    value: "9"
  },
  {
    text: "10th Year",
    value: "10"
  },
  {
    text: "11th Year",
    value: "11"
  },
  {
    text: "12th Year",
    value: "12"
  },
  {
    text: "13th Year",
    value: "13"
  },
  {
    text: "14th Year",
    value: "14"
  },
  {
    text: "15th Year",
    value: "15"
  },
  {
    text: "16th Year",
    value: "16"
  },
  {
    text: "17th Year",
    value: "17"
  },
  {
    text: "18th Year",
    value: "18"
  },
  {
    text: "19th Year",
    value: "19"
  },
  {
    text: "20th Year",
    value: "20"
  }
];

const applicationTypeOptions = [
  {
    text: "Ordinary",
    value: "Ordinary"
  },
  {
    text: "Convention",
    value: "Convention"
  },
  {
    text: "National Phase",
    value: "National Phase"
  },
  {
    text: "Divisional",
    value: "Divisional"
  }
];
const statusOptions = [
  {
    text: "Pending",
    value: "Pending"
  },
  {
    text: "Examination",
    value: "Examination"
  },
  {
    text: "Granted",
    value: "Granted"
  },
  {
    text: "Abandoned",
    value: "Abandoned"
  },
  {
    text: "Hearing",
    value: "Hearing"
  },
  {
    text: "FER Replied",
    value: "FER Replied"
  }
];
const ferExtensionOptions = [
  {
    text: "Fer extention",
    value: "NA"
  },
  {
    text: "1 Month",
    value: "1"
  },
  {
    text: "2 Months",
    value: "2"
  },
  {
    text: "3 Months",
    value: "3"
  }
];

const specificationOptions = [
  {
    text: "Complete",
    value: "Complete"
  },
  {
    text: "Provisional",
    value: "Provisional"
  }
];

export {
  renewalYearsOptions,
  statusOptions,
  applicationTypeOptions,
  ferExtensionOptions,
  specificationOptions
};
