import React, { useEffect, useRef, useState } from "react";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio
} from "@material-ui/core";

import { connect } from "react-redux";
import SectionCard from "components/shared/SectionCard";
import PopUp from "components/shared/Popup";
import {
  getTrademarkById,
  postTrademark,
  putTrademark
} from "api/trademarkApi";
import Toaster from "components/shared/Toaster";
import { trademarkClass, trademarkStatus } from "utils/dropdownData";
import UIDatepicker from "components/shared/Datepicker";

function CreateTrademark(props) {
  let [trademarkInfo, setTrademarkInfo] = useState(reqPayload());
  let [error, setError] = useState({});
  let [showToaster, setShowToaster] = useState(false);
  let [toastMessage, setToastMessage] = useState("");
  let [toastType, setToastType] = useState("error");

  let formRef = useRef();
  let selectRef = useRef();
  let statusRef = useRef();
  let { edit } = props;

  useEffect(init, []);

  function init() {
    if (props.editedTrademark) {
      let info = { ...props.editedTrademark };
      if (info.usedSinceDate && info.usedSinceDate != "Proposed to be used") {
        info.userDate = info.usedSinceDate;
        info.usedSinceDate = "date";
      }
      info.erIssue = info.erReplyDeadline
        ? "erReplyDeadline"
        : info.erReplyFiled
        ? "erReplyFiled"
        : "";
      setTrademarkInfo(info);
    } else if (props.tradeMarkId) {
      getTrademarkInfo();
    }
  }

  async function getTrademarkInfo() {
    let info = await fetchEditedTrademark();
    if (info.usedSinceDate && info.usedSinceDate != "Proposed to be used") {
      info.userDate = info.usedSinceDate;
      info.usedSinceDate = "date";
    }
    info.erIssue = info.erReplyDeadline
      ? "erReplyDeadline"
      : info.erReplyFiled
      ? "erReplyFiled"
      : "";
    setTrademarkInfo(info);
  }

  async function fetchEditedTrademark() {
    try {
      let response = await getTrademarkById(
        props.tradeMarkId,
        props.searchedCompany
      );
      if (response && response.data) {
        return response.data;
      }
    } catch {}
  }
  function reqPayload() {
    return {
      fileNo: "",
      tradeMarkId: "",
      applicationDate: null,
      name: "",
      class: "",
      applicantName: "",
      applicantAddress: "",
      status: "",
      goods: "",
      renewedUptoDate: null,
      journalNo: "",
      journalDate: null,
      showCauseHearingDate: null,
      notes: "",
      keywords: "",
      companyId: "",
      usedSinceDate: "",
      userDate: null,
      erReplyDeadline: null,
      erIssue: "",
      erReplyFiled: ""
    };
  }
  function handleInputChange(value, field) {
    let updatedTrademarkInfo = { ...trademarkInfo };
    if (value === "erReplyDeadline") {
      updatedTrademarkInfo.erReplyFiled = "";
    } else if (value === "erReplyFiled") {
      updatedTrademarkInfo.erReplyDeadline = "";
    }
    if (value === "Proposed to be used") {
      updatedTrademarkInfo.userDate = "";
    }
    setTrademarkInfo({ ...updatedTrademarkInfo, [field]: value });
    setError({ ...error, [field]: false });
  }

  async function handleSave() {
    let validPayload = validatePayload();
    if (validPayload) {
      let payload = { ...trademarkInfo };
      if (payload.usedSinceDate === "date") {
        payload.usedSinceDate = payload.userDate;
      } else if (payload.usedSinceDate === "Proposed to be used") {
        payload.usedSinceDate = "Proposed to be used";
      }
      if (payload.erIssue === "erReplyFiled") {
        payload.erReplyFiled = payload.erReplyDeadline;
        payload.erReplyDeadline = "";
      } else if (payload.erIssue === "erReplyDeadline") {
        payload.erReplyFiled = "";
        payload.erReplyDeadline = payload.erReplyDeadline;
      }
      if (edit) {
        let clientCreated = await proceedUpdateTrademark(payload);
        if (clientCreated) {
          setToastMessage("Trade mark Updated Successfully");
          setToastType("success");
          setShowToaster(true);
          setTimeout(() => {
            props.onClose(true);
          }, 1000);
        }
      } else {
        let clientCreated = await proceedCreateTrademark(payload);
        if (clientCreated) {
          setToastMessage("Trade mark Created Successfully");
          setToastType("success");
          setShowToaster(true);
          setTimeout(() => {
            props.onClose();
          }, 1000);
        }
      }
    }
  }

  async function proceedCreateTrademark(payload) {
    payload.tradeMarkId = Number(trademarkInfo.tradeMarkId);
    try {
      let response = await postTrademark(payload, payload.companyId);
      return response;
    } catch {}
  }

  async function proceedUpdateTrademark(payload) {
    payload.tradeMarkId = Number(trademarkInfo.tradeMarkId);
    try {
      let response = await putTrademark(
        payload,
        payload.companyId,
        payload.tradeMarkId
      );
      return response;
    } catch {}
  }

  function validatePayload() {
    let valid = true;
    let updatedError = { ...error };
    if (!trademarkInfo.companyId) {
      selectRef.current.focus();
      updatedError.companyId = true;
      valid = false;
    } else if (
      !trademarkInfo.fileNo ||
      (trademarkInfo.fileNo &&
        (trademarkInfo.fileNo.length < 3 || trademarkInfo.fileNo.length > 8))
    ) {
      const field = getFieldElement("fileNo");
      field.focus();
      updatedError.fileNo = true;
      valid = false;
    } else if (!trademarkInfo.tradeMarkId) {
      const field = getFieldElement("tradeMarkId");
      field.focus();
      updatedError.tradeMarkId = true;
      valid = false;
    } else if (!trademarkInfo.name) {
      const field = getFieldElement("name");
      field.focus();
      updatedError.name = true;
      valid = false;
    } else if (!trademarkInfo.status) {
      statusRef.current.focus();
      updatedError.status = true;
      valid = false;
    }
    setError(updatedError);
    return valid;
  }

  function getFieldElement(fieldName) {
    return formRef.current.querySelector(`[name=${fieldName}]`);
  }

  function getCompanies() {
    return props.companies.filter((company) => {
      return ["vtm", "absolute", "active", "va"].includes(company.companyId);
    });
  }

  return (
    <PopUp
      size="md"
      open={true}
      fullWidth
      onClose={() => props.onClose()}
      title={`${edit ? "Update" : "Create"} Trade mark`}
      onSave={handleSave}
      actionLabel={`${edit ? "Update" : "Save"}`}
    >
      <div ref={formRef}>
        <SectionCard entity="trademark" title="File Details" bg={true}>
          <Grid container spacing="1">
            <Grid item xs="6">
              <FormControl required fullWidth variant="outlined">
                <InputLabel>Company</InputLabel>
                <Select
                  disabled={edit || false}
                  error={error.companyId}
                  inputRef={selectRef}
                  label="Company"
                  value={trademarkInfo.companyId}
                  required
                  onChange={(event) =>
                    handleInputChange(event.target.value, "companyId")
                  }
                >
                  {getCompanies().map((company, index) => (
                    <MenuItem
                      key={"compnay-" + index}
                      value={company.companyId}
                    >
                      {company.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs="6">
              <TextField
                className="mb-3 w-full"
                label="File No."
                variant="outlined"
                name="fileNo"
                disabled={edit}
                type="number"
                required
                value={trademarkInfo.fileNo}
                error={error.fileNo}
                helperText={
                  error.fileNo &&
                  "File No. should be between 3 to 8 characters only"
                }
                onChange={(event) =>
                  handleInputChange(event.target.value, "fileNo")
                }
              />
            </Grid>
          </Grid>
        </SectionCard>
        <SectionCard entity="trademark" title="Application Details" bg={true}>
          <Grid container spacing="1">
            <Grid item xs="4">
              <TextField
                className="mb-3 w-full"
                label="Application No"
                variant="outlined"
                required
                name="tradeMarkId"
                type="text"
                value={trademarkInfo.tradeMarkId}
                error={error.tradeMarkId}
                onChange={(e) =>
                  handleInputChange(e.target.value, "tradeMarkId")
                }
              />
            </Grid>
            <Grid item xs="4">
              <UIDatepicker
                required
                label="Application Date"
                onChange={(value) =>
                  handleInputChange(value, "applicationDate")
                }
                value={trademarkInfo.applicationDate}
              />
            </Grid>
            <Grid item xs="4">
              <TextField
                required
                className="mb-3 w-full"
                label="Name/TM"
                variant="outlined"
                type="text"
                name="name"
                value={trademarkInfo.name}
                error={error.name}
                onChange={(event) =>
                  handleInputChange(event.target.value, "name")
                }
              />
            </Grid>
            <Grid item xs="4">
              <FormControl fullWidth variant="outlined">
                <InputLabel>Class</InputLabel>
                <Select
                  error={error.class}
                  label="Class"
                  value={trademarkInfo.class}
                  name="class"
                  onChange={(e) => handleInputChange(e.target.value, "class")}
                >
                  {trademarkClass.map((val, index) => (
                    <MenuItem key={"class-" + index} value={val}>
                      {val}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs="4">
              <TextField
                className="mb-3 w-full"
                label="Applicant Name"
                variant="outlined"
                name="applicantName"
                type="text"
                error={error.applicantName}
                helperText={error.applicantName}
                value={trademarkInfo.applicantName}
                onChange={(event) =>
                  handleInputChange(event.target.value, "applicantName")
                }
              />
            </Grid>
            <Grid item xs="4">
              <TextField
                className="mb-3 w-full"
                label="Applicant Address"
                variant="outlined"
                name="applicantAddress"
                type="text"
                value={trademarkInfo.applicantAddress}
                onChange={(event) =>
                  handleInputChange(event.target.value, "applicantAddress")
                }
              />
            </Grid>
            <Grid item xs="4">
              <FormControl required fullWidth variant="outlined">
                <InputLabel>Status</InputLabel>
                <Select
                  error={error.status}
                  inputRef={statusRef}
                  label="Status"
                  value={trademarkInfo.status}
                  required
                  name="status"
                  onChange={(e) => handleInputChange(e.target.value, "status")}
                >
                  {trademarkStatus.map((status, index) => (
                    <MenuItem key={"status-" + index} value={status}>
                      {status}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs="8">
              <TextField
                className="mb-3 w-full"
                label="Goods/Services"
                variant="outlined"
                name="goods"
                type="text"
                multiline
                rows={3}
                value={trademarkInfo.goods}
                onChange={(event) =>
                  handleInputChange(event.target.value, "goods")
                }
              />
            </Grid>
          </Grid>
        </SectionCard>
        <Grid container spacing="1" className="mb-3">
          <Grid item xs="6">
            <SectionCard entity="trademark" title="User Details" bg={true}>
              <Grid container spacing="1">
                <Grid item xs="6">
                  <FormControl component="fieldset">
                    <RadioGroup
                      value={trademarkInfo.usedSinceDate}
                      onChange={(event) =>
                        handleInputChange(event.target.value, "usedSinceDate")
                      }
                    >
                      <FormControlLabel
                        value="date"
                        control={<Radio />}
                        label="Date"
                      />
                      <FormControlLabel
                        value="Proposed to be used"
                        control={<Radio />}
                        label="Proposed to be used"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                {trademarkInfo.usedSinceDate === "date" && (
                  <Grid item xs="6">
                    <UIDatepicker
                      label="Date"
                      onChange={(date) => handleInputChange(date, "userDate")}
                      value={trademarkInfo.userDate}
                    />
                  </Grid>
                )}
              </Grid>
            </SectionCard>
          </Grid>
          <Grid item xs="6">
            <SectionCard entity="trademark" title="ER Issue Details" bg={true}>
              <Grid container spacing="1">
                <Grid item xs="6">
                  <FormControl component="fieldset">
                    <RadioGroup
                      value={trademarkInfo.erIssue}
                      onChange={(event) =>
                        handleInputChange(event.target.value, "erIssue")
                      }
                    >
                      <FormControlLabel
                        value="erReplyDeadline"
                        control={<Radio />}
                        label="Deadline"
                      />
                      <FormControlLabel
                        value="erReplyFiled"
                        control={<Radio />}
                        label="Filed Date"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>

                <Grid item xs="6">
                  {trademarkInfo.erIssue === "erReplyDeadline" && (
                    <UIDatepicker
                      label="Deadline"
                      value={trademarkInfo.erReplyDeadline}
                      onChange={(date) =>
                        handleInputChange(date, "erReplyDeadline")
                      }
                    />
                  )}
                  {trademarkInfo.erIssue === "erReplyFiled" && (
                    <UIDatepicker
                      label="Filed Date"
                      value={trademarkInfo.erReplyFiled}
                      onChange={(date) =>
                        handleInputChange(date, "erReplyFiled")
                      }
                    />
                  )}
                </Grid>
              </Grid>
            </SectionCard>
          </Grid>
        </Grid>
        <Grid container spacing="1" className="mb-3">
          <Grid item xs="4">
            <SectionCard
              entity="trademark"
              title="Showcause Hearing Date"
              bg={true}
            >
              <UIDatepicker
                label="Date"
                value={trademarkInfo.showCauseHearingDate}
                onChange={(date) =>
                  handleInputChange(date, "showCauseHearingDate")
                }
              />
            </SectionCard>
          </Grid>
          <Grid item xs="8">
            <SectionCard entity="trademark" title="Journal Details" bg={true}>
              <Grid container spacing="1">
                <Grid item xs="6">
                  <TextField
                    className="mb-3 w-full"
                    label="Number"
                    variant="outlined"
                    type="text"
                    value={trademarkInfo.journalNo}
                    onChange={(event) =>
                      handleInputChange(event.target.value, "journalNo")
                    }
                  />
                </Grid>
                <Grid item xs="6">
                  <UIDatepicker
                    label="Date"
                    value={trademarkInfo.journalDate}
                    onChange={(date) => handleInputChange(date, "journalDate")}
                  />
                </Grid>
              </Grid>
            </SectionCard>
          </Grid>
        </Grid>
        <Grid container spacing="1">
          <Grid item xs="4">
            <SectionCard entity="trademark" title="Renewed Upto Date" bg={true}>
              <UIDatepicker
                label="Date"
                value={trademarkInfo.renewedUptoDate}
                onChange={(date) => handleInputChange(date, "renewedUptoDate")}
              />
            </SectionCard>
          </Grid>
          <Grid item xs="4">
            <SectionCard entity="trademark" title="Notes" bg={true}>
              <Grid container spacing="1">
                <Grid item xs="12">
                  <TextField
                    className="mb-3 w-full"
                    label="Notes"
                    variant="outlined"
                    name="notes"
                    type="text"
                    multiline
                    rows={3}
                    value={trademarkInfo.notes}
                    onChange={(event) =>
                      handleInputChange(event.target.value, "notes")
                    }
                  />
                </Grid>
              </Grid>
            </SectionCard>
          </Grid>
          <Grid item xs="4">
            <SectionCard entity="trademark" title="Keywords" bg={true}>
              <Grid container spacing="1">
                <Grid item xs="12">
                  <TextField
                    className="mb-3 w-full"
                    label="Keywords"
                    variant="outlined"
                    name="keywords"
                    type="text"
                    multiline
                    rows={3}
                    value={trademarkInfo.keywords}
                    onChange={(event) =>
                      handleInputChange(event.target.value, "keywords")
                    }
                  />
                </Grid>
              </Grid>
            </SectionCard>
          </Grid>
        </Grid>
      </div>
      <Toaster
        open={showToaster}
        message={toastMessage}
        type={toastType}
        onClose={() => setShowToaster(false)}
      />
    </PopUp>
  );
}

const mapStateToProps = (state) => {
  return {
    companies: state.tableReducer.companies
  };
};

export default connect(mapStateToProps)(CreateTrademark);
