import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField
} from "@material-ui/core";
import { connect } from "react-redux";
import SectionCard from "components/shared/SectionCard";
import PopUp from "components/shared/Popup";
import { getPatentById, postPatent, putPatent } from "api/patentApi";
import UIDatepicker from "components/shared/Datepicker";
import { Delete } from "@material-ui/icons";
import {
  statusOptions,
  applicationTypeOptions,
  renewalYearsOptions,
  ferExtensionOptions,
  specificationOptions
} from "./constants";
import Toaster from "components/shared/Toaster";
import moment from "moment";
import "./createpatent.scss";
import Skeleton from "react-loading-skeleton";

function UIskeleton() {
  return (
    <div>
      <Skeleton duration={2} circle={true} height={20} width={20} />
      <div>
        <Skeleton duration={2} width="20%" />
      </div>
      <div>
        <Skeleton duration={2} width="50%" />
      </div>
      <div>
        <Skeleton duration={2} width="40%" />
      </div>
      <div>
        <Skeleton duration={2} width="60%" />
      </div>
      <div>
        <Skeleton duration={2} width="80%" />
      </div>{" "}
      <div>
        <Skeleton duration={2} width="100%" />
      </div>
      <Skeleton duration={2} />
    </div>
  );
}
function CreatePatent(props) {
  let [toasterInfo, setToasterInfo] = useState({
    show: false,
    message: "",
    type: ""
  });

  let [form18, setForm18] = useState("");
  let [isShowLoader, setIsShowLoader] = useState(false);
  let [form1, setForm1] = useState("");
  let [engTransPr, setEngTransPr] = useState("");
  let [engTransPct, setEngPct] = useState("");
  let [renewal, setRenewal] = useState("");
  let [fer, setFer] = useState("");
  let [specification, setSpecification] = useState("");
  let [patentInfo, setPatentInfo] = useState({
    companyId: "",
    applicantName: "",
    applicationDate: "",
    applicationType: "",
    attorneyEmail: "",
    attorneyName: "",
    ferDeadline: "",
    ferExtension: "",
    ferFiled: "",
    fileNo: "",
    form1Deadline: "",
    form1Filed: "",
    form3Deadline: "",
    form3FiledDates: [],
    form3RemainderEmails: [],
    form18Deadline: "",
    form18Filed: "",
    form18NotToBeFiled: "",
    form18RemainderEmails: [],
    form27Deadline: "",
    form27FiledDates: [],
    form27NotToBeFiled: "",
    hearingDate: "",
    notes: "",
    patentId: "",
    patentNo: "",
    pctDocumentDeadline: "",
    pctDocumentFiled: "",
    priority: [],
    priorityDocumentDeadline: "",
    priorityDocumentFiled: "",
    referenceNo: "",
    renewalDeadline: "",
    grantedOnDate: "",
    renewalFiled: "",
    renewalFiledDates: [],
    renewalFiledYears: [],
    renewalNotToBeFiled: "",
    renewalYears: 0,
    status: "",
    title: "",
    divisional: [
      {
        applicationNo: "",
        applicationDate: ""
      }
    ],
    specificationType: "",
    specificationDeadline: null,
    specificationFiled: null
  });

  let { edit } = props;
  let { status } = patentInfo;
  useEffect(init, []);

  function init() {
    if (edit) {
      setIsShowLoader(true);
      getPatentInfo();
    }
  }

  async function getPatentInfo() {
    let patentDetails = await fetchEditedPatentInfo();
    if (patentDetails) {
      setPatentInfo({ ...patentInfo, ...patentDetails });
      updateForm18Info(patentDetails);
      updateForm1Info(patentDetails);
      updateengTransPrInfo(patentDetails);
      updateengTransPctInfo(patentDetails);
      updateRenewalInfo(patentDetails);
      updateFerInfo(patentDetails);
      updateSpecificationInfo(patentDetails);
      setIsShowLoader(false);
    }
  }

  function updateForm18Info(patentDetails) {
    if (patentDetails.form18Filed) {
      setForm18("form18Filed");
    } else if (patentDetails.form18Deadline) {
      setForm18("form18Deadline");
    } else if (patentDetails.form18NotToBeFiled) {
      setForm18("form18NotToBeFiled");
    } else if (
      patentDetails.form18Filed &&
      patentDetails.form18Filed === patentDetails.applicationDate
    ) {
      setForm18("Copy Application Date");
    }
  }

  function updateSpecificationInfo(patentDetails) {
    if (patentDetails.specificationFiled) {
      setSpecification("specificationFiled");
    } else if (patentDetails.specificationDeadline) {
      setSpecification("specificationDeadline");
    }
  }

  function updateForm1Info(patentDetails) {
    if (patentDetails.form1Filed) {
      setForm1("form1Filed");
    } else if (patentDetails.form1Deadline) {
      setForm1("form1Deadline");
    }
  }

  function updateengTransPrInfo(patentDetails) {
    if (patentDetails.priorityDocumentDeadline) {
      setEngTransPr("priorityDocumentDeadline");
    } else if (patentDetails.priorityDocumentFiled) {
      setEngTransPr("priorityDocumentFiled");
    }
  }

  function updateengTransPctInfo(patentDetails) {
    if (patentDetails.pctDocumentDeadline) {
      setEngPct("pctDocumentDeadline");
    } else if (patentDetails.pctDocumentFiled) {
      setEngPct("pctDocumentFiled");
    }
  }
  function updateRenewalInfo(patentDetails) {
    if (patentDetails.renewalDeadline) {
      setRenewal("renewalDeadline");
    } else if (patentDetails.renewalFiled) {
      setRenewal("renewalFiled");
    } else if (patentDetails.renewalNotToBeFiled) {
      setRenewal("renewalNotToBeFiled");
    }
  }
  function updateFerInfo(patentDetails) {
    if (patentDetails.ferDeadline) {
      setFer("ferDeadline");
    } else if (patentDetails.ferFiled) {
      setFer("ferFiled");
    }
  }

  async function fetchEditedPatentInfo() {
    try {
      let response = await getPatentById(props.patentId, props.companyId);
      if (response && response.data) {
        return response.data;
      }
    } catch {}
  }

  function getDate(value, diff) {
    return moment(value, "DD/MM/YYYY").add(diff, "month").format("DD/MM/YYYY");
  }

  function getForm27Deadline() {
    let currentMonth = new Date().getMonth();
    let currentYear = new Date().getFullYear();
    let deadline = "";
    if (currentMonth > 8) {
      deadline = `30/09/${currentYear + 1}`;
    } else {
      deadline = `30/09/${currentYear}`;
    }
    return deadline;
  }

  function handleInputChange(value, field) {
    let updatedPatentInfo = { ...patentInfo };

    if (
      field === "applicationType" &&
      value === "Divisional" &&
      !patentInfo.form18Filed &&
      !patentInfo.form18NotToBeFiled &&
      patentInfo.applicationDate
    ) {
      updatedPatentInfo.form18Deadline = getDate(patentInfo.applicationDate, 6);
      setForm18("form18Deadline");
    }
    if (
      field === "applicationType" &&
      value === "Ordinary" &&
      !patentInfo.form18Filed &&
      !patentInfo.form18NotToBeFiled &&
      patentInfo.applicationDate
    ) {
      updatedPatentInfo.form18Deadline = getDate(
        patentInfo.applicationDate,
        48
      );
      setForm18("form18Deadline");
    }
    if (
      (field === "applicationType" &&
        ["Divisional", "Ordinary"].includes(value)) ||
      (field === "status" && value !== "Pending")
    ) {
      updatedPatentInfo.pctDocumentDeadline = "";
      updatedPatentInfo.pctDocumentFiled = "";
      updatedPatentInfo.priorityDocumentDeadline = "";
      updatedPatentInfo.priorityDocumentFiled = "";
      setEngPct("");
      setEngTransPr("");
    }
    if (
      field === "applicationDate" &&
      patentInfo.specificationType === "Provisional" &&
      specification !== "specificationFiled"
    ) {
      setSpecification("specificationDeadline");
      updatedPatentInfo.specificationDeadline = getDate(value, 12);
    }
    // if (
    //   field === "applicationType" &&
    //   ["Convention", "National Phase"].includes(value) &&
    //   patentInfo.priority.length === 0
    // ) {
    //   updatedPatentInfo.priority.push({
    //     no: patetnInfo.patentId,
    //     date: patetnInfo.applicationDate,
    //     country: "India"
    //   });
    // }
    if (
      field === "applicationType" &&
      // ["Ordinary", "Divisional"].includes(value) &&
      patentInfo.priority.length === 0
    ) {
      updatedPatentInfo.priority.push({
        no: updatedPatentInfo.patentId,
        date: updatedPatentInfo.applicationDate,
        country: "India"
      });
    }
    if (
      field === "applicationDate" &&
      // ["Ordinary", "Divisional"].includes(patentInfo.applicationType) &&
      patentInfo.priority.length > 0
    ) {
      patentInfo.priority[0].date = value;
    }
    setPatentInfo({ ...updatedPatentInfo, [field]: value });
  }

  async function handleSave() {
    let validPayload = validatePayload();
    if (validPayload && edit) {
      validPayload = validateUpdatePayload();
    }
    let updatedPayload = getUpdatedPayload();
    if (validPayload) {
      if (edit) {
        let updatedSuccess = await proceedPatentUpdate(updatedPayload);
        if (updatedSuccess) {
          gotoList();
        }
      } else {
        let createdSuccess = await proceedPatentCreate(updatedPayload);
        if (createdSuccess) {
          gotoList();
        }
      }
    }
  }

  function getUpdatedPayload() {
    let updatedPayload = { ...patentInfo };
    if (updatedPayload.status === "Granted" && updatedPayload.grantedOnDate) {
      updatedPayload.renewalDeadline = getDate(updatedPayload.grantedOnDate, 3);
    }
    if (
      updatedPayload.applicationDate &&
      updatedPayload.renewalFiledYears.length > 0 &&
      updatedPayload.applicationType !== "National Phase"
    ) {
      let years = updatedPayload.renewalFiledYears.slice(-1)[0];
      updatedPayload.renewalDeadline = getRenewalDeadlineFromFiledYear(
        updatedPayload.applicationDate,
        Number(years)
      );
    } else if (
      updatedPayload.applicationType === "National Phase" &&
      updatedPayload.internationalFilingDate &&
      updatedPayload.renewalFiledYears.length > 0
    ) {
      let years = updatedPayload.renewalFiledYears.slice(-1)[0];
      updatedPayload.renewalDeadline = getRenewalDeadlineFromFiledYear(
        updatedPayload.internationalFilingDate,
        Number(years)
      );
    }
    if (updatedPayload.status === "Granted" && !updatedPayload.form27Deadline) {
      updatedPayload.form27Deadline = getForm27Deadline();
    }
    return updatedPayload;
  }

  function validateUpdatePayload() {
    let { status, applicationDate, title, patentNo } = patentInfo;
    if (!status) {
      setToasterInfo({
        show: true,
        type: "error",
        message: "Select Status"
      });
      return false;
    } else if (!applicationDate) {
      setToasterInfo({
        show: true,
        type: "error",
        message: "Enter Application Date"
      });
      return false;
    } else if (!title) {
      setToasterInfo({
        show: true,
        type: "error",
        message: "Enter Title"
      });
      return false;
    } else if (!patentNo && (status === "Granted" || status === "Abandoned")) {
      setToasterInfo({
        show: true,
        type: "error",
        message: "Enter Patent Number"
      });
      return false;
    } else if (
      patentInfo.applicationType === "Divisional" &&
      patentInfo.status === "Pending"
    ) {
      if (patentInfo.form18Filed) {
        let difference = getDifferenceInMonth(
          patentInfo.applicationDate,
          patentInfo.form18Filed
        );
        if (difference !== 6) {
          setToasterInfo({
            show: true,
            type: "error",
            message:
              "Form18 filed date should be 6 months greater that application date"
          });
          return false;
        }
      }
    }
    if (["Pending", "FER Replied", "Examination"].includes(status)) {
      if (!form18) {
        setToasterInfo({
          show: true,
          type: "error",
          message: "Enter Form 18 details"
        });
        return false;
      } else if (!form1) {
        setToasterInfo({
          show: true,
          type: "error",
          message: "Enter Form 1 details"
        });
        return false;
      } else if (status !== "Pending" && !fer) {
        setToasterInfo({
          show: true,
          type: "error",
          message: "Enter FER details"
        });
      }
    } else if (["Granted", "Abandoned", "Published"].includes(status)) {
      if (!form18) {
        setToasterInfo({
          show: true,
          type: "error",
          message: "Enter Form 18 details"
        });
        return false;
      } else if (!renewal && status === "Granted") {
        setToasterInfo({
          show: true,
          type: "error",
          message: "Enter Renewal details"
        });
        return false;
      }
    }
    return true;
  }

  async function proceedPatentUpdate(payload) {
    try {
      let response = await putPatent(
        payload,
        patentInfo.companyId,
        patentInfo.patentId
      );
      if (response) {
        setToasterInfo({
          show: true,
          message: "Patent Updated Success",
          type: "success"
        });
        return response;
      }
    } catch {
      setToasterInfo({
        show: true,
        message: "Something went wrong",
        type: "error"
      });
    }
  }

  function gotoList() {
    setTimeout(() => {
      props.onClose(true);
    }, 1000);
  }

  async function proceedPatentCreate(payload) {
    try {
      let response = await postPatent(payload, patentInfo.companyId);
      if (response) {
        setToasterInfo({
          show: true,
          message: "Patent Created Success",
          type: "success"
        });
        return response;
      }
    } catch {
      setToasterInfo({
        show: true,
        message: "Something went wrong",
        type: "error"
      });
    }
  }

  function validatePayload() {
    let { companyId, fileNo, patentId, applicationDate } = patentInfo;
    if (!companyId) {
      setToasterInfo({
        show: true,
        type: "error",
        message: "Select Company"
      });
      return false;
    } else if (!fileNo) {
      setToasterInfo({
        show: true,
        type: "error",
        message: "Enter File Number"
      });
      return false;
    } else if (!patentId) {
      setToasterInfo({
        show: true,
        type: "error",
        message: "Enter Application Number"
      });
      return false;
    } else if (!applicationDate) {
      setToasterInfo({
        show: true,
        type: "error",
        message: "Enter Application Date"
      });
      return false;
    } else if (
      patentInfo.specificationType === "Complete" &&
      !patentInfo.specificationFiled
    ) {
      setToasterInfo({
        show: true,
        type: "error",
        message:
          "Specification Filed date is mandatory if specification type is Complete"
      });
      return false;
    } else if (
      patentInfo.status === "Pending" &&
      ["Ordinary", "Convention", "National Phase"].includes(
        patentInfo.applicationType
      )
    ) {
      if (patentInfo.form18Filed) {
        let difference = getDifferenceInMonth(
          patentInfo.applicationDate,
          patentInfo.form18Filed
        );
        if (difference !== 48) {
          setToasterInfo({
            show: true,
            type: "error",
            message:
              "Form18 filed date should be 48 months greater that application date"
          });
          return false;
        }
      }
    }
    return true;
  }

  function getDifferenceInMonth(fromDate, toDate) {
    return moment(toDate, "DD/MM/YYYY").diff(
      moment(fromDate, "DD/MM/YYYY"),
      "months"
    );
  }

  function handleRemainderEmailAdd() {
    let updated = { ...patentInfo };
    updated.form18RemainderEmails.push("");
    setPatentInfo(updated);
  }

  function handleRemainderEmailDelete(index) {
    let updated = { ...patentInfo };
    updated.form18RemainderEmails.splice(index, 1);
    setPatentInfo(updated);
  }

  function handleAddPriority() {
    let updated = { ...patentInfo };
    updated.priority.push({
      no: "",
      date: null,
      country: ""
    });
    setPatentInfo(updated);
  }

  function handlePriorityRemove(index) {
    let updated = { ...patentInfo };
    updated.priority.splice(index, 1);
    setPatentInfo(updated);
  }

  function handlePriorityInfoChange(value, field, index) {
    let updated = { ...patentInfo };
    updated.priority[index][field] = value;
    setPatentInfo(updated);
  }

  function handleForm18Remainders(date, index) {
    let updated = { ...patentInfo };
    updated.form18RemainderEmails[index] = date;
    setPatentInfo(updated);
  }

  function handleForm3RemainderAdd() {
    let updated = { ...patentInfo };
    updated.form3RemainderEmails.push("");
    setPatentInfo(updated);
  }

  function handleForm3RemainderChange(date, index) {
    let updated = { ...patentInfo };
    updated.form3RemainderEmails[index] = date;
    setPatentInfo(updated);
  }

  function handleForm3RemainderDelete(index) {
    let updated = { ...patentInfo };
    updated.form3RemainderEmails.splice(index, 1);
    setPatentInfo(updated);
  }

  function handleForm3filedDateAdd() {
    let updated = { ...patentInfo };
    updated.form3FiledDates.push("");
    setPatentInfo(updated);
    // form3Info.filedDates.push({ date: null });
    // setForm3Info(updated);
  }

  function handleForm3FiledDateChange(date, index) {
    let updated = { ...patentInfo };
    updated.form3FiledDates[index] = date;
    setPatentInfo(updated);
  }

  function handleForm3FiledDateDelete(index) {
    let updated = { ...patentInfo };
    updated.form3FiledDates.splice(index, 1);
    setPatentInfo(updated);
  }

  function handleForm27DateAdd() {
    let updated = { ...patentInfo };
    updated.form27FiledDates.push("");
    setPatentInfo(updated);
    // updated.filedDates.push({ date: null });
    // setForm27Info(updated);
  }

  function handleForm27DateRemove(index) {
    let updated = { ...patentInfo };
    updated.form27FiledDates.splice(index, 1);
    setPatentInfo(updated);
  }

  function handleForm27FiledDateChange(date, index) {
    let updated = { ...patentInfo };
    updated.form27FiledDates[index] = date;
    setPatentInfo(updated);
  }

  function handleForm18InfoChange(value) {
    setForm18(value);
    let updatedPatentInfo = { ...patentInfo };
    if (value === "form18Filed") {
      updatedPatentInfo.form18Deadline = "";
      updatedPatentInfo.form18NotToBeFiled = "";
    } else if (value === "form18Deadline") {
      updatedPatentInfo.form18Filed = "";
      updatedPatentInfo.form18NotToBeFiled = "";
    } else if (value === "form18NotToBeFiled") {
      updatedPatentInfo.form18Deadline = "";
      updatedPatentInfo.form18Filed = "";
      updatedPatentInfo.form18NotToBeFiled = true;
    }

    if (value === "Copy Application Date") {
      updatedPatentInfo.form18Filed = patentInfo.applicationDate;
    }
    setPatentInfo({ ...updatedPatentInfo });
  }

  function handleForm1Change(value) {
    let updatedPatentInfo = { ...patentInfo };
    if (value === "form1Filed") {
      updatedPatentInfo.form1Deadline = "";
    } else if (value === "form1Deadline") {
      updatedPatentInfo.form1Filed = "";
    }
    setPatentInfo(updatedPatentInfo);
    setForm1(value);
  }

  function handleTransPctChange(value) {
    let updatedPatentInfo = { ...patentInfo };
    if (value === "pctDocumentDeadline") {
      updatedPatentInfo.pctDocumentFiled = "";
    } else if (value === "pctDocumentFiled") {
      updatedPatentInfo.pctDocumentDeadline = "";
    }
    setPatentInfo({ ...updatedPatentInfo });
    setEngPct(value);
  }

  function handleTransPrChange(value) {
    let updatedPatentInfo = { ...patentInfo };
    if (value === "priorityDocumentDeadline") {
      updatedPatentInfo.priorityDocumentFiled = "";
    } else if (value === "priorityDocumentFiled") {
      updatedPatentInfo.priorityDocumentDeadline = "";
    }
    setPatentInfo({ ...updatedPatentInfo });
    setEngTransPr(value);
  }

  function handleRenewalDetailsChange(value) {
    let updatedPatentInfo = { ...patentInfo };
    if (value === "renewalDeadline") {
      updatedPatentInfo.renewalNotToBeFiled = "";
    } else if (value === "renewalNotToBeFiled") {
      updatedPatentInfo.renewalDeadline = "";
      updatedPatentInfo.renewalFiledYears = [];
      updatedPatentInfo.renewalNotToBeFiled = true;
    }
    setPatentInfo({ ...updatedPatentInfo });
    setRenewal(value);
  }

  function handleFerChange(value) {
    let updatedPatentInfo = { ...patentInfo };
    if (value === "ferFiled") {
      updatedPatentInfo.ferDeadline = "";
      updatedPatentInfo.ferExtension = "";
    } else if (value === "ferDeadline") {
      updatedPatentInfo.ferFiled = "";
    }
    setPatentInfo({ ...updatedPatentInfo });
    setFer(value);
  }

  function handleSpecificationChange(value) {
    setSpecification(value);
  }

  function handleAddDivisional() {
    let updated = { ...patentInfo };
    updated.divisional.push({
      applicationNo: "",
      applicationDate: null
    });
    setPatentInfo(updated);
  }

  function handleDivisionalRemove(index) {
    let updated = { ...patentInfo };
    updated.divisional.splice(index, 1);
    setPatentInfo(updated);
  }

  function handleDivisionalnfoChange(value, field, index) {
    let updated = { ...patentInfo };
    updated.divisional[index][field] = value;
    setPatentInfo(updated);
  }

  function handleAppNoChange(value) {
    let updated = { ...patentInfo };
    if (
      // ["Ordinary", "Divisional"].includes(patentInfo.applicationType) &&
      patentInfo.priority.length > 0
    ) {
      updated.priority[0].no = value;
    }
    setPatentInfo((prevInfo) => ({ ...prevInfo, ...updated }));
  }

  function getApplicationTypeOptions() {
    if (edit) {
      return applicationTypeOptions;
    } else {
      return applicationTypeOptions.filter(
        (type) => type.value !== "Divisional"
      );
    }
  }

  function getForm27DeadlineMaxDate() {
    let currentYear = new Date().getFullYear();
    return new Date(`${currentYear + 20}-01-01`);
  }

  function handleRenewalFiledYears() {
    let updated = { ...patentInfo };
    if (updated.renewalFiledYears.length === 0) {
      updated.renewalFiledYears.push("");
    } else {
      let lastFiledYear =
        updated.renewalFiledYears[updated.renewalFiledYears.length - 1];
      if (!lastFiledYear) {
        setToasterInfo({
          show: true,
          type: "error",
          message: "Select enter filed year for already added field"
        });
        return;
      } else {
        updated.renewalFiledYears.push("");
      }
    }
    setPatentInfo({ ...updated });
  }

  function handleRenewalFiledYearChange(value, index) {
    let updated = { ...patentInfo };
    updated.renewalFiledYears[index] = value;
    setPatentInfo({ ...updated });
  }

  function getRenewalDeadlineFromFiledYear(date, diff) {
    return moment(date, "DD/MM/YYYY")
      .add(diff, "years")
      .subtract(6, "months")
      .format("DD/MM/YYYY");
  }

  function getCompanies() {
    return props.companies.filter((company) => {
      return ["vtm", "absolute", "active", "va"].includes(company.companyId);
    });
  }

  return (
    <PopUp
      size="md"
      open={true}
      fullWidth
      fullScreen
      onClose={() => props.onClose()}
      title={`${edit ? "Update" : "Create"} Patent`}
      onSave={handleSave}
      actionLabel={`${edit ? "Update" : "Save"}`}
    >
      {isShowLoader && (
        <Grid container spacing="2" justify="space-between">
          <Grid item xs="12">
            <SectionCard> {UIskeleton()}</SectionCard>
          </Grid>
        </Grid>
      )}
      {!isShowLoader && (
        <Grid container spacing="2" justify="space-between">
          <Grid item xs="6">
            <Grid item xs="12" className="sectionContainer">
              <SectionCard
                entity="patent"
                bg={true}
                fullwidth
                title="File Details"
              >
                <Grid container spacing="2">
                  <Grid item xs="4">
                    <FormControl required fullWidth variant="outlined">
                      <InputLabel>Company</InputLabel>
                      <Select
                        required
                        label="Company"
                        onChange={(event) =>
                          handleInputChange(event.target.value, "companyId")
                        }
                        value={patentInfo.companyId}
                      >
                        {getCompanies().map((company, index) => (
                          <MenuItem
                            key={"compnay-" + index}
                            value={company.companyId}
                          >
                            {company.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs="4">
                    <TextField
                      label="File No."
                      variant="outlined"
                      name="fileNo"
                      required
                      fullWidth
                      onChange={(event) =>
                        handleInputChange(event.target.value, "fileNo")
                      }
                      value={patentInfo.fileNo}
                    />
                  </Grid>
                  <Grid item xs="4">
                    <TextField
                      label="Reference No."
                      variant="outlined"
                      name="referenceNo"
                      fullWidth
                      onChange={(event) =>
                        handleInputChange(event.target.value, "referenceNo")
                      }
                      value={patentInfo.referenceNo}
                    />
                  </Grid>
                </Grid>
              </SectionCard>
            </Grid>
            <Grid itex xs="12" className="sectionContainer">
              <SectionCard
                entity="patent"
                bg={true}
                title="Application Details"
                fullwidth
              >
                <Grid container spacing="2">
                  <Grid item xs="4">
                    <TextField
                      label="Application No."
                      variant="outlined"
                      name="patentId"
                      required
                      fullWidth
                      onChange={(event) =>
                        handleInputChange(event.target.value, "patentId")
                      }
                      onBlur={(event) => handleAppNoChange(event.target.value)}
                      value={patentInfo.patentId}
                    />
                  </Grid>
                  <Grid item xs="4">
                    <FormControl fullWidth variant="outlined">
                      <InputLabel>Application Type</InputLabel>
                      <Select
                        label="Application Type"
                        onChange={(event) =>
                          handleInputChange(
                            event.target.value,
                            "applicationType"
                          )
                        }
                        value={patentInfo.applicationType}
                      >
                        {getApplicationTypeOptions().map((type, index) => (
                          <MenuItem key={"compnay-" + index} value={type.value}>
                            {type.text}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  {patentInfo.applicationType === "Ordinary" && (
                    <Grid item xs="4">
                      <FormControl fullWidth variant="outlined">
                        <InputLabel>Specification Type</InputLabel>
                        <Select
                          label="Specification Type"
                          onChange={(event) =>
                            handleInputChange(
                              event.target.value,
                              "specificationType"
                            )
                          }
                          value={patentInfo.specificationType}
                        >
                          {specificationOptions.map((type, index) => (
                            <MenuItem
                              key={"compnay-" + index}
                              value={type.value}
                            >
                              {type.text}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                  <Grid item xs="4">
                    <FormControl fullWidth variant="outlined" required={edit}>
                      <InputLabel>Status</InputLabel>
                      <Select
                        label="Status"
                        onChange={(event) =>
                          handleInputChange(event.target.value, "status")
                        }
                        value={patentInfo.status}
                      >
                        {statusOptions.map((status, index) => (
                          <MenuItem
                            key={"compnay-" + index}
                            value={status.value}
                          >
                            {status.text}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs="4">
                    <UIDatepicker
                      label="Application Date"
                      required
                      disableFuture
                      onChange={(value) =>
                        handleInputChange(value, "applicationDate")
                      }
                      value={patentInfo.applicationDate}
                    />
                  </Grid>
                  <Grid item xs="4">
                    <TextField
                      label="Applicant Name"
                      variant="outlined"
                      required={edit}
                      fullWidth
                      onChange={(event) =>
                        handleInputChange(event.target.value, "applicantName")
                      }
                      value={patentInfo.applicantName}
                    />
                  </Grid>
                  {(status === "Granted" || status === "Abandoned") && (
                    <Grid item xs="4">
                      <TextField
                        label="Patent No"
                        variant="outlined"
                        required={edit}
                        fullWidth
                        onChange={(event) =>
                          handleInputChange(event.target.value, "patentNo")
                        }
                        value={patentInfo.patentNo}
                      />
                    </Grid>
                  )}
                  {status === "Granted" &&
                    patentInfo.applicationType === "National Phase" && (
                      <Grid item xs="6">
                        <UIDatepicker
                          label="International filing date"
                          value={patentInfo.internationalFilingDate}
                          onChange={(date) =>
                            handleInputChange(date, "internationalFilingDate")
                          }
                        />
                      </Grid>
                    )}
                  {status === "Granted" && (
                    <Grid item xs={6}>
                      <UIDatepicker
                        label="Granted On"
                        value={patentInfo.grantedOnDate}
                        onChange={(date) =>
                          handleInputChange(date, "grantedOnDate")
                        }
                      />
                    </Grid>
                  )}
                  <Grid item xs="12">
                    <TextField
                      label="Title"
                      variant="outlined"
                      required={edit}
                      multiline
                      rows="4"
                      fullWidth
                      onChange={(event) =>
                        handleInputChange(event.target.value, "title")
                      }
                      value={patentInfo.title}
                    />
                  </Grid>
                  {patentInfo.applicationType && patentInfo.applicationType && (
                    <Grid item xs="12">
                      {patentInfo.priority.map((priority, index) => {
                        return (
                          <div
                            style={{ display: "flex", margin: "8px 0px" }}
                            key={index}
                          >
                            <UIDatepicker
                              label="Priority Date"
                              value={priority.date}
                              onChange={(date) =>
                                handlePriorityInfoChange(date, "date", index)
                              }
                            />
                            <TextField
                              label="Priority No"
                              variant="outlined"
                              fullWidth
                              style={{ marginLeft: "8px" }}
                              onChange={(event) =>
                                handlePriorityInfoChange(
                                  event.target.value,
                                  "no",
                                  index
                                )
                              }
                              value={priority.no}
                            />
                            <TextField
                              label="Priority Country"
                              variant="outlined"
                              fullWidth
                              style={{ marginLeft: "8px" }}
                              onChange={(event) =>
                                handlePriorityInfoChange(
                                  event.target.value,
                                  "country",
                                  index
                                )
                              }
                              value={priority.country}
                            />
                            {patentInfo.applicationType !== "Divisional" &&
                            index === 0 ? null : (
                              <IconButton
                                onClick={() => handlePriorityRemove(index)}
                              >
                                <Delete />
                              </IconButton>
                            )}
                          </div>
                        );
                      })}

                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={handleAddPriority}
                      >
                        Add Priority
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </SectionCard>
            </Grid>
            {patentInfo.applicationType === "Divisional" && (
              <Grid item xs="12" className="sectionContainer">
                <SectionCard
                  entity="patent"
                  bg={true}
                  title="Divisional Details"
                  fullwidth
                >
                  <Grid item xs="12">
                    {patentInfo.divisional.map((details, index) => {
                      return (
                        <div
                          style={{ display: "flex", margin: "8px 0px" }}
                          key={index}
                        >
                          <UIDatepicker
                            value={details.applicationDate}
                            label="Divisional Application Date"
                            onChange={(date) =>
                              handleDivisionalnfoChange(
                                date,
                                "applicationDate",
                                index
                              )
                            }
                          />
                          <TextField
                            label="Divisional application Number"
                            variant="outlined"
                            fullWidth
                            style={{ marginLeft: "8px" }}
                            onChange={(event) =>
                              handleDivisionalnfoChange(
                                event.target.value,
                                "applicationNo",
                                index
                              )
                            }
                            value={details.applicationNo}
                          />
                          <IconButton
                            onClick={() => handleDivisionalRemove(index)}
                          >
                            <Delete />
                          </IconButton>
                        </div>
                      );
                    })}
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={handleAddDivisional}
                    >
                      Add More
                    </Button>
                  </Grid>
                </SectionCard>
              </Grid>
            )}
            {[
              "FER Replied",
              "Granted",
              "Pending",
              "Examination",
              "Hearing"
            ].includes(status) && (
              <Grid item xs="12" className="sectionContainer">
                <SectionCard
                  entity="patent"
                  bg={true}
                  title="Form 18 Details"
                  isExpansionPanel={[
                    "FER Replied",
                    "Granted",
                    "Examination",
                    "Hearing"
                  ].includes(status)}
                  fullwidth
                >
                  <Grid container spacing="2">
                    <Grid item xs="12">
                      <RadioGroup
                        style={{ flexDirection: "row" }}
                        value={form18}
                        onChange={(event) =>
                          handleForm18InfoChange(event.target.value)
                        }
                      >
                        <FormControlLabel
                          label="Deadline"
                          value="form18Deadline"
                          control={<Radio />}
                        />
                        <FormControlLabel
                          label="Filed Date"
                          value="form18Filed"
                          control={<Radio />}
                        />
                        <FormControlLabel
                          label="Copy Application Date"
                          value="Copy Application Date"
                          control={<Radio />}
                        />
                        <FormControlLabel
                          label="Not to be filed"
                          value="form18NotToBeFiled"
                          control={<Radio />}
                        />
                      </RadioGroup>
                      {[
                        "form18Deadline",
                        "form18Filed",
                        "Copy Application Date"
                      ].includes(form18) && (
                        <UIDatepicker
                          value={
                            patentInfo[form18] ||
                            (form18 === "Copy Application Date" &&
                              patentInfo.form18Filed)
                          }
                          onChange={(date) => handleInputChange(date, form18)}
                        />
                      )}
                    </Grid>
                    <Grid item xs="7">
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={handleRemainderEmailAdd}
                      >
                        Add Remainder Email
                      </Button>
                      {patentInfo.form18RemainderEmails &&
                        patentInfo.form18RemainderEmails.length > 0 && (
                          <Grid container spacing="2">
                            {patentInfo.form18RemainderEmails.map(
                              (date, index) => {
                                return (
                                  <Grid item xs="12" key={date}>
                                    <div
                                      style={{
                                        display: "flex",
                                        marginTop: "8px"
                                      }}
                                    >
                                      <UIDatepicker
                                        label="Form 18 Remainder Email"
                                        value={
                                          patentInfo.form18RemainderEmails[
                                            index
                                          ]
                                        }
                                        onChange={(date) => {
                                          handleForm18Remainders(date, index);
                                        }}
                                      />
                                      <IconButton
                                        onClick={() =>
                                          handleRemainderEmailDelete(index)
                                        }
                                      >
                                        <Delete />
                                      </IconButton>
                                    </div>
                                  </Grid>
                                );
                              }
                            )}
                          </Grid>
                        )}
                    </Grid>
                  </Grid>
                </SectionCard>
              </Grid>
            )}
            {[
              "FER Replied",
              "Granted",
              "Pending",
              "Examination",
              "Hearing"
            ].includes(status) && (
              <Grid item xs="12" className="sectionContainer">
                <SectionCard
                  entity="patent"
                  bg={true}
                  title="Form 3 Details"
                  isExpansionPanel={[
                    "FER Replied",
                    "Granted",
                    "Examination",
                    "Hearing"
                  ].includes(status)}
                >
                  <Grid container spacing="2">
                    <Grid item xs="4">
                      <UIDatepicker
                        label="Deadline"
                        value={patentInfo.form3Deadline}
                        onChange={(date) => {
                          handleInputChange(date, "form3Deadline");
                        }}
                      />
                    </Grid>
                    <Grid item xs="12">
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={handleForm3filedDateAdd}
                      >
                        Add Filed Date
                      </Button>
                      {patentInfo.form3FiledDates &&
                        patentInfo.form3FiledDates.length > 0 && (
                          <Grid container spacing="2">
                            {patentInfo.form3FiledDates.map((date, index) => {
                              return (
                                <Grid item xs="6" key={date + index}>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "8px"
                                    }}
                                  >
                                    <UIDatepicker
                                      label="Form 3 Filed date"
                                      value={date}
                                      onChange={(date) =>
                                        handleForm3FiledDateChange(date, index)
                                      }
                                    />
                                    <IconButton
                                      onClick={() =>
                                        handleForm3FiledDateDelete(index)
                                      }
                                    >
                                      <Delete />
                                    </IconButton>
                                  </div>
                                </Grid>
                              );
                            })}
                          </Grid>
                        )}
                    </Grid>
                    <Grid item xs="12">
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={handleForm3RemainderAdd}
                      >
                        Add Remainder Email
                      </Button>
                      {patentInfo.form3RemainderEmails &&
                        patentInfo.form3RemainderEmails.length > 0 && (
                          <Grid container spacing="2">
                            {patentInfo.form3RemainderEmails.map(
                              (date, index) => {
                                return (
                                  <Grid item xs="6" key={date + index}>
                                    <div
                                      style={{
                                        display: "flex",
                                        marginTop: "8px"
                                      }}
                                    >
                                      <UIDatepicker
                                        label="Form 3 remainder email"
                                        value={date}
                                        onChange={(date) => {
                                          handleForm3RemainderChange(
                                            date,
                                            index
                                          );
                                        }}
                                      />
                                      <IconButton
                                        onClick={() =>
                                          handleForm3RemainderDelete(index)
                                        }
                                      >
                                        <Delete />
                                      </IconButton>
                                    </div>
                                  </Grid>
                                );
                              }
                            )}
                          </Grid>
                        )}
                    </Grid>
                  </Grid>
                </SectionCard>
              </Grid>
            )}
            {[
              "FER Replied",
              "Granted",
              "Pending",
              "Examination",
              "Hearing"
            ].includes(status) && (
              <Grid item xs="12" className="sectionContainer">
                <SectionCard
                  entity="patent"
                  bg={true}
                  title="Form 1 Details"
                  isExpansionPanel={[
                    "FER Replied",
                    "Granted",
                    "Examination",
                    "Hearing"
                  ].includes(status)}
                >
                  <Grid container spacing="2">
                    <Grid item xs="5">
                      <RadioGroup
                        value={form1}
                        onChange={(event) =>
                          handleForm1Change(event.target.value)
                        }
                        style={{ flexDirection: "row" }}
                      >
                        <FormControlLabel
                          label="Deadline"
                          value="form1Deadline"
                          control={<Radio />}
                        />
                        <FormControlLabel
                          label="Filed Date"
                          value="form1Filed"
                          control={<Radio />}
                        />
                      </RadioGroup>
                    </Grid>
                    <Grid item xs="7">
                      {form1 && (
                        <UIDatepicker
                          label={
                            form1 === "form1Deadline"
                              ? "Form 1 Deadline"
                              : "Form 1 filed"
                          }
                          value={patentInfo[form1]}
                          onChange={(date) => {
                            handleInputChange(date, form1);
                          }}
                        />
                      )}
                    </Grid>
                  </Grid>
                </SectionCard>
              </Grid>
            )}
          </Grid>
          <Grid item xs="6">
            <Grid item xs="12" className="sectionContainer">
              <SectionCard entity="patent" bg={true} title="Attorney Details">
                <Grid container spacing="2">
                  <Grid item xs="6">
                    <TextField
                      label="Attorney Name"
                      variant="outlined"
                      style={{ marginBottom: "8px" }}
                      value={patentInfo.attorneyName}
                      onChange={(event) => {
                        handleInputChange(event.target.value, "attorneyName");
                      }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs="6">
                    <TextField
                      label="Attorney Email"
                      variant="outlined"
                      fullWidth
                      style={{ marginBottom: "8px" }}
                      value={patentInfo.attorneyEmail}
                      onChange={(event) => {
                        handleInputChange(event.target.value, "attorneyEmail");
                      }}
                    />
                  </Grid>
                </Grid>
              </SectionCard>
            </Grid>
            {patentInfo.applicationType === "Ordinary" && (
              <Grid item xs="12" className="sectionContainer">
                <SectionCard
                  entity="patent"
                  bg={true}
                  title="Complete specification Details"
                >
                  <Grid container spacing="2">
                    <Grid item xs="5">
                      <RadioGroup
                        style={{ flexDirection: "row" }}
                        value={specification}
                        onChange={(event) =>
                          handleSpecificationChange(event.target.value)
                        }
                      >
                        <FormControlLabel
                          label="Deadline"
                          value="specificationDeadline"
                          control={<Radio />}
                        />
                        <FormControlLabel
                          label="Filed Date"
                          value="specificationFiled"
                          control={<Radio />}
                        />
                      </RadioGroup>
                    </Grid>
                    <Grid item xs="7">
                      {specification && (
                        <UIDatepicker
                          label={
                            specification === "specificationDeadline"
                              ? "Specification Deadline"
                              : "Specification Filed"
                          }
                          value={patentInfo[specification]}
                          onChange={(date) => {
                            handleInputChange(date, specification);
                          }}
                        />
                      )}
                    </Grid>
                  </Grid>
                </SectionCard>
              </Grid>
            )}
            {status === "Pending" &&
              !["Divisional", "Ordinary"].includes(
                patentInfo.applicationType
              ) && (
                <>
                  <Grid item xs="12" className="sectionContainer">
                    <SectionCard
                      entity="patent"
                      bg={true}
                      isExpansionPanel
                      title="English Translation of Priority Document"
                    >
                      <Grid container spacing="2">
                        <Grid item xs="5">
                          <RadioGroup
                            style={{ flexDirection: "row" }}
                            value={engTransPr}
                            onChange={(event) =>
                              handleTransPrChange(event.target.value)
                            }
                          >
                            <FormControlLabel
                              label="Deadline"
                              value="priorityDocumentDeadline"
                              control={<Radio />}
                            />
                            <FormControlLabel
                              label="Filed Date"
                              value="priorityDocumentFiled"
                              control={<Radio />}
                            />
                          </RadioGroup>
                        </Grid>
                        <Grid item xs="7">
                          {engTransPr && (
                            <UIDatepicker
                              label={
                                engTransPr === "priorityDocumentFiled"
                                  ? "Priority Document Filed"
                                  : "Priority Document Deadline"
                              }
                              value={patentInfo[engTransPr]}
                              onChange={(date) => {
                                handleInputChange(date, engTransPr);
                              }}
                            />
                          )}
                        </Grid>
                      </Grid>
                    </SectionCard>
                  </Grid>
                  <Grid item xs="12" className="sectionContainer">
                    <SectionCard
                      entity="patent"
                      bg={true}
                      title="English Translation of PCT Document"
                      isExpansionPanel
                    >
                      <Grid container spacing="2">
                        <Grid item xs="5">
                          <RadioGroup
                            value={engTransPct}
                            style={{ flexDirection: "row" }}
                            onChange={(event) =>
                              handleTransPctChange(event.target.value)
                            }
                          >
                            <FormControlLabel
                              label="Deadline"
                              value="pctDocumentDeadline"
                              control={<Radio />}
                            />
                            <FormControlLabel
                              label="Filed Date"
                              value="pctDocumentFiled"
                              control={<Radio />}
                            />
                          </RadioGroup>
                        </Grid>
                        <Grid item xs="7">
                          {engTransPct && (
                            <UIDatepicker
                              label={
                                engTransPct === "pctDocumentFiled"
                                  ? "Pct document filed"
                                  : "Pct document deadline"
                              }
                              value={patentInfo[engTransPct]}
                              onChange={(date) => {
                                handleInputChange(date, engTransPct);
                              }}
                            />
                          )}
                        </Grid>
                      </Grid>
                    </SectionCard>
                  </Grid>
                </>
              )}
            {status === "Granted" && (
              <>
                <Grid item xs="12" className="sectionContainer">
                  <SectionCard
                    entity="patent"
                    bg={true}
                    title="Form 27 Details"
                  >
                    <div>
                      <UIDatepicker
                        label="Deadline"
                        maxDate={getForm27DeadlineMaxDate()}
                        value={patentInfo.form27Deadline}
                        onChange={(date) => {
                          handleInputChange(date, "form27Deadline");
                        }}
                        disable
                      />
                      <Button
                        color="primary"
                        variant="outlined"
                        style={{ display: "block", margin: "8px 0px" }}
                        onClick={handleForm27DateAdd}
                      >
                        Add Filled Dates
                      </Button>
                      {patentInfo.form27FiledDates.map((date, index) => {
                        return (
                          <div
                            style={{ display: "flex", marginTop: "8px" }}
                            key={date}
                          >
                            <UIDatepicker
                              label="Form27 filed date"
                              value={date}
                              disableFuture
                              onChange={(date) =>
                                handleForm27FiledDateChange(date, index)
                              }
                            />
                            <IconButton
                              onClick={() => handleForm27DateRemove(index)}
                            >
                              <Delete />
                            </IconButton>
                          </div>
                        );
                      })}
                    </div>
                  </SectionCard>
                </Grid>
                <Grid item xs="12" className="sectionContainer">
                  <SectionCard
                    entity="patent"
                    bg={true}
                    title="Renewal Details"
                  >
                    <RadioGroup
                      value={renewal}
                      style={{ flexDirection: "row" }}
                      onChange={(event) =>
                        handleRenewalDetailsChange(event.target.value)
                      }
                    >
                      <FormControlLabel
                        label="Deadline"
                        value="renewalDeadline"
                        control={<Radio />}
                      />
                      <FormControlLabel
                        label="Not to be filed"
                        value="renewalNotToBeFiled"
                        control={<Radio />}
                      />
                    </RadioGroup>
                    {["renewalDeadline"].includes(renewal) && (
                      <>
                        <div style={{ display: "flex" }}>
                          <UIDatepicker
                            value={patentInfo.renewalDeadline}
                            onChange={(date) =>
                              handleInputChange(date, "renewalDeadline")
                            }
                            label="Renewal deadline"
                          />
                        </div>
                        <div style={{ marginTop: "8px" }}>
                          <Button
                            color="primary"
                            variant="outlined"
                            onClick={handleRenewalFiledYears}
                            style={{ marginBottom: "8px" }}
                          >
                            Add Filed Years
                          </Button>
                          <Grid item xs={12}>
                            {patentInfo.renewalFiledYears.map(
                              (filedYear, index) => (
                                <FormControl
                                  key={index}
                                  fullWidth
                                  variant="outlined"
                                  style={{ marginBottom: "8px" }}
                                >
                                  <InputLabel>Renewal Years</InputLabel>
                                  <Select
                                    label="Renewal Years"
                                    onChange={(event) =>
                                      handleRenewalFiledYearChange(
                                        event.target.value,
                                        index
                                      )
                                    }
                                    value={filedYear}
                                  >
                                    {renewalYearsOptions.map((year, index) => (
                                      <MenuItem
                                        key={"compnay-" + index}
                                        value={year.value}
                                      >
                                        {year.text}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              )
                            )}
                          </Grid>
                        </div>
                      </>
                    )}
                  </SectionCard>
                </Grid>
              </>
            )}

            {["FER Replied", "Examination", "Hearing"].includes(status) && (
              <Grid item xs="12" className="sectionContainer">
                <SectionCard
                  entity="patent"
                  bg={true}
                  title="FER Details"
                  isExpansionPanel={status === "Hearing"}
                >
                  <RadioGroup
                    style={{ flexDirection: "row" }}
                    onChange={(event) => handleFerChange(event.target.value)}
                    value={fer}
                  >
                    <FormControlLabel
                      label="Deadline"
                      value="ferDeadline"
                      control={<Radio />}
                    />
                    <FormControlLabel
                      label="Filed Date"
                      value="ferFiled"
                      control={<Radio />}
                    />
                  </RadioGroup>
                  <div style={{ display: "flex" }}>
                    <UIDatepicker
                      label={
                        fer === "ferDeadline" ? "FER deadline" : "FER filed"
                      }
                      value={patentInfo[fer]}
                      onChange={(date) => handleInputChange(date, fer)}
                    />
                    {fer === "ferDeadline" && (
                      <FormControl
                        fullWidth
                        variant="outlined"
                        style={{ marginLeft: "8px" }}
                      >
                        <InputLabel>FER Extension</InputLabel>
                        <Select
                          label="Choose fer"
                          onChange={(event) =>
                            handleInputChange(
                              event.target.value,
                              "ferExtension"
                            )
                          }
                          value={patentInfo.ferExtension}
                        >
                          {ferExtensionOptions.map((option, index) => (
                            <MenuItem
                              key={"compnay-" + index}
                              value={option.value}
                            >
                              {option.text}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  </div>
                </SectionCard>
              </Grid>
            )}
            {(status === "FER Replied" || status === "Hearing") && (
              <Grid item xs="12" className="sectionContainer">
                <SectionCard
                  entity="patent"
                  bg={true}
                  title="Hearing Details"
                  fullwidth
                >
                  <UIDatepicker
                    label="Hearing Date"
                    value={patentInfo.hearingDate}
                    onChange={(date) => handleInputChange(date, "hearingDate")}
                  />
                </SectionCard>
              </Grid>
            )}
            <Grid item xs="12">
              <SectionCard entity="patent" bg={true} title="Other Information">
                <TextField
                  label="Notes"
                  variant="outlined"
                  multiline
                  rows="4"
                  fullWidth
                  value={patentInfo.notes}
                  onChange={(event) =>
                    handleInputChange(event.target.value, "notes")
                  }
                  style={{ margin: "8px 0px" }}
                />
              </SectionCard>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Toaster
        open={toasterInfo.show}
        message={toasterInfo.message}
        type={toasterInfo.type}
        onClose={() => setToasterInfo({})}
      />
    </PopUp>
  );
}

const mapStateToProps = (state) => {
  return {
    companies: state.tableReducer.companies
  };
};

export default connect(mapStateToProps)(CreatePatent);
