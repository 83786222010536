import React, { Component } from "react";
import {
  Card,
  Grid,
  Button,
  CircularProgress,
  TextField
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import "./login.scss";
import { loginUser } from "../../../api/loginApi";
import Cookies from "js-cookie";
import projectLogo from "assets/images/ipbloc.png";
import Toaster from "components/shared/Toaster";

const styles = () => ({
  signin: {
    background: "#1A2038"
  },
  wrapper: {
    position: "relative"
  },

  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
});
class Login extends Component {
  state = {
    email: "",
    password: "",
    agreement: "",
    loading: "",
    error: {
      email: false,
      password: false
    },
    showToaster: false,
    toastMessage: ""
  };
  handleChange = (event) => {
    event.persist();
    let updatedError = { ...this.state.error };
    updatedError[event.target.name] = false;
    this.setState({
      [event.target.name]: event.target.value,
      error: updatedError
    });
  };
  handleFormSubmit = (event) => {
    event.preventDefault();
    let valid = this.validateForm();
    if (!valid) {
      return;
    }
    this.setState({
      loading: true
    });
    this.handleLogin();
    // setTimeout(() => {
    //   this.props.history.push("/dashboard");
    // }, 2000);
  };

  handleLogin = async () => {
    try {
      let { email, password } = this.state;
      let response = await loginUser({ userId: email, password: password });
      if (response && response.data && response.data.token) {
        Cookies.set("token", response.data.token);
        Cookies.set("userId", email);
      }
      this.setState({ loading: false });
      this.props.history.push("/dashboard");
    } catch (err) {
      this.setState({
        loading: false,
        showToaster: true,
        toastMessage: "Check email and password"
      });
    }
  };

  validateForm = () => {
    let updatedError = { ...this.state.error };
    let showToaster = this.state.showToaster;
    let toastMessage = this.state.toastMessage;
    let valid = true;
    if (!this.state.email) {
      updatedError.email = true;
      valid = false;
      showToaster = true;
      toastMessage = "Enter Email";
    }
    if (!this.state.password) {
      updatedError.password = true;
      valid = false;
      showToaster = true;
      toastMessage = "Enter Password";
    }
    this.setState({
      error: updatedError,
      showToaster: showToaster,
      toastMessage: toastMessage
    });
    return valid;
  };
  render() {
    let { email, password, error } = this.state;
    let { classes } = this.props;
    return (
      <div className="signin flex justify-center w-full h-full-screen">
        <div className="p-8">
          <Card className="signin-card position-relative y-center">
            <Grid container>
              <Grid item lg={5} md={5} sm={5} xs={12}>
                <div className="flex-column bg-light-gray justify-center items-center h-full">
                  <h6>INTELLECTUAL PROPERTY management on the cloud</h6>
                  <img src={projectLogo} width="120px" alt="" />
                </div>
              </Grid>
              <Grid item lg={7} md={7} sm={7} xs={12}>
                <div className="h-full  position-relative">
                  <h4>Login</h4>
                  <form
                    className="p-9"
                    ref={this.form}
                    onSubmit={this.handleFormSubmit}
                  >
                    <TextField
                      className="mb-6 w-full"
                      variant="outlined"
                      label="Email"
                      onChange={this.handleChange}
                      type="email"
                      name="email"
                      value={email}
                      validators={["required", "isEmail"]}
                      errorMessages={[
                        "this field is required",
                        "email is not valid"
                      ]}
                      error={error.email}
                    />
                    <TextField
                      className="mb-3 w-full"
                      label="Password"
                      variant="outlined"
                      onChange={this.handleChange}
                      name="password"
                      type="password"
                      value={password}
                      validators={["required"]}
                      errorMessages={["this field is required"]}
                      error={error.password}
                    />

                    <div className="flex flex-wrap items-center mb-4">
                      <div className={classes.wrapper}>
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={this.state.loading}
                          type="submit"
                          className="submit-btn"
                        >
                          Sign in
                        </Button>
                        {this.state.loading && (
                          <CircularProgress
                            size={24}
                            className="buttonProgress"
                          />
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </Grid>
            </Grid>
          </Card>
        </div>
        <Toaster
          open={this.state.showToaster}
          message={this.state.toastMessage}
          type={"error"}
          onClose={() => this.setState({ showToaster: false })}
        />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Login);
