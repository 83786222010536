import React, { useState } from "react";
import Drawer from "@material-ui/core/Drawer";
import TextField from "@material-ui/core/TextField";
import { changePassword } from "api/userApi";

import { Box, Menu, Button, List, ListItem, Divider } from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Cookies from "js-cookie";
import { logoutUser } from "../../../api/loginApi";
import { useHistory } from "react-router";
import Toaster from "components/shared/Toaster";

import "./headerUserbox.scss";

export default function HeaderUserbox(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  let [toasterInfo, setToasterInfo] = useState({
    show: false,
    message: "",
    type: ""
  });
  const [passwords, setPasswords] = useState({
    password: "",
    confirmPassword: ""
  });

  let history = useHistory();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const openDrawer = () => {
    setAnchorEl(null);
    setIsOpenDrawer(!isOpenDrawer);
    setIsLoading(false);
  };
  function closeDrawer() {
    setIsOpenDrawer(!isOpenDrawer);
  }
  async function submitPassword(e) {
    e.preventDefault();
    if (!passwords.password || !passwords.confirmPassword) {
      setToasterInfo({
        show: true,
        type: "error",
        message: "Please enter new password and confirm password"
      });
    } else if (
      passwords.password.toLowerCase().length !==
      passwords.confirmPassword.toLowerCase().length
    ) {
      setToasterInfo({
        show: true,
        type: "error",
        message: "New password and confirm password should be same"
      });
    } else {
      setIsLoading(true);
      try {
        let response = await changePassword(passwords);
        if (response && response.data) {
          setToasterInfo({
            show: true,
            type: "success",
            message: "Password changed successfully"
          });
          setTimeout(() => {
            setIsOpenDrawer(!isOpenDrawer);
          }, 1000);
        }
      } catch {}
    }
  }
  function passwordChange(e) {
    let password = { ...passwords };
    password[e.target.name] = e.target.value;
    setPasswords(password);
  }
  async function handleLogOut() {
    let body = {
      token: Cookies.get("token"),
      userId: Cookies.get("userId")
    };
    try {
      await logoutUser(body);
      Cookies.remove("token");
      Cookies.remove("userId");
      history.push("/login");
    } catch {}
  }
  const toggleDrawer = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setIsOpenDrawer({ isOpenDrawer: !isOpenDrawer });
  };
  return (
    <>
      <Button
        color="inherit"
        onClick={handleClick}
        className="text-capitalize px-3 text-left btn-inverse d-flex align-items-center"
      >
        <Box>
          <AccountCircleIcon />
        </Box>
        <div className="d-none d-xl-block pl-3">
          <div className="font-weight-bold  line-height-1">
            {props.user.name}
          </div>
        </div>
      </Button>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center"
        }}
        onClose={handleClose}
        className="ml-2"
        style={{
          width: "250px"
        }}
      >
        <div className="dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
          <List className="text-left bg-transparent d-flex align-items-center flex-column pt-0">
            <Box>
              <AccountCircleIcon />
              {/* <Avatar sizes="44" alt="Emma Taylor" src={avatar5} /> */}
            </Box>
            <div className="pl-3  pr-3">
              <div className="font-weight-bold text-center pt-2 line-height-1">
                {props.user.name}
              </div>
            </div>
            <Divider className="w-100 mt-2" />
            <ListItem button onClick={openDrawer}>
              Change Password
            </ListItem>
            <ListItem button onClick={handleLogOut}>
              Logout
            </ListItem>
          </List>
        </div>
      </Menu>
      <Drawer
        className="drawer"
        anchor={"right"}
        open={isOpenDrawer}
        onClose={toggleDrawer}
      >
        <h6>Change Password</h6>
        <form className="drawer-form">
          <TextField
            fullWidth
            className="mb-3 w-full"
            label="Email"
            variant="outlined"
            disabled
            value={props.user.name}
          />
          {/* <TextField
            fullWidth
            className="mb-3 w-full"
            label="Current Password"
            variant="outlined"
            name="password"
            type="password"
          /> */}
          <TextField
            value={passwords.password}
            onChange={passwordChange}
            fullWidth
            className="mb-3 w-full"
            label="New Password"
            variant="outlined"
            name="password"
            type="password"
          />
          <TextField
            value={passwords.confirmPassword}
            onChange={passwordChange}
            className="mb-3 w-full"
            label="Confrim Password"
            variant="outlined"
            name="confirmPassword"
            type="password"
          />
          <Button
            variant="contained"
            type="button"
            className="submit-btn"
            onClick={closeDrawer}
          >
            Close
          </Button>
          <Button
            className="ml-20"
            variant="contained"
            color="primary"
            disabled={isLoading}
            type="submit"
            onClick={submitPassword}
          >
            Update
          </Button>
        </form>
        {toasterInfo.show && (
          <Toaster
            open={toasterInfo.show}
            message={toasterInfo.message}
            type={toasterInfo.type}
            onClose={() => setToasterInfo({})}
          />
        )}
      </Drawer>
    </>
  );
}
