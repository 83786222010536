import React, { lazy, Suspense, useEffect } from "react";
import { Route, Switch } from "react-router";
import { AnimatePresence, motion } from "framer-motion";

import { ThemeProvider } from "@material-ui/styles";

// import Footer from "components/shared/Footer";
import Header from "components/shared/Header";

// import TradeMarks from "components/main/TradeMarks";
// import Patents from "components/main/Patents";
// import Bills from "components/main/Bills";

import "./DashboardLayout.scss";
import MuiTheme from "theme";
import TrademarkOpposition from "components/main/TrademarkOpposition";
import Clients from "components/main/Clients";
// import Dashboard from "components/main/Dashboard/copyDashboard";
import Dashboard from "components/main/Dashboard";
import { storeCompanies } from "store/actions/table";
import { connect } from "react-redux";
import { getCompanies } from "api/commonApi";

const TradeMarks = lazy(() => import("components/main/TradeMarks"));
const Patents = lazy(() => import("components/main/Patents"));
const Bills = lazy(() => import("components/main/Bills"));

function DashboardLayout(props) {
  useEffect(init, []);

  function init() {
    fetchCompanyOptions();
  }

  async function fetchCompanyOptions() {
    try {
      let response = await getCompanies();
      if (response && response.data) {
        props.storeCompanies(response.data);
      }
    } catch {}
  }

  const pageVariants = {
    initial: {
      opacity: 0
      // scale: 0.99
    },
    in: {
      opacity: 1
      // scale: 1
    },
    out: {
      opacity: 0
      // scale: 1.01
    }
  };

  const pageTransition = {
    type: "tween",
    ease: "anticipate",
    duration: 0.4
  };
  return (
    <ThemeProvider theme={MuiTheme}>
      <div className="app-wrapper">
        <Header />
        <div className="app-main app-main-sidebar-static">
          <div className="app-content">
            <div className="app-content--inner">
              <div className="app-content--inner__wrapper">
                <AnimatePresence>
                  <Suspense
                    fallback={
                      <div className="d-flex align-items-center vh-100 justify-content-center text-center font-weight-bold font-size-lg py-3">
                        {/* <div className="w-50 mx-auto">Please wait...</div> */}
                      </div>
                    }
                  >
                    <Switch>
                      <motion.div
                        initial="initial"
                        animate="in"
                        exit="out"
                        variants={pageVariants}
                        transition={pageTransition}
                      >
                        <Route exact path="/dashboard" component={Dashboard} />
                        <Route
                          exact
                          path="/dashboard/trademarks"
                          component={TradeMarks}
                        />
                        <Route
                          exact
                          path="/dashboard/trademarks/opposition"
                          component={TrademarkOpposition}
                        />
                        <Route
                          exact
                          path="/dashboard/patents"
                          component={Patents}
                        />
                        <Route
                          exact
                          path="/dashboard/bills"
                          component={Bills}
                        />
                        <Route
                          exact
                          path="/dashboard/clients"
                          component={Clients}
                        />
                      </motion.div>
                    </Switch>
                  </Suspense>
                </AnimatePresence>
              </div>
            </div>
            {/* <Footer /> */}
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    storeCompanies: (companies) => dispatch(storeCompanies(companies))
  };
};

export default connect(null, mapDispatchToProps)(DashboardLayout);
