import React, { useEffect, useState } from "react";
import PopUp from "components/shared/Popup";
import SectionCard from "components/shared/SectionCard";
import { Button, Grid, Typography } from "@material-ui/core";
import Toaster from "components/shared/Toaster";
import {
  deleteTrademarkOpposition,
  getTrademarkOppositionById
} from "api/trademarkOppositionApi";
import "./trademark.scss";

export default function ViewTrademarkOpposition(props) {
  let [trademarkOppInfo, setTrademarkInfo] = useState({});
  let { oppositionId, companyId } = trademarkOppInfo;
  let [confirmDelete, setConfirmDelete] = useState(false);
  let [toasterInfo, setToasterInfo] = useState({
    show: false,
    message: "",
    type: ""
  });

  useEffect(init, []);

  function init() {
    fetchTrademarkInfo();
  }

  async function fetchTrademarkInfo() {
    let info = await getTraderMarkOppositionInfo();
    if (info) {
      setTrademarkInfo(info);
    }
  }

  async function getTraderMarkOppositionInfo() {
    try {
      let response = await getTrademarkOppositionById(
        props.tradeMarkId,
        props.company
      );
      if (response && response.data) {
        return response.data;
      }
    } catch {}
  }

  async function handleDelete() {
    let deletedSuccess = await proceedClientDelete();
    if (deletedSuccess) {
      setToasterInfo({
        show: true,
        message: "Trademark opposition deleted successfully",
        type: "success"
      });
      setTimeout(() => {
        props.onClose(true);
      }, 1000);
    }
  }

  async function proceedClientDelete() {
    try {
      await deleteTrademarkOpposition(companyId, oppositionId);
      return "deleted success";
    } catch {
      setToasterInfo({
        show: true,
        message: "Something went wrong",
        type: "error"
      });
    }
  }

  function renderActions() {
    if (confirmDelete) {
      return (
        <>
          <Typography>
            Are you sure you want to delete File No. {oppositionId}.
          </Typography>
          <Button color="primary" variant="contained" onClick={handleDelete}>
            Yes
          </Button>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => setConfirmDelete(false)}
          >
            No
          </Button>
        </>
      );
    } else {
      return (
        <Button
          color="primary"
          variant="contained"
          onClick={() => setConfirmDelete(true)}
        >
          Delete Trade mark Opposition
        </Button>
      );
    }
  }

  return (
    <PopUp
      size="md"
      open={true}
      fullWidth
      onClose={() => props.onClose()}
      title="View Trade mark Opposition"
      renderActions={renderActions}
    >
      <div className="view-trademark">
        <SectionCard entity="trademark" title="File Details" bg={true}>
          <Grid container spacing="1">
            <Grid item xs="6">
              <label>Company</label>
              <p>{trademarkOppInfo.companyId || "-NA-"}</p>
            </Grid>
            <Grid item xs="6">
              <label>File No</label>
              <p>{trademarkOppInfo.fileNo || "-NA-"}</p>
            </Grid>
          </Grid>
        </SectionCard>
        <SectionCard entity="trademark" title="Opposition Details" bg={true}>
          <Grid container spacing="1">
            <Grid item xs="4">
              <label>Opposition No</label>
              <p>{trademarkOppInfo.oppositionId || "-NA-"}</p>
            </Grid>
            <Grid item xs="4">
              <label>Application No</label>
              <p>{trademarkOppInfo.tradeMarkId || "-NA-"}</p>
            </Grid>
            <Grid item xs="4">
              <label>Name/TM</label>
              <p>{trademarkOppInfo.name || "-NA-"}</p>
            </Grid>
            <Grid item xs="4">
              <label>Class</label>
              <p>{trademarkOppInfo.class || "-NA-"}</p>
            </Grid>
            <Grid item xs="4">
              <label>Applicant Name</label>
              <p>{trademarkOppInfo.applicantName || "-NA-"}</p>
            </Grid>
            <Grid item xs="4">
              <label>Opponent Name</label>
              <p>{trademarkOppInfo.opponentName || "-NA-"}</p>
            </Grid>
            <Grid item xs="4">
              <label>Document Status</label>
              <p>{trademarkOppInfo.documentStatus || "-NA-"}</p>
            </Grid>
            <Grid item xs="4">
              <label>Opposition Status</label>
              <p>{trademarkOppInfo.oppositionStatus || "-NA-"}</p>
            </Grid>
            <Grid item xs="4">
              <label>Jurisdiction</label>
              <p>{trademarkOppInfo.jurisdiction || "-NA-"}</p>
            </Grid>
          </Grid>
        </SectionCard>
        <Grid container spacing="1" className="mb-3">
          <Grid item xs="6">
            <SectionCard entity="trademark" title="TMO Details" bg={true}>
              <Grid container spacing="1">
                <Grid item xs="6">
                  <label>Deadline</label>
                  <p>{trademarkOppInfo.tmoDeadline || "-NA-"}</p>
                </Grid>
                <Grid item xs="6">
                  <label>Filed date</label>
                  <p>{trademarkOppInfo.tmoFiled || "-NA-"}</p>
                </Grid>
              </Grid>
            </SectionCard>
          </Grid>
          <Grid item xs="6">
            <SectionCard
              entity="trademark"
              title="TMO Counter details"
              bg={true}
            >
              <Grid container spacing="1">
                <Grid item xs="6">
                  <label>Deadline</label>
                  <p>{trademarkOppInfo.tmoCounterDeadline || "-NA-"}</p>
                </Grid>
                <Grid item xs="6">
                  <label>Filed date</label>
                  <p>{trademarkOppInfo.tmoCounterFiled || "-NA-"}</p>
                </Grid>
              </Grid>
            </SectionCard>
          </Grid>
        </Grid>
        <Grid container spacing="1" className="mb-3">
          <Grid item xs="6">
            <SectionCard entity="trademark" title="Rule45 Details" bg={true}>
              <Grid container spacing="1">
                <Grid item xs="6">
                  <label>Deadline</label>
                  <p>{trademarkOppInfo.rule45Deadline || "-NA-"}</p>
                </Grid>
                <Grid item xs="6">
                  <label>Filed date</label>
                  <p>{trademarkOppInfo.rule45Filed || "-NA-"}</p>
                </Grid>
              </Grid>
            </SectionCard>
          </Grid>
          <Grid item xs="6">
            <SectionCard entity="trademark" title="Rule46 details" bg={true}>
              <Grid container spacing="1">
                <Grid item xs="6">
                  <label>Deadline</label>
                  <p>{trademarkOppInfo.rule46Deadline || "-NA-"}</p>
                </Grid>
                <Grid item xs="6">
                  <label>Filed date</label>
                  <p>{trademarkOppInfo.rule46Filed || "-NA-"}</p>
                </Grid>
              </Grid>
            </SectionCard>
          </Grid>
        </Grid>
        <Grid container spacing="1" className="mb-3">
          <Grid item xs="6">
            <SectionCard entity="trademark" title="Rule47 details" bg={true}>
              <Grid container spacing="1">
                <Grid item xs="6">
                  <label>Deadline</label>
                  <p>{trademarkOppInfo.rule47Deadline || "-NA-"}</p>
                </Grid>
                <Grid item xs="6">
                  <label>Filed date</label>
                  <p>{trademarkOppInfo.rule47Filed || "-NA-"}</p>
                </Grid>
              </Grid>
            </SectionCard>
          </Grid>
          <Grid item xs="6">
            <SectionCard entity="trademark" title="Notes" bg={true}>
              <Grid container spacing="1">
                <Grid item xs="12">
                  <label>Notes</label>
                  <p>{trademarkOppInfo.notes || "-NA-"}</p>
                </Grid>
              </Grid>
            </SectionCard>
          </Grid>
        </Grid>
        {trademarkOppInfo.hearingHistory &&
          trademarkOppInfo.hearingHistory.length > 0 && (
            <SectionCard entity="trademark" title="Hearing Details" bg={true}>
              {trademarkOppInfo.hearingHistory.map((val, i) => {
                return (
                  <Grid container spacing="1" key={"trademark-" + i}>
                    <Grid item xs="4">
                      <label>Hearing Date</label>
                      <p>{val.hearingDate || "-NA-"}</p>
                    </Grid>
                    <Grid item xs="6">
                      <label>Hearing Comment</label>
                      <p>{val.hearingComment || "-NA-"}</p>
                    </Grid>
                  </Grid>
                );
              })}
            </SectionCard>
          )}
      </div>
      <Toaster
        open={toasterInfo.show}
        message={toasterInfo.message}
        type={toasterInfo.type}
        onClose={() => setToasterInfo({})}
      />
    </PopUp>
  );
}
