import React, { useEffect, useState } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {
  AppBar,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select
} from "@material-ui/core";
import PatentDashboard from "./PatentDashboard";
import TradeMarkDashboard from "./TrademarkDashboard";
import { storeCompanies } from "store/actions/table";
import { connect } from "react-redux";
import { getCompanies } from "api/commonApi";
import queryString from "query-string";
import moment from "moment";

import "./dashboard.scss";

function Dashboard(props) {
  let [activeTab, setActiveTab] = useState(0);
  let [searchedCompany, setSearchedCompany] = useState("All");

  useEffect(init, []);

  function init() {
    if (props.companies.length === 0) {
      fetchCompanyOptions();
    }
    let search = queryString.parse(props.history.location.search);
    let tabIndex = Number(search.activeTab);
    if (tabIndex) {
      setActiveTab(tabIndex);
      if (search.company === "All" && tabIndex === 1) {
        search.company = "vtm";
      }
    } else {
      search.activeTab = 0;
    }
    if (!search.company && tabIndex !== 1) {
      search.company = "All";
    }
    setSearchedCompany(search.company);
    props.history.push({
      pathname: props.history.location.pathname,
      search: queryString.stringify(search)
    });
  }

  function handleCompanyChange(value) {
    setSearchedCompany(value);
    let search = queryString.parse(props.history.location.search);
    search.company = value;
    props.history.push({
      pathname: props.history.location.pathname,
      search: queryString.stringify(search)
    });
  }

  async function fetchCompanyOptions() {
    try {
      let response = await getCompanies();
      if (response && response.data) {
        props.storeCompanies(response.data);
      }
    } catch {}
  }

  function handleTabChange(event, newValue) {
    setActiveTab(newValue);
    let search = queryString.parse(props.history.location.search);
    if (newValue === 1) {
      search.company = "vtm";
      setSearchedCompany("vtm");
    }
    search.activeTab = newValue;
    props.history.push({
      pathname: props.history.location.pathname,
      search: queryString.stringify(search)
    });
  }

  function getFilteredCompanies() {
    return props.companies.filter((company) => {
      return ["vtm", "absolute", "active", "va"].includes(company.companyId);
    });
  }

  function getIsDeadline(deadline) {
    let deadlineDate = moment(new Date()).add(7, "days");
    return moment(deadline).isSameOrBefore(deadlineDate);
  }

  return (
    <section className="dashboard">
      <AppBar position="static" color="default" className="appbar">
        <Grid item xs="8" class="tabs">
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            aria-label="simple tabs example"
          >
            <Tab label="Patents" />
            <Tab label="Trade Marks" />
            <Tab label="Copy Rights" />
          </Tabs>
        </Grid>
        <Grid item xs="3" className="right-filter">
          <FormControl fullWidth variant="outlined">
            <InputLabel>Company</InputLabel>
            <Select
              onChange={(event) => handleCompanyChange(event.target.value)}
              label="Company"
              value={searchedCompany}
            >
              {activeTab === 0 && <MenuItem value="All">All</MenuItem>}
              {getFilteredCompanies().map((company, index) => (
                <MenuItem key={"compnay-" + index} value={company.companyId}>
                  {company.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </AppBar>
      <TabPanel value={activeTab} index={0}>
        <PatentDashboard
          searchedCompany={searchedCompany}
          getIsDeadline={getIsDeadline}
        />
      </TabPanel>
      <TabPanel value={activeTab} index={1}>
        <TradeMarkDashboard
          searchedCompany={searchedCompany}
          getIsDeadline={getIsDeadline}
        />
      </TabPanel>
      <TabPanel value={activeTab} index={2}>
        {/* <FormRow /> */}
      </TabPanel>
    </section>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      className="tab-panel"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    companies: state.tableReducer.companies
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    storeCompanies: (companies) => dispatch(storeCompanies(companies))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
