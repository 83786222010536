import React, { useEffect, useState } from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import SectionCard from "components/shared/SectionCard";
import PopUp from "components/shared/Popup";
import { getPatentById, deletePatent } from "api/patentApi";
import Toaster from "components/shared/Toaster";
import "./createpatent.scss";
function ViewPatent(props) {
  let [toasterInfo, setToasterInfo] = useState({
    show: false,
    message: "",
    type: ""
  });
  let [confirmDelete, setConfirmDelete] = useState(false);

  let [patentInfo, setPatentInfo] = useState({
    companyId: "",
    applicantName: "",
    applicationDate: "",
    applicationType: "",
    attorneyEmail: "",
    attorneyName: "",
    ferDeadline: "",
    ferExtension: "",
    ferFiled: "",
    fileNo: "",
    form1Deadline: "",
    form1Filed: "",
    form3Deadline: "",
    form3FiledDates: [],
    form3RemainderEmails: [],
    form18Deadline: "",
    form18Filed: "",
    form18NotToBeFiled: "",
    form18RemainderEmails: [],
    form27Deadline: "",
    form27FiledDates: [],
    form27NotToBeFiled: "",
    hearingDate: "",
    notes: "",
    patentId: "",
    patentNo: "",
    pctDocumentDeadline: "",
    pctDocumentFiled: "",
    priority: [],
    priorityDocumentDeadline: "",
    priorityDocumentFiled: "",
    referenceNo: "",
    renewalDeadline: "",
    renewalFiled: "",
    renewalFiledDates: [],
    renewalNotToBeFiled: "",
    renewalYears: 0,
    status: "",
    title: ""
  });

  let { edit } = props;
  let { status } = patentInfo;
  useEffect(init, []);

  function init() {
    if (edit) {
      getPatentInfo();
    }
  }

  async function getPatentInfo() {
    let patentDetails = await fetchEditedPatentInfo();
    if (patentDetails) {
      let company = props.companies.find(
        (company) => company.companyId === patentDetails.companyId
      );
      if (company) {
        patentDetails.company = company.name;
      }
      setPatentInfo({ ...patentInfo, ...patentDetails });
    }
  }

  async function fetchEditedPatentInfo() {
    try {
      let response = await getPatentById(props.patentId, props.companyId);
      if (response && response.data) {
        return response.data;
      }
    } catch {}
  }

  async function handleDelete() {
    let deletedSuccess = await proceedPatentDelete();
    if (deletedSuccess) {
      setToasterInfo({
        show: true,
        message: "Patent deleted success",
        type: "success"
      });
      setTimeout(() => {
        props.onClose(true);
      }, 1000);
    }
  }

  async function proceedPatentDelete() {
    try {
      await deletePatent(patentInfo.companyId, patentInfo.patentId);
      return "deleted success";
    } catch {
      setToasterInfo({
        show: true,
        message: "Something went wrong",
        type: "error"
      });
    }
  }

  function renderActions() {
    if (confirmDelete) {
      return (
        <>
          <Typography>
            Are you sure you want to delete File No. {patentInfo.patentId}.
          </Typography>
          <Button color="primary" variant="contained" onClick={handleDelete}>
            Yes
          </Button>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => setConfirmDelete(false)}
          >
            No
          </Button>
        </>
      );
    } else {
      return (
        <Button
          color="primary"
          variant="contained"
          onClick={() => setConfirmDelete(true)}
        >
          Delete Patent
        </Button>
      );
    }
  }

  return (
    <PopUp
      size="md"
      open={true}
      fullWidth
      fullScreen
      onClose={() => props.onClose()}
      title={`View Patent`}
      renderActions={renderActions}
    >
      <div className="view-patent">
        <Grid container spacing="2" justify="space-between">
          <Grid item xs="6">
            <Grid item xs="12" className="sectionContainer">
              <SectionCard
                entity="patent"
                bg={true}
                fullwidth
                title="File Details"
              >
                <Grid container spacing="2">
                  <Grid item xs="4">
                    <label>Company</label>
                    <p>{patentInfo.company}</p>
                  </Grid>
                  <Grid item xs="4">
                    <label>File No.</label>
                    <p>{patentInfo.fileNo}</p>
                  </Grid>
                  <Grid item xs="4">
                    <label>Reference No</label>
                    <p>{patentInfo.referenceNo}</p>
                  </Grid>
                </Grid>
              </SectionCard>
            </Grid>
            <Grid itex xs="12" className="sectionContainer">
              <SectionCard
                entity="patent"
                bg={true}
                title="Application Details"
                fullwidth
              >
                <Grid container spacing="2">
                  <Grid item xs="4">
                    <label>Application No.</label>
                    <p>{patentInfo.patentId || "-NA-"}</p>
                  </Grid>
                  <Grid item xs="4">
                    <label>Application Type</label>
                    <p>{patentInfo.applicationType || "-NA-"}</p>
                  </Grid>
                  <Grid item xs="4">
                    <label>Status</label>
                    <p>{patentInfo.status || "-NA-"}</p>
                  </Grid>
                  <Grid item xs="4">
                    <label>Application Date</label>
                    <p>{patentInfo.applicationDate || "-NA-"}</p>
                  </Grid>
                  <Grid item xs="4">
                    <label>Applicant Name</label>
                    <p>{patentInfo.applicantName || "-NA-"}</p>
                  </Grid>
                  {(status === "Granted" || status === "Abandoned") && (
                    <Grid item xs="4">
                      <label>Patent No.</label>
                      <p>{patentInfo.patentNo || "-NA-"}</p>
                    </Grid>
                  )}
                  <Grid item xs="12">
                    <label>Title</label>
                    <p>{patentInfo.title}</p>
                  </Grid>
                  {patentInfo.applicationType &&
                    patentInfo.applicationType !== "Ordinary" && (
                      <Grid item xs="12">
                        {patentInfo.priority.map((priority) => {
                          return (
                            <div
                              style={{ display: "flex", margin: "8px 0px" }}
                              key={priority.no}
                            >
                              <label>Priority Date: &nbsp;</label>
                              <p>{priority.date || "-NA-"}</p>
                            </div>
                          );
                        })}
                      </Grid>
                    )}
                </Grid>
              </SectionCard>
            </Grid>
            <Grid item xs="12" className="sectionContainer">
              <SectionCard
                entity="patent"
                bg={true}
                title="Form 18 Details"
                fullwidth
              >
                <Grid container spacing="2">
                  <Grid item xs="6">
                    <label>Filed Date: &nbsp;</label>
                    <p>{patentInfo.form18Filed || "-NA-"}</p>
                  </Grid>
                  <Grid item xs="6">
                    <label>Deadline Date: &nbsp;</label>
                    <p>{patentInfo.form18Deadline || "-NA-"}</p>
                  </Grid>
                </Grid>
              </SectionCard>
            </Grid>
            {(status === "Pending" ||
              status === "Examination" ||
              status === "FER Replied") && (
              <Grid item xs="12" className="sectionContainer">
                <SectionCard entity="patent" bg={true} title="Form 3 Details">
                  <Grid container spacing="2">
                    <Grid item xs="6">
                      <label>Deadline: &nbsp;</label>
                      <p>{patentInfo.form3Deadline || "-NA-"}</p>
                    </Grid>
                    <Grid item xs="6">
                      <label>Filed Dates: &nbsp;</label>
                      <p>{patentInfo.form3FiledDates.join(", ") || "-NA-"}</p>
                    </Grid>
                  </Grid>
                </SectionCard>
              </Grid>
            )}
            {(status === "Pending" ||
              status === "Examination" ||
              status === "FER Replied") && (
              <Grid item xs="12" className="sectionContainer">
                <SectionCard entity="patent" bg={true} title="Form 1 Details">
                  <Grid container spacing="2">
                    <Grid item xs="6">
                      <label>Filed Date: &nbsp;</label>
                      <p>{patentInfo.form1Filed || "-NA-"}</p>
                    </Grid>
                    <Grid item xs="6">
                      <label>Deadline: &nbsp;</label>
                      <p>{patentInfo.form1Deadline || "-NA-"}</p>
                    </Grid>
                  </Grid>
                </SectionCard>
              </Grid>
            )}
          </Grid>
          <Grid item xs="6">
            <Grid item xs="12" className="sectionContainer">
              <SectionCard entity="patent" bg={true} title="Attorney Details">
                <Grid container spacing="2">
                  <Grid item xs="6">
                    <label>Attorney Name</label>
                    <p>{patentInfo.attorneyName || "-NA-"}</p>
                  </Grid>
                  <Grid item xs="6">
                    <label>Attorney Email</label>
                    <p>{patentInfo.attorneyEmail || "-NA-"}</p>
                  </Grid>
                </Grid>
              </SectionCard>
            </Grid>
            <Grid item xs="12" className="sectionContainer">
              <SectionCard
                entity="patent"
                bg={true}
                title="Eng Trans Pr. Doc details:"
              >
                <Grid container spacing="2">
                  <Grid item xs="6">
                    <label>Filed Date: &nbsp;</label>
                    <p>{patentInfo.priorityDocumentFiled || "-NA-"}</p>
                  </Grid>
                  <Grid item xs="6">
                    <label>Deadline: &nbsp;</label>
                    <p>{patentInfo.priorityDocumentDeadline || "-NA-"}</p>
                  </Grid>
                </Grid>
              </SectionCard>
            </Grid>
            <Grid item xs="12" className="sectionContainer">
              <SectionCard
                entity="patent"
                bg={true}
                title="Eng Trans PCT Doc details:"
              >
                <Grid container spacing="2">
                  <Grid item xs="6">
                    <label>Filed Date: &nbsp;</label>
                    <p>{patentInfo.pctDocumentFiled || "-NA-"}</p>
                  </Grid>
                  <Grid item xs="6">
                    <label>Deadline: &nbsp;</label>
                    <p>{patentInfo.pctDocumentDeadline || "-NA-"}</p>
                  </Grid>
                </Grid>
              </SectionCard>
            </Grid>
            {status === "Granted" && (
              <>
                <Grid item xs="12" className="sectionContainer">
                  <SectionCard
                    entity="patent"
                    bg={true}
                    title="Form 27 Details"
                  >
                    <Grid container spacing="2">
                      <Grid item xs="6">
                        <label>Deadline: &nbsp;</label>
                        <p>{patentInfo.pctDocumentDeadline || "-NA-"}</p>
                      </Grid>
                      <Grid item xs="6">
                        <label>Filled Dates: &nbsp;</label>
                        <p>
                          {patentInfo.form27FiledDates.join(", ") || "-NA-"}
                        </p>
                      </Grid>
                    </Grid>
                  </SectionCard>
                </Grid>
                <Grid item xs="12" className="sectionContainer">
                  <SectionCard
                    entity="patent"
                    bg={true}
                    title="Renewal Details"
                  >
                    <Grid container spacing="2">
                      <Grid item xs="4">
                        <label>Filled Dates: &nbsp;</label>
                        <p>{patentInfo.renewalFiled || "-NA-"}</p>
                      </Grid>
                      <Grid item xs="4">
                        <label>Deadline: &nbsp;</label>
                        <p>{patentInfo.renewalDeadline || "-NA-"}</p>
                      </Grid>
                      <Grid item xs="4">
                        <label>Years: &nbsp;</label>
                        <p>{patentInfo.renewalYears || "-NA-"}</p>
                      </Grid>
                    </Grid>
                  </SectionCard>
                </Grid>
              </>
            )}

            {status === "FER Replied" && (
              <Grid item xs="12" className="sectionContainer">
                <SectionCard entity="patent" bg={true} title="FER Details">
                  <Grid container spacing="2">
                    <Grid item xs="4">
                      <label>Deadline: &nbsp;</label>
                      <p>{patentInfo.ferDeadline || "-NA-"}</p>
                    </Grid>
                    <Grid item xs="4">
                      <label>Filed Date: &nbsp;</label>
                      <p>{patentInfo.ferFiled || "-NA-"}</p>
                    </Grid>
                    <Grid item xs="4">
                      <label>Extension: &nbsp;</label>
                      <p>{patentInfo.ferExtension || "-NA-"}</p>
                    </Grid>
                  </Grid>
                </SectionCard>
              </Grid>
            )}
            {(status === "Examination" || status === "FER Replied") && (
              <Grid item xs="12" className="sectionContainer">
                <SectionCard
                  entity="patent"
                  bg={true}
                  title="Hearing Details"
                  fullwidth
                >
                  <label>Hearing Date</label>
                  <p>{patentInfo.hearingDate || "-NA-"}</p>
                </SectionCard>
              </Grid>
            )}
            <Grid item xs="12">
              <SectionCard entity="patent" bg={true} title="Other Information">
                <label>Notes</label>
                <p>{patentInfo.notes || "-NA-"}</p>
              </SectionCard>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Toaster
        open={toasterInfo.show}
        message={toasterInfo.message}
        type={toasterInfo.type}
        onClose={() => setToasterInfo({})}
      />
    </PopUp>
  );
}

const mapStateToProps = (state) => {
  return {
    companies: state.tableReducer.companies
  };
};

export default connect(mapStateToProps)(ViewPatent);
