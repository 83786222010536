export default [
  {
    title: "File No.",
    field: "clientId"
  },
  {
    title: "Name",
    field: "name"
  },
  {
    title: "Address",
    field: "address"
  },
  {
    title: "GST No.",
    field: "gstin"
  },
  {
    title: "Email",
    field: "email"
  },
  {
    title: "Phone No.",
    field: "phone"
  },
  {
    title: "Actions"
  }
];
