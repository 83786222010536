import React, { useEffect, useRef, useState } from "react";
import { Grid } from "@material-ui/core";
import DashboardCard from "./Card";
import moment from "moment";
import PopUp from "components/shared/Popup";
import Filter from "./Filter";
import { getTrademarkByKey, getTrademarkByDate } from "api/trademarkApi";
import CreateTrademark from "../TradeMarks/CreateTrademark";
import ViewTrademark from "../TradeMarks/ViewTrademark";
import TradeMarkView from "./ViewTrademark";
import { getTrademarkOppositionByKey } from "api/trademarkOppositionApi";
import CreateTrademarkOpposition from "../TrademarkOpposition/CreateTrademarkOpposition";
import ViewTrademarkOpposition from "../TrademarkOpposition/ViewTrademarkOpposition";

export default function TradeMarkDashboard(props) {
  let [trademarkInfo, setTrademarkInfo] = useState(getInitTrademarkInfo());
  let [count, setCount] = useState({});
  let [filterLoading, setfilterLoading] = useState(false);
  let [showFilter, setShowFilter] = useState(false);
  let [dataLoading, setdataLoading] = useState(false);
  let [filteredData, setFilteredData] = useState(null);
  let prevCompany = useRef();
  let [showCreate, setShowCreate] = useState(false);
  let [type, setType] = useState("");
  let [showPreview, setShowPreview] = useState(false);
  let [editedTrademark, setEditedTrademark] = useState({});
  let [showPatchEdit, setShowPatchEdit] = useState(false);
  let filteredCard = useRef("");
  let [columnOneData, setColumnOneData] = useState({
    "ER Issue": null,
    "TM-O (Notice of opp.)": null,
    "Rule 45": null
  });
  let [columnTwoData, setColumnTwoData] = useState({
    "Renewed Upto": null,
    "TM-O (Counter St.)": null,
    "Rule 46": null
  });
  let [columnThreeData, setColumnThreeData] = useState({
    "Show Cause Hearing": null,
    "Opposition Hearing": null,
    "Rule 47": null
  });

  const labelFieldMapping = {
    "ER Issue": "erReply",
    "Renewed Upto": "renewedUptoDate",
    "Show Cause Hearing": "showCauseHearingDate",
    "TM-O (Notice of opp.)": "tmo",
    "TM-O (Counter St.)": "tmoCounter",
    "Opposition Hearing": "hearing",
    "Rule 45": "rule45",
    "Rule 46": "rule46",
    "Rule 47": "rule47"
  };

  function getInitTrademarkInfo() {
    return {
      "ER Issue": null,
      "Renewed Upto": null,
      "Show Cause Hearing": null,
      "TM-O (Notice of opp.)": null,
      "TM-O (Counter St.)": null,
      "Opposition Hearing": null,
      "Rule 45": null,
      "Rule 46": null,
      "Rule 47": null
    };
  }

  useEffect(init, []);

  useEffect(handleFilterByCompany, [props.searchedCompany]);

  function handleFilterByCompany() {
    if (prevCompany.current !== props.searchedCompany) {
      prevCompany.current = props.searchedCompany;
      fetchTrademarkInfo();
    }
  }

  function init() {
    fetchTrademarkInfo();
    prevCompany.current = props.searchedCompany;
  }

  async function fetchTrademarkInfo() {
    setdataLoading(true);
    let responses = await fetchTrademarkByKey();
    setdataLoading(false);
    if (responses) {
      let updatedCount = {};
      let updatedTrademarkInfo = { ...trademarkInfo };
      let updatedColumnOneData = { ...columnOneData };
      let updatedColumnTwoData = { ...columnTwoData };
      let updatedColumnThreeData = { ...columnThreeData };
      let columnOne = [0, 3, 6];
      let columnTwo = [1, 4, 7];
      let columnThree = [2, 5, 8];
      Object.keys(updatedTrademarkInfo).forEach((field, index) => {
        updatedCount[field] = responses[index].length;
        if (responses[index].length > 0) {
          let constructed = getConstructedResponse(responses[index], field);
          updatedTrademarkInfo[field] = constructed;
          if (columnOne.includes(index)) {
            updatedColumnOneData[field] = constructed;
          } else if (columnTwo.includes(index)) {
            updatedColumnTwoData[field] = constructed;
          } else if (columnThree.includes(index)) {
            updatedColumnThreeData[field] = constructed;
          }
        } else {
          updatedTrademarkInfo[field] = null;
        }
      });
      setColumnOneData(updatedColumnOneData);
      setColumnTwoData(updatedColumnTwoData);
      setColumnThreeData(updatedColumnThreeData);
      setCount(updatedCount);
      setTrademarkInfo(updatedTrademarkInfo);
    }
  }

  function getConstructedResponse(data, field) {
    const dateKeys = {
      "ER Issue": "erReplyDeadline",
      "Renewed Upto": "renewedUptoDate",
      "Show Cause Hearing": "showCauseHearingDate",
      "TM-O (Notice of opp.)": "tmoDeadline",
      "TM-O (Counter St.)": "tmoCounterDeadline",
      "Opposition Hearing": "hearingDeadline",
      "Rule 45": "rule45Deadline",
      "Rule 46": "rule46Deadline",
      "Rule 47": "rule47Deadline"
    };
    let sortedData = data.sort((data1, data2) => {
      let date1 = new Date(moment(data1[dateKeys[field]], "DD-MM-YYYY"));
      let date2 = new Date(moment(data2[dateKeys[field]], "DD-MM-YYYY"));
      return date1 - date2;
    });
    let formatedData = {};
    sortedData.forEach((data) => {
      let date = new Date(moment(data[dateKeys[field]], "DD-MM-YYYY"));
      data.date = moment(date).format("MMMM, yyyy");
      data.field = field;
      data.isDeadline = props.getIsDeadline(moment(date));
      if (formatedData[data.date]) {
        formatedData[data.date].push(data);
      } else {
        formatedData[data.date] = [data];
      }
    });
    return formatedData;
  }

  async function fetchTrademarkByKey() {
    let promises = Object.values(labelFieldMapping).map((key, index) => {
      if (index < 3) {
        return getTrademarkByKey(props.searchedCompany.toLowerCase(), key);
      } else {
        return getTrademarkOppositionByKey(
          props.searchedCompany.toLowerCase(),
          key
        );
      }
    });
    try {
      let results = await Promise.all(promises);
      if (results && results.length && results.length > 0) {
        return results.map((result) => result.data);
      }
    } catch {}
  }

  function handleFilter(field) {
    setShowFilter(true);
    filteredCard.current = field;
  }

  async function handleSearch(startDate, endDate) {
    setfilterLoading(true);
    let data = await fetchTrademarkByDate(startDate, endDate);
    setfilterLoading(false);
    if (data && data.length > 0) {
      let constructedResponse = getConstructedResponse(
        data,
        filteredCard.current
      );
      setFilteredData(constructedResponse);
    }
  }

  async function fetchTrademarkByDate(startDate, endDate) {
    let field = labelFieldMapping[filteredCard.current];
    let company = props.searchedCompany.toLowerCase();
    try {
      let response = await getTrademarkByDate(
        company,
        field,
        startDate,
        endDate
      );
      if (response && response.data) {
        return response.data;
      }
    } catch {}
  }

  function handleFilterClose() {
    setShowFilter(false);
    setFilteredData(null);
  }

  function handleAction(info, action) {
    setEditedTrademark(info);
    filteredCard.current = info.field;
    if (
      ["ER Issue", "Renewed Upto", "Show Cause Hearing"].includes(info.field)
    ) {
      setType("Trademark");
    } else {
      setType("Trademark Opposition");
    }
    if (action === "edit") {
      setShowCreate(true);
    } else if (action === "view") {
      setShowPreview(true);
    } else {
      setShowPatchEdit(true);
    }
  }

  function handleCreateClose() {
    setShowCreate(false);
    setEditedTrademark({});
  }

  function handlePreviewClose() {
    setShowPreview(false);
    setEditedTrademark({});
  }

  async function fetchUpdatedData(company, field) {
    try {
      let response;
      if (["ER Issue", "Renewed Upto", "Show Cause Hearing"].includes(field)) {
        response = await getTrademarkByKey(company, labelFieldMapping[field]);
      } else {
        response = await getTrademarkOppositionByKey(
          company,
          labelFieldMapping[field]
        );
      }
      if (response && response.data) {
        return response.data;
      }
      return [];
    } catch {
      return [];
    }
  }

  async function updateData(company, field) {
    let updatedData = await fetchUpdatedData(company, field);
    let constructedData = getConstructedResponse(updatedData, field);
    let fieldIndex = Object.keys(labelFieldMapping).indexOf(field);
    let updatedColumnOneData = { ...columnOneData };
    let updatedColumnTwoData = { ...columnTwoData };
    let updatedColumnThreeData = { ...columnThreeData };
    let columnOne = [0, 3, 6];
    let columnTwo = [1, 4, 7];
    let columnThree = [2, 5, 8];
    let updatedTrademarkInfo = { ...trademarkInfo };
    updatedTrademarkInfo[field] = constructedData;
    if (columnOne.includes(fieldIndex)) {
      updatedColumnOneData[field] = constructedData;
      setColumnOneData(updatedColumnOneData);
    } else if (columnTwo.includes(fieldIndex)) {
      updatedColumnTwoData[field] = constructedData;
      setColumnTwoData(updatedColumnTwoData);
    } else if (columnThree.includes(fieldIndex)) {
      updatedColumnThreeData[field] = constructedData;
      setColumnThreeData(updatedColumnThreeData);
    }
    setTrademarkInfo(updatedTrademarkInfo);
  }

  function handleUpdateClose(isUpdate) {
    setShowPatchEdit(false);
    if (isUpdate) {
      updateData(editedTrademark.companyId, editedTrademark.field);
    }
  }

  return (
    <>
      <Grid container direction="row" spacing="2">
        <Grid item xs={4} className="column-style">
          {Object.keys(columnOneData).map((field) => {
            return (
              <Grid item xs={12} key={field} style={{ marginBottom: "8px" }}>
                <DashboardCard
                  field={field}
                  entity="trademark"
                  data={trademarkInfo[field]}
                  count={count[field]}
                  handleFilter={handleFilter}
                  handleAction={handleAction}
                  loading={dataLoading}
                />
              </Grid>
            );
          })}
        </Grid>
        <Grid item xs={4} className="column-style">
          {Object.keys(columnTwoData).map((field) => {
            return (
              <Grid item xs={12} key={field} style={{ marginBottom: "8px" }}>
                <DashboardCard
                  entity="trademark"
                  field={field}
                  data={trademarkInfo[field]}
                  count={count[field]}
                  handleFilter={handleFilter}
                  handleAction={handleAction}
                  column2
                  loading={dataLoading}
                />
              </Grid>
            );
          })}
        </Grid>
        <Grid item xs={4} className="column-style">
          {Object.keys(columnThreeData).map((field) => {
            return (
              <Grid item xs={12} key={field} style={{ marginBottom: "8px" }}>
                <DashboardCard
                  field={field}
                  entity="trademark"
                  data={trademarkInfo[field]}
                  count={count[field]}
                  handleFilter={handleFilter}
                  handleAction={handleAction}
                  column3
                  loading={dataLoading}
                />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
      {showFilter && (
        <PopUp
          size="sm"
          open={showFilter}
          fullWidth
          onClose={handleFilterClose}
          title="Trademarks"
        >
          <Filter
            onSearch={handleSearch}
            filterResult={filteredData}
            loading={filterLoading}
          />
        </PopUp>
      )}
      {showCreate &&
        (type === "Trademark" ? (
          <CreateTrademark
            onClose={handleCreateClose}
            tradeMarkId={editedTrademark.tradeMarkId}
            edit={editedTrademark.tradeMarkId ? true : false}
            searchedCompany={editedTrademark.companyId}
          />
        ) : (
          <CreateTrademarkOpposition
            onClose={handleCreateClose}
            tradeMarkId={editedTrademark.oppositionId}
            company={editedTrademark.companyId}
            edit={true}
          />
        ))}
      {showPreview &&
        (type === "Trademark" ? (
          <ViewTrademark
            onClose={handlePreviewClose}
            tradeMarkId={editedTrademark.tradeMarkId}
            searchedCompany={editedTrademark.companyId}
          />
        ) : (
          <ViewTrademarkOpposition
            onClose={handlePreviewClose}
            tradeMarkId={editedTrademark.oppositionId}
            company={editedTrademark.companyId}
            searchedCompany={editedTrademark.companyId}
          />
        ))}
      {showPatchEdit && (
        <TradeMarkView
          type={type}
          onClose={handleUpdateClose}
          currentField={filteredCard.current}
          trademarkInfo={editedTrademark}
        />
      )}
    </>
  );
}
