import axios from "axios";
import apiUrl from "config/environment";

export async function loginUser(body) {
  let url = `${apiUrl}/user/login`;
  const apiInfo = {
    method: "POST",
    data: body,
    url: url
  };
  return axios(apiInfo);
}

export async function logoutUser(body) {
  let url = `${apiUrl}/user/logout`;
  const apiInfo = {
    method: "DELETE",
    data: body,
    url: url
  };
  return axios(apiInfo);
}
