import React from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader
} from "@material-ui/core";
import TodayIcon from "@material-ui/icons/Today";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DescriptionIcon from "@material-ui/icons/Description";
import clsx from "clsx";

export default function DataList(props) {
  return (
    <List
      key={"date-" + props.date}
      subheader={
        <ListSubheader className="date">
          <TodayIcon />
          {props.date}
        </ListSubheader>
      }
      className={clsx("data-list")}
    >
      {props.data[props.date].map((listInfo) => {
        return (
          <ListItem
            className={clsx("list-item", listInfo.isDeadline ? "deadline" : "")}
            key={"fileNo-" + Math.floor(Math.random() * 10000)}
          >
            <ListItemIcon className="item-icon">
              <DescriptionIcon />
            </ListItemIcon>
            <ListItemText
              className="title"
              style={{ cursor: "pointer" }}
              onClick={() => props.handleAction(listInfo, "patchEdit")}
            >
              {props.entity === "trademark" && (
                <>
                  <b>Application No :</b> <span>{listInfo.tradeMarkId}</span>
                </>
              )}
              {props.entity === "patent" && (
                <>
                  <b>File No :</b> <span>{listInfo.fileNo}</span>
                </>
              )}
            </ListItemText>
            <ListItemSecondaryAction className="right-icon">
              <EditIcon
                style={{ cursor: "pointer" }}
                onClick={() => props.handleAction(listInfo, "edit")}
              ></EditIcon>
              <VisibilityIcon
                style={{ cursor: "pointer" }}
                onClick={() => props.handleAction(listInfo, "view")}
              ></VisibilityIcon>
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>
  );
}
