import React, { useEffect, useRef, useState } from "react";
import { getPatentsByDate, getPatentsByKey } from "api/patentApi";
import { Grid } from "@material-ui/core";
import DashboardCard from "./Card";
import moment from "moment";
import PopUp from "components/shared/Popup";
import Filter from "./Filter";
import CreatePatent from "../Patents/CreatePatent";
import ViewPatent from "../Patents/ViewPatent";
import PatentView from "./ViewPatent";

export default function PatentDashboard(props) {
  let [patentInfo, setPatentInfo] = useState(getInitPatentInfo());
  let [count, setCount] = useState({});
  let [filterLoading, setfilterLoading] = useState(false);
  let [showFilter, setShowFilter] = useState(false);
  let [filteredData, setFilteredData] = useState(null);
  let [showCreate, setShowCreate] = useState(false);
  let [showPreview, setShowPreview] = useState(false);
  let [showPatchEdit, setShowPatchEdit] = useState(false);
  let [editedPatent, setEditedPatent] = useState({});
  let prevCompany = useRef();
  let filteredCard = useRef("");
  let [columnOneData, setColumnOneData] = useState({
    "Form 18": null,
    "Form 1": null,
    "Eng Trans Pr. Doc": null
  });
  let [columnTwoData, setColumnTwoData] = useState({
    FER: null,
    "Renewal Due Date": null,
    "Eng Trans PCT Doc": null
  });
  let [columnThreeData, setColumnThreeData] = useState({
    "Form 3": null,
    "Form 27": null,
    Hearing: null
  });
  let [dataLoading, setDataLoading] = useState(false);
  const labelFieldMapping = {
    "Form 18": "form18",
    FER: "fer",
    "Form 3": "form3",
    "Form 1": "form1",
    "Renewal Due Date": "renewalDeadline",
    "Form 27": "form27",
    "Eng Trans Pr. Doc": "priorityDocument",
    "Eng Trans PCT Doc": "pctDocument",
    Hearing: "hearingDate"
  };

  useEffect(init, []);

  useEffect(handleFilterByCompany, [props.searchedCompany]);

  function handleFilterByCompany() {
    if (prevCompany.current !== props.searchedCompany) {
      prevCompany.current = props.searchedCompany;
      fetchPatentInfo();
    }
  }

  function init() {
    fetchPatentInfo();
    prevCompany.current = props.searchedCompany;
  }

  async function fetchPatentInfo() {
    setDataLoading(true);
    let responses = await fetchPatentsByKey();
    setDataLoading(false);
    if (responses) {
      let updatedCount = {};
      let updatedPatentInfo = { ...patentInfo };
      let updatedColumnOneData = {};
      let updatedColumnTwoData = {};
      let updatedColumnThreeData = {};
      let columnOne = [0, 3, 6];
      let columnTwo = [1, 4, 7];
      let columnThree = [2, 5, 8];
      Object.keys(updatedPatentInfo).forEach((field, index) => {
        updatedCount[field] = responses[index].length;
        if (responses[index].length > 0) {
          let constructed = getConstructedResponse(responses[index], field);
          if (columnOne.includes(index)) {
            updatedColumnOneData[field] = constructed;
          } else if (columnTwo.includes(index)) {
            updatedColumnTwoData[field] = constructed;
          } else if (columnThree.includes(index)) {
            updatedColumnThreeData[field] = constructed;
          }
          updatedPatentInfo[field] = constructed;
        } else {
          if (columnOne.includes(index)) {
            updatedColumnOneData[field] = null;
          } else if (columnTwo.includes(index)) {
            updatedColumnTwoData[field] = null;
          } else if (columnThree.includes(index)) {
            updatedColumnThreeData[field] = null;
          }
          updatedPatentInfo[field] = null;
        }
      });
      setColumnOneData(updatedColumnOneData);
      setColumnTwoData(updatedColumnTwoData);
      setColumnThreeData(updatedColumnThreeData);
      setCount(updatedCount);
      setPatentInfo(updatedPatentInfo);
    }
  }

  function getConstructedResponse(data, field) {
    let dateKeys = {
      form3: "form3Deadline",
      form27: "form27Deadline",
      form1: "form1Deadline",
      form18: "form18Deadline",
      priorityDocument: "priorityDocumentDeadline",
      pctDocument: "pctDocumentDeadline",
      hearingDate: "hearingDate",
      fer: "ferDeadline"
    };
    let dateField = dateKeys[labelFieldMapping[field]];
    let sortedData = data.sort((data1, data2) => {
      let date1 = new Date(moment(data1[dateField], "DD-MM-YYYY"));
      let date2 = new Date(moment(data2[dateField], "DD-MM-YYYY"));
      return date1 - date2;
    });

    let formatedData = {};
    sortedData.forEach((data) => {
      let date = new Date(moment(data[dateField], "DD-MM-YYYY"));
      data.date = moment(date).format("MMMM, yyyy");
      data.field = field;
      data.isDeadline = props.getIsDeadline(moment(date));
      if (formatedData[data.date]) {
        formatedData[data.date].push(data);
      } else {
        formatedData[data.date] = [data];
      }
    });
    return formatedData;
  }

  async function fetchPatentsByKey() {
    let promises = Object.values(labelFieldMapping).map((key) => {
      return getPatentsByKey(props.searchedCompany.toLowerCase(), key);
    });
    try {
      let results = await Promise.all(promises);
      if (results && results.length && results.length > 0) {
        return results.map((result) => result.data);
      }
    } catch {}
  }

  function getInitPatentInfo() {
    return {
      "Form 18": null,
      FER: null,
      "Form 3": null,
      "Form 1": null,
      "Renewal Due Date": null,
      "Form 27": null,
      "Eng Trans Pr. Doc": null,
      "Eng Trans PCT Doc": null,
      Hearing: null
    };
  }

  function handleFilter(field) {
    setShowFilter(true);
    filteredCard.current = field;
  }

  async function handleSearch(startDate, endDate) {
    setfilterLoading(true);
    let data = await fetchPatentByDate(startDate, endDate);
    setfilterLoading(false);
    if (data && data.length > 0) {
      let constructedResponse = getConstructedResponse(
        data,
        filteredCard.current
      );
      setFilteredData(constructedResponse);
    }
  }

  async function fetchPatentByDate(startDate, endDate) {
    let field = labelFieldMapping[filteredCard.current];
    let company = props.searchedCompany.toLowerCase();
    try {
      let response = await getPatentsByDate(company, field, startDate, endDate);
      if (response && response.data) {
        return response.data;
      }
    } catch {}
  }

  function handleFilterClose() {
    setShowFilter(false);
    setFilteredData(null);
  }

  function handleAction(info, action) {
    setEditedPatent(info);
    filteredCard.current = info.field;
    if (action === "edit") {
      setShowCreate(true);
    } else if (action === "view") {
      setShowPreview(true);
    } else if (action === "patchEdit") {
      setShowPatchEdit(true);
    }
  }

  function handleCreateClose() {
    setShowCreate(false);
    setEditedPatent({});
  }

  function handlePreviewClose() {
    setShowPreview(false);
    setEditedPatent({});
  }

  function handleEditClose(updated) {
    setEditedPatent({});
    setShowPatchEdit(false);
    if (updated) {
      updateData(editedPatent.companyId, editedPatent.field);
    }
  }

  async function updateData(company, field) {
    let updatedData = await fetchUpdatedData(company, field);
    let constructedData = getConstructedResponse(updatedData, field);
    let fieldIndex = Object.keys(labelFieldMapping).indexOf(field);
    let updatedColumnOneData = { ...columnOneData };
    let updatedColumnTwoData = { ...columnTwoData };
    let updatedColumnThreeData = { ...columnThreeData };
    let columnOne = [0, 3, 6];
    let columnTwo = [1, 4, 7];
    let columnThree = [2, 5, 8];
    let updatedPatentInfo = { ...patentInfo };
    updatedPatentInfo[field] = constructedData;
    if (columnOne.includes(fieldIndex)) {
      updatedColumnOneData[field] = constructedData;
      setColumnOneData(updatedColumnOneData);
    } else if (columnTwo.includes(fieldIndex)) {
      updatedColumnTwoData[field] = constructedData;
      setColumnTwoData(updatedColumnTwoData);
    } else if (columnThree.includes(fieldIndex)) {
      updatedColumnThreeData[field] = constructedData;
      setColumnThreeData(updatedColumnThreeData);
    }
    setPatentInfo(updatedPatentInfo);
  }

  async function fetchUpdatedData(company, field) {
    try {
      let response = await getPatentsByKey(company, labelFieldMapping[field]);
      if (response && response.data) {
        return response.data;
      }
      return [];
    } catch {
      return [];
    }
  }

  // function handleAction(info, action) {
  // }
  return (
    <>
      {/* {Object.keys(patentInfo).map((field, index) => {
        return (
          <Grid item xs={4} key={field}>
            <DashboardCard
              field={field}
              data={patentInfo[field]}
              count={count[field]}
              handleFilter={handleFilter}
              column2={[1, 4, 7].includes(index)}
              column3={[2, 5, 8].includes(index)}
            />
          </Grid>
        );
      })} */}
      <Grid container direction="row" spacing="2">
        <Grid item xs={4} className="column-style">
          {Object.keys(columnOneData).map((field) => {
            return (
              <Grid item xs={12} key={field} style={{ marginBottom: "8px" }}>
                <DashboardCard
                  field={field}
                  data={patentInfo[field]}
                  entity="patent"
                  count={count[field]}
                  handleFilter={handleFilter}
                  handleAction={handleAction}
                  loading={dataLoading}
                />
              </Grid>
            );
          })}
        </Grid>
        <Grid item xs={4} className="column-style">
          {Object.keys(columnTwoData).map((field) => {
            return (
              <Grid item xs={12} key={field} style={{ marginBottom: "8px" }}>
                <DashboardCard
                  field={field}
                  data={patentInfo[field]}
                  entity="patent"
                  count={count[field]}
                  handleFilter={handleFilter}
                  handleAction={handleAction}
                  column2
                  loading={dataLoading}
                />
              </Grid>
            );
          })}
        </Grid>
        <Grid item xs={4} className="column-style">
          {Object.keys(columnThreeData).map((field) => {
            return (
              <Grid item xs={12} key={field} style={{ marginBottom: "8px" }}>
                <DashboardCard
                  field={field}
                  data={patentInfo[field]}
                  entity="patent"
                  count={count[field]}
                  handleFilter={handleFilter}
                  column3
                  handleAction={handleAction}
                  loading={dataLoading}
                />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
      {showFilter && (
        <PopUp
          size="sm"
          open={showFilter}
          fullWidth
          onClose={handleFilterClose}
          title="Patents"
        >
          <Filter
            onSearch={handleSearch}
            filterResult={filteredData}
            loading={filterLoading}
          />
        </PopUp>
      )}
      {showCreate && (
        <CreatePatent
          onClose={handleCreateClose}
          patentId={editedPatent.patentId}
          edit={true}
          companyId={editedPatent.companyId}
        />
      )}
      {showPreview && (
        <ViewPatent
          onClose={handlePreviewClose}
          patentId={editedPatent.patentId}
          edit={true}
          companyId={editedPatent.companyId}
        />
      )}
      {showPatchEdit && (
        <PatentView
          patentInfo={editedPatent}
          currentField={filteredCard.current}
          onClose={handleEditClose}
        />
      )}
    </>
  );
}
