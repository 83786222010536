import axios from "axios";
import apiUrl from "config/environment";

import Cookies from "js-cookie";

export async function getUserById(emailId) {
  let url = `${apiUrl}/user/` + emailId;
  const options = {
    method: "GET",
    headers: {
      Authorization: Cookies.get("token")
    },
    url: url
  };
  return axios(options);
}
export async function changePassword(password) {
  let url = `${apiUrl}/user/${Cookies.get("userId")}/changePass`;
  const options = {
    method: "POST",
    headers: {
      Authorization: Cookies.get("token")
    },
    url: url,
    data: password
  };
  return axios(options);
}
